import axiosGlobalInstance from '../http-instance';
import paginationDefault from '../../constants/pagination';
import { getCookie, decryptAes } from '../../utils';

export default (
  params = { page: paginationDefault.page, limit: paginationDefault.limit }
) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'voter.print.php',
    responseType: 'blob',
    headers: {
      token: decipher.token.value,
      action: 'search'
    },
    params: {
      ...params,
      isExcluded: false,
    },
  })
}
