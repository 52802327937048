import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, TextInput, Text, Button, Heading, ResponsiveContext, MaskedInput } from 'grommet';

import Header from '../components/header/header';
import { Placeholder, ErrorForm, HeaderHtml } from '../../../components';

import { formattedDateTime, formattedCurrency } from '../../../utils';

import urlRoutes from '../../../config/url-routes';

import { getUseLicense, createBilling, changeField } from '../actions/create-billing';

class InvoiceUseLicense extends Component {
  constructor(props) {
    super(props);

    const { identity } = this.props.match.params;
    const { getUseLicense } = this.props;

    getUseLicense(identity);
  }

  componentDidUpdate(prevProps) {
    const { clientId } = this.props;

    if(clientId && prevProps.clientId !== clientId) {
      this.existOpenInvoice();
    }
  };

  existOpenInvoice = () => {
    const { identifier, openInvoice, history } = this.props;

    if(openInvoice && openInvoice.id) {
      history.push(urlRoutes.InvoiceOpen.replace(/:identity/gi, identifier));
    }
  };

  callbackPostSuccess = () => {
    const { identifier, history } = this.props;
    history.push(urlRoutes.InvoiceOpen.replace(/:identity/gi, identifier));
  };

  handlePost = formParams => {
    const { createBilling } = this.props;
    createBilling({ formParams, callbackSuccess: this.callbackPostSuccess });
  };

  render() {
    const {
      loadingPostForm,
      postDataError,
      identifier,
      clientId,
      clientName,

      value,
      startLicense,
      endLicense,

      isNewClient,
      inputBillingName,
      inputBillingDocument,
      changeField,
    } = this.props;

    if(!clientId) {
      return (
        <Placeholder
          title='Carregando...'
          message='Aguarde que estamos preparando as coisas :)'
        />
      );
    }

    const licenseStart = formattedDateTime(startLicense);
    const licenseEnd = formattedDateTime(endLicense);

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title={ `Pagamento licença de uso - ${licenseStart} até ${licenseEnd}` } />
            <Header />

            <Box
              direction='column'
              align='center'
            >
              {isNewClient && <Box
                width='xlarge'
                pad={ size === 'small' ? 'large' : 'small' }
              >
                <Heading level='2' size='small' margin={{ bottom: 'none' }}>
                  Olá {clientName}.
                </Heading>

                <Text size='small' color='dark-1' margin={{ top: 'none', bottom: 'none' }}>
                  Complete seu cadastro pra continuar gerenciando seu mandato.
                </Text>
              </Box>}


              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                width='xlarge'
              >
                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'small' }
                  margin={{ right: size === 'small' ? 'none' : 'medium' }}
                >
                  <Heading level='3' size='small'>
                    Dados do pagador
                  </Heading>

                  <Text
                    color='dark-2'
                    size='small'
                    margin={{ bottom: 'small' }}
                  >
                    Informe abaixo o nome completo e o CPF do pagador.
                  </Text>

                  <Box
                    gap='small'
                    margin={{ bottom: 'medium' }}
                  >
                    <Box>
                      <TextInput
                        value={inputBillingName ?? ''}
                        placeholder='Nome completo *'
                        onChange={event =>
                          changeField(event.target.value, 'changeInputField', 'inputBillingName')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='billingName' />
                    </Box>

                    <Box>
                      <MaskedInput
                        mask={[
                          { length: 3, regexp: /\d/ },
                          { fixed: '.' },
                          { length: 3, regexp: /\d/ },
                          { fixed: '.' },
                          { length: 3, regexp: /\d/ },
                          { fixed: '-' },
                          { length: 2, regexp: /\d/ }
                        ]}
                        placeholder='Cpf *'
                        value={inputBillingDocument ?? ''}
                        onChange={ event =>
                          changeField(event.target.value, 'changeInputField', 'inputBillingDocument')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='billingDocument' />
                    </Box>
                  </Box>

                  <Heading level='3' size='small'>
                    Dados da cobrança
                  </Heading>

                  <Box
                    border={{ color: 'light-3' }}
                    round='xsmall'
                    margin={{ top: 'xsmall' }}
                    pad='medium'
                    background='white'
                    flex={{ shrink: 0 }}
                  >
                    <Text size='medium' color='dark-2'>Período de uso da cobrança</Text>
                    <Text size='small' color='dark-4'>{licenseStart} até {licenseEnd}</Text>
                  </Box>
                </Box>

                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'small' }
                  margin={{ left: size === 'small' ? 'none' : 'medium' }}
                >
                  <Heading level='3' size='small'>
                    Resumo
                  </Heading>

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                      Produto
                    </Text>

                    <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                      Licença de uso
                    </Text>
                  </Box>

                  {isNewClient && (<Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small', top: 'xsmall' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                      Taxa de adesão, cancelamento
                    </Text>

                    <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>
                      R$ 0,00
                    </Text>
                  </Box>)}

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small', top: 'xsmall' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                      Total a pagar
                    </Text>

                    <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>
                      {formattedCurrency(value)}
                    </Text>
                  </Box>

                  <ErrorForm errorsList={postDataError.errors} fieldName='createBilling' />


                  <Box
                    margin={{ top: 'medium' }}
                    pad={{ bottom: 'small', top: 'xsmall' }}
                  >
                    <Button
                      primary
                      label={ !loadingPostForm ? 'Gerar Boleto' : 'Gerando Boleto...' }
                      disabled={ loadingPostForm }
                      onClick={() => {
                        this.handlePost({
                          clientToken: identifier,
                          billingName: inputBillingName,
                          billingDocument: inputBillingDocument,
                          dateStartLicense: licenseStart,
                          dateEndLicense: licenseEnd,
                        })
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ invoiceReducer: { clientBilling } }) => ({
  loadingPostForm: clientBilling.loadingPostForm,
  postDataError: clientBilling.postDataError,

  identifier: clientBilling.identifier,
  clientId: clientBilling.clientId,
  clientName: clientBilling.clientName,
  openInvoice: clientBilling.openInvoice,

  value: clientBilling.value,
  startLicense: clientBilling.startLicense,
  endLicense: clientBilling.endLicense,

  isNewClient: clientBilling.isNewClient,

  inputBillingName: clientBilling.inputBillingName,
  inputBillingDocument: clientBilling.inputBillingDocument,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUseLicense,
  createBilling,
  changeField,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceUseLicense)
);
