import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import formArchiveReducer from './form-archive';
import formCommentReducer from './form-comment';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';
import selectOptionsReducer from './select-options';
import notificationReducer from './notification';

const servicesReducer = combineReducers({
  remove: removeReducer,
  formArchive: formArchiveReducer,
  formComment: formCommentReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
  selectOptions: selectOptionsReducer,
  notification: notificationReducer,
});

export default servicesReducer;
