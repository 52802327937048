import React from 'react';
import { ResponsiveContext, Text, Box, Anchor } from 'grommet';
import items from './options-menu-help';

function DropHelp() {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          gap='large'
          direction='row'
          background='light-1'
          pad={{ vertical: 'large', horizontal: 'large' }}
        >
          <Box
            gap={ size === 'small' ? 'large' : 'medium' }
          >
            <Text size='small'>Ajuda</Text>

            <Box gap={ size === 'small' ? 'medium' : 'small' }>
              {items
                .filter(a => a.location === 'primary')
                .map(item => (
                  <Anchor
                    key={item.label}
                    label={item.label}
                    href={item.click}
                    target='blank'
                    size='small'
                    color='brand'
                  />
              ))}
            </Box>
          </Box>

          <Box border={{ side: 'right', color: 'light-4' }} />

          <Box
            gap={ size === 'small' ? 'large' : 'medium' }
          >
            <Text size='small'>Nossas políticas</Text>

            <Box gap={ size === 'small' ? 'medium' : 'small' }>
              {items
                .filter(a => a.location === 'second')
                .map(item => (
                  <Anchor
                    key={item.label}
                    label={item.label}
                    href={item.click}
                    target='blank'
                    size='small'
                    color='brand'
                  />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default DropHelp;
