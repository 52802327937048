import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Text, Button, Menu } from 'grommet';
import { Notes, Transaction, Search, More } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination, Tag } from '../../../../components';

import { toggleDetailsLayer } from '../../actions/details';
import { toggleFormCancelLayer } from '../../actions/form-cancel';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';

import { formattedDateTime, formattedNumber } from '../../../../utils';
import rulesName from '../../../../constants/rules';
import urlRoutes from '../../../../config/url-routes';

import statusColor from '../../constants/status-color';

function SearchResult(props) {
  const {
    userLogged: { rules },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    name,
    type,
    dateSend,
    status,
    page,
    limit,

    toggleDetailsLayer,
    toggleFormCancelLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,

    history,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createCampaign) ?
          { label: 'Nova campanha', action: () => history.push(urlRoutes.campaignCreateUrl) } : null
      }
    />;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.cancelCampaign) && parseInt(row.statusId) === 1) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Transaction />
            <Text size='small'>Cancelar</Text>
          </Box>
        ),
        onClick: () => { toggleFormCancelLayer(row) }
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'campanhas' : 'campanha'}
                  </Text>
                </Box>

                <Box
                  direction='row'
                  align='center'
                  gap={size === 'small' ? 'large' : 'medium'}
                >
                  <Button
                    plain
                    title='Filtrar campanhas'
                    icon={<Search />}
                    onClick={() => { openFormSearchLayer() }}
                  />

                  {rules.includes(rulesName.createCampaign) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Nova'
                      onClick={() => { history.push(urlRoutes.campaignCreateUrl) }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && <Box width='medium'>
                  <Text color='dark-2'>Nome</Text>
                </Box>}

                {size !== 'small' && (<Box width='small'>
                  <Text color='dark-2'>Data de envio</Text>
                </Box>)}

                {size !== 'small' && size !== 'medium' && (<Box width='small'>
                  <Text color='dark-2'>Eleitores</Text>
                </Box>)}

                {size !== 'small' && <Box width='small'>
                  <Text color='dark-2'>Status</Text>
                </Box>}

                <Box width='small' />
              </Box>

              {dataRows.map(row => {
                const menu = optionsMenu(row);

                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    <Box width='medium'>
                      <Text color='dark-2' size='small'>{row.name}</Text>
                    </Box>

                    {size !== 'small' && (<Box width='small'>
                      <Text color='dark-2' size='small'>
                        {`${formattedDateTime(row.dateSend, 'DD MMM YYYY HH:mm')}h`}
                      </Text>
                    </Box>)}

                    {size !== 'small' && size !== 'medium' && (<Box width='small'>
                      <Text color='dark-2' size='small'>
                        {row.totalVoters}
                      </Text>
                    </Box>)}

                    <Box width='small' direction='row'>
                      <Tag text={row.statusName} background={statusColor[row.statusId] || 'dark-2'} />
                    </Box>

                    <Box align='end' width='small'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        {menu.length > 0 && (<Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={menu}
                        />)}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  name,
                  type,
                  dateSend: formattedDateTime(dateSend),
                  status,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, campaignsReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  name: formSearch.name,
  type: formSearch.type,
  dateSend: formSearch.dateSend,
  status: formSearch.status,
  page: formSearch.page,
  limit: formSearch.limit,
  loadingFormSearch: searchResult.loadingFormSearch,

  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    openFormSearchLayer,
    toggleFormCancelLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
