import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer,  Text, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';
import visibleContentWidth from '../../../../constants/layer-content-width';

import {
  enableOrDisable,
  toggleFormChangeStatusLayer,
} from '../../actions/form-change-status';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      enableOrDisable,
    } = this.props;

    enableOrDisable({ formParams });
  };

  render() {
    const {
      id,
      status,
      name,
      email,

      toggleLayer,
      loadingPostForm,
      postDataError,

      toggleFormChangeStatusLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value={status ? 'Deseja realmente inativar esse usuário?' : 'Deseja realmente reativar esse usuário?'} />

                <Box
                  align='center'
                  margin={{ top: 'medium' }}
                  flex={{ shrink: 0 }}
                >
                  <Box
                    width={visibleContentWidth[size]}
                    round='small'
                    pad={{ vertical: 'large' }}
                    margin={{ bottom: 'medium', horizontal: 'xsmall' }}
                    elevation='small'
                  >
                    <SimpleDetail
                      title='Nome'
                      value={name}
                    />

                    <SimpleDetail
                      title='E-mail'
                      value={email}
                    />

                    <Hr />

                    <Box
                      margin={{
                        top: 'medium',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      alignSelf='center'
                    >
                      <Text size='small' textAlign='center' color='status-warning'>
                        {status ?
                          <>Após a confirmação o usuário deixará de acessar o sistema.</> :
                          <>Após a confirmação o usuário poderá acessar o sistema.</>}
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  align='center'
                  flex={{ shrink: 0 }}
                >
                  <ErrorForm errorsList={postDataError.errors} />
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                  flex={{ shrink: 0 }}
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Não'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ?
                        (status ? 'SIM, inativar' : 'SIM, reativar') :
                        (status ? 'Inativando...' : 'Reativando...')
                      }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          status: !status,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsUserReducer: { formChangeStatus }, layerReducer }) => ({
  id: formChangeStatus.id,
  status: formChangeStatus.status,
  name: formChangeStatus.name,
  email: formChangeStatus.email,

  loadingPostForm: formChangeStatus.loadingPostForm,
  postDataError: formChangeStatus.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    enableOrDisable,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
