import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { deleteVoter } from '../../../api/voter';

const loadingPostDeleteToggle = () => ({
  type: TYPES.loadingFormDelete,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDeleteToggle());

    deleteVoter(params.formParams.id)
      .then(response => {
        dispatch(loadingPostDeleteToggle());
        dispatch(notification(`O eleitor foi removido!`));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostDeleteToggle());
        dispatch({
          type: TYPES.formPostDeleteError,
          payload: responseFail.response
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.voter,
      action: analytics.actions.voterDelete,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: TYPES.setDataFormDelete, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
