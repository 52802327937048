import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Select, Button, Layer, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeStatus, changeField, toggleFormChangeStatusLayer } from '../../actions/form-change-status';

import selectManifestationStatusOptions from '../../../../constants/select-manifestation-status';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      changeStatus,
    } = this.props;

    changeStatus({ formParams });
  };

  render() {
    const {
      id,
      name,
      protocolNumber,
      statusId,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      toggleFormChangeStatusLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Trocar status' />

                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Protocolo'
                    value={protocolNumber}
                  />

                  <SimpleDetail
                    title='Nome'
                    value={name}
                  />

                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Status' required />

                    <Select
                      options={selectManifestationStatusOptions}
                      value={statusId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'statusId', value: nextValue }, 'changeFormStatusField')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='statusId' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          statusId,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ manifestationReducer: { formChangeStatus }, layerReducer }) => ({
  id: formChangeStatus.id,
  name: formChangeStatus.name,
  protocolNumber: formChangeStatus.protocolNumber,
  statusId: formChangeStatus.statusId,

  loadingPostForm: formChangeStatus.loadingPostForm,
  postDataError: formChangeStatus.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeField,
    changeStatus,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
