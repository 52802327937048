import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((appointment, key, items) => map(
    when(
      propEq('id', parseInt(key)),
      evolve({
        subject: always(appointment.subject),
        startDate: always(appointment.startDate),
        endDate: always(appointment.endDate),
      }),
    ),
    items
  )
)
