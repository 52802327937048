import TYPES from './types';
import { getClientUseLicense } from '../../../api/client';
import { postCreateBilling } from '../../../api/payment';

export const createBilling = params => {
  return dispatch => {
    dispatch({ type: TYPES.loadFormPostToggle });

    postCreateBilling(params.formParams)
      .then(() => {
        dispatch({
          type: TYPES.formPostSuccess
        });
      })
      .then(() => { params.callbackSuccess(); })
      .then(() => { dispatch({ type: TYPES.loadFormPostToggle }); })
      .catch((responseFail) => {
        dispatch({ type: TYPES.formPostError, payload: responseFail.response });
        dispatch({ type: TYPES.loadFormPostToggle });
      })
  }
}

export const getUseLicense = identity => {
  return dispatch => {
    getClientUseLicense(identity)
      .then(response => {
        dispatch({
          type: TYPES.useLicense,
          payload: response,
        });
      })
      .catch(() => { })
  }
}

export const changeField = (value, type, field) => ({
  type: TYPES[type],
  payload: {
    value,
    field,
  }
});
