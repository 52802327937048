import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default (category, description) => {
  const formattedParams = {
    Categoria: category,
    Descricao: description,
  }

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    url: '/usuario.php',
    data: formattedParams,
    headers: {
      token: decipher.token.value,
      action: 'criar_historia'
    }
  });
}
