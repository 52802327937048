import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { cancelCampaign } from '../../../api/campaign';

const loadingPostCancelToggle = () => ({
  type: TYPES.loadFormCancel,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const cancel = params => {
  return dispatch => {
    dispatch(loadingPostCancelToggle());

    cancelCampaign(params.formParams)
      .then(response => {
        dispatch(toggleFormCancelLayer());
        dispatch(loadingPostCancelToggle());

        dispatch({
          type: TYPES.formCancelSuccess,
          payload: response,
        });

        dispatch(notification(`A campanha foi cancelada!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostCancelToggle());
        dispatch({
          type: TYPES.formCancelError,
          payload: responseFail.response
        });
      })
  }
};

export const toggleFormCancelLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      { type: TYPES.loadingDataFormCancel, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
