import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { cancel, enable, toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { changeValues } from '../../../../actions/form';

class FormCancel extends Component {
  handlePost = formParams => {
    const {
      cancel,
      enable,
      isCanceled
    } = this.props;

    if(isCanceled) {
      enable({ formParams });
    } else {
      cancel({ formParams });
    }
  };

  render() {
    const {
      id,
      startDate,
      isCanceled,
      reason,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormChangeStatusLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value={isCanceled ? 'Deseja realmente reativar esse compromisso?' : 'Deseja realmente cancelar esse compromisso?'} />

                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Referência'
                    value={`AG-${id}`}
                  />

                  <SimpleDetail
                    title='Dia'
                    value={formattedDateTime(startDate, 'DD MMM YYYY')}
                  />

                  <SimpleDetail
                    title='Horário início'
                    value={formattedDateTime(startDate, 'HH:mm')}
                  />

                  <Hr />

                  {!isCanceled &&
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large',
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Motivo do cancelamento' />

                      <TextArea
                        resize='vertical'
                        value={reason}
                        onChange={event =>
                          changeValues({ fieldName: 'reason', value: event.target.value }, 'changeFormChangeStatusReason')
                        }
                      />
                    </Box>}

                  <Box
                    margin={{ horizontal: 'large' }}
                    flex={{ shrink: 0 }}
                  >
                    <ErrorForm errorsList={postDataError.errors} />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Não'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ?
                        (isCanceled ? 'SIM, reativar' : 'SIM, cancelar') :
                        (isCanceled ? 'Reativando...' : 'Cancelando...')
                      }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          reason
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ appointmentsReducer: { formChangeStatus }, layerReducer }) => ({
  id: formChangeStatus.id,
  startDate: formChangeStatus.startDate,
  isCanceled: formChangeStatus.isCanceled,
  reason: formChangeStatus.reason,

  loadingPostForm: formChangeStatus.loadingPostForm,
  postDataError: formChangeStatus.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    cancel,
    enable,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCancel);
