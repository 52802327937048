import TYPES from './types';
import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';
import * as analytics from '../../../analytics';

const _notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const _command = params =>
  dispatch => {
    dispatch(_notification(`Gerando Excel. Aguarde...`));

    list(params, 'atendimento.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            reference: { name: 'Ref.', transform: (value) => value },
            description: { name: 'Descrição', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            category: { name: 'Categoria', transform: (value) => value ?? '' },
            origin: { name: 'Origem', transform: (value) => value ?? '' },
            status: { name: 'Status', transform: (value) => value ?? '' },
            office: { name: 'Escritório', transform: (value) => value ?? '' },
            createdAt: { name: 'Data de cadastro', transform: (value) => value ?? '' },
            updatedAt: { name: 'Última atualização', transform: (value) => value ?? '' },
            requestDate: { name: 'Data de solicitação', transform: (value) => value ?? '' },
            completionDate: { name: 'Data de conclusão', transform: (value) => value ?? '' },
            electorName: { name: 'Eleitor', transform: (value) => value ?? '' },
            electorTelephone: { name: 'Telefone(s)', transform: (value) => value ?? '' },
            electorAddress: { name: 'Endereço', transform: (value) => value ?? '' },
            electorAddressNumber: { name: 'Número', transform: (value) => value ?? '' },
            electorAdditionalAddress: { name: 'Complemento', transform: (value) => value ?? '' },
            electorDistrict: { name: 'Bairro', transform: (value) => value ?? '' },
            electorCity: { name: 'Cidade', transform: (value) => value ?? '' },
            electorState: { name: 'Estado', transform: (value) => value ?? '' },
            electorZipcode: { name: 'CEP', transform: (value) => value ?? '' },
          },
          fileName: `listagem-atendimentos-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(_notification(`Ops... não conseguimos gerar o Excel.`))
      );
  }

export const generateCsv = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.service,
    action: analytics.actions.serviceExport,
  });

  return _command(params);
};
