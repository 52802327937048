const TYPES = ({
  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_USER_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_USER_SUCCESS',
  searchError: 'SEARCH_SETTINGS_USER_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_SETTINGS_USER_FORM_LOADING',
  loadDataEdit: 'MANAGER_SETTINGS_USER_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_SETTINGS_USER_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_SETTINGS_USER_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_SETTINGS_USER_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_SETTINGS_USER_FORM_ERROR',
  changeFormManagerField: 'CHANGE_MANAGER_SETTINGS_USER_FORM_FIELD',
  setAllRulesAdmin: 'MANAGER_SETTINGS_USER_SET_ALL_RULES',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_USER_DEFAULT_PAGE',
  updateItemListing: 'SETTINGS_USER_SUCCESS_UPDATE_LISTING',
  insertItemListing: 'SETTINGS_USER_SUCCESS_INSERT_LISTING',

  // change status
  loadDataChangeStatus: 'SET_STATUS_SETTINGS_USER_FORM_ID',
  loadFormChangeStatus: 'LOADING_STATUS_SETTINGS_USER_FORM_TOGGLE',
  formChangeStatusError: 'POST_STATUS_SETTINGS_USER_FORM_ERROR',

  // change pass
  loadDataChangePass: 'SET_PASS_SETTINGS_USER_FORM_ID',
  loadFormChangePass: 'LOADING_PASS_SETTINGS_USER_FORM_TOGGLE',
  formChangePassError: 'POST_PASS_SETTINGS_USER_FORM_ERROR',
  changeFormPassField: 'CHANGE_PASS_SETTINGS_USER_FORM_FIELD',

  // notification
  showNotification: 'SETTINGS_USER_SHOW_NOTIFICATION',
  hideNotification: 'SETTINGS_USER_HIDE_NOTIFICATION',
});

export default TYPES;
