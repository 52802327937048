import { getCookie, decryptAes } from '../../../utils';
import paginationDefault from '../../../constants/pagination';

export default () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return {
    status: 'pending',
    participants: [decipher?.user?.id],

    page: paginationDefault.page,
    limit: paginationDefault.limit
  }
};
