import { prepend } from 'ramda';

export default (object, itens) => {
  const newObject = {
    name: object.name,
    description: object.description,
  };

  return prepend(newObject, itens);
};
