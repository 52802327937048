import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { createPhonecall, updatePhonecall } from '../../../api/phonecall';
import { details } from '../../../api/common';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const searchDetails = id =>
  details(id, 'telefonema.php')
    .then(response => ({
        type: TYPES.loadDataEdit,
        payload: response,
      })
    )
    .catch(() => ({ type: TYPES.loadingFormManagerEditError }))

export const loadingPostManager = () => ({
  type: TYPES.loadingPostFormManager,
});

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    updatePhonecall(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer())
        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });
        dispatch(notification(`A ligação LI-${response.data.id} foi atualizada!`))
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response
        });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    createPhonecall(params.formParams)
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(notification(`A ligação LI-${response.data.id} foi criada!`))
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value) => ({
  type: TYPES.changeFormManagerFields,
  payload: value,
});

export const showFormManagerLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerEdit },
      searchDetails(params.id),
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: TYPES.loadDataCreate },
  ]
}

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: TYPES.clearFormManager }
]
