import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Anchor, TextInput, Text, Box, Select } from 'grommet';

import { TitleField, CalendarDropButton, DescriptionField, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';
import { ErrorForm } from '../../../../components';

import { create, changeField, toggleFormCreateServiceLayer } from '../../actions/form-create-service';

import visibleWidth from '../../../../constants/layer-width';
import { formattedDateTime } from '../../../../utils';

class FormCreateService extends Component {
  callbackPostSuccess = () => {
    const {
      toggleFormCreateServiceLayer,
    } = this.props;

    toggleFormCreateServiceLayer();
  };

  handlePost = formParams => {
    const { create } = this.props;

    create({
      formParams,
      callbackSuccess: this.callbackPostSuccess,
    });
  };

  render() {
    const {
      statusOptionsService,
      originOptionsService,
      categoryOptionsService,
      officeOptionsService,
      usersOptionsVoterAndService,

      categoryId,
      description,
      documents,
      electorId,
      officeId,
      originId,
      requestDate,
      completionDate,
      responsibles,
      statusId,
      voterName,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      toggleFormCreateServiceLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'createService') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCreateServiceLayer}
              onEsc={toggleFormCreateServiceLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {true ? (
                  <>
                    <TitleDetail value='Novo atendimento' />

                    <Box flex overflow='auto'>
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Eleitor' />

                        <TextInput
                          value={voterName}
                          disabled={true}
                        />
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Status' required />

                        <Select
                          options={statusOptionsService}
                          value={statusId ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          emptySearchMessage='Selecione o status desse atendimento'
                          onChange={({ value: nextValue }) => changeField({ fieldName: 'statusId', value: nextValue }) }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='statusId' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Data solicitação' required
                          />

                          <DescriptionField
                            text='Data que o atendimento foi solicitado pelo eleitor.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <CalendarDropButton
                            date={requestDate ?? ''}
                            onSelect={(selectedDate) => changeField({ fieldName: 'requestDate', value: selectedDate }, 'changeFormManagerField')}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='requestDate' />
                        </Box>
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Data conclusão'
                          />

                          <DescriptionField
                            text='Data que o atendimento foi finalizado.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <CalendarDropButton
                            date={completionDate ?? ''}
                            onSelect={(selectedDate) => changeField({ fieldName: 'completionDate', value: selectedDate }, 'changeFormManagerField')}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='completionDate' />
                        </Box>
                      </Box>

                      {/*<Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='50vw'>
                            <TitleField text='Data solicitação' required />

                            <CalendarDropButton
                              date={requestDate ?? ''}
                              onSelect={(selectedDate) => changeField({ fieldName: 'requestDate', value: selectedDate })}
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='requestDate' />
                          </Box>

                          <Box width='50vw'>
                            <TitleField text='Data conclusão' />

                            <CalendarDropButton
                              date={completionDate ?? ''}
                              onSelect={(selectedDate) => changeField({ fieldName: 'completionDate', value: selectedDate }, 'changeFormManagerField')}
                            />
                          </Box>
                        </Box>
                      </Box>*/}



                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Origem' required />

                        <SelectSearch
                          options={originOptionsService}
                          value={originId ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          emptySearchMessage='Selecione qual o canal do atendimento'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'originId', value: nextValue })
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='originId' />
                      </Box>


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='50vw'>
                            <TitleField text='Escritório' required />

                            <SelectSearch
                              options={officeOptionsService}
                              value={officeId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              onChange={({ value: nextValue }) => changeField({ fieldName: 'officeId', value: nextValue }) }
                              emptySearchMessage='Selecione qual o escritório desse atendimento'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='officeId' />
                          </Box>

                          <Box width='50vw'>
                            <TitleField text='Categoria' required />

                            <SelectSearch
                              options={categoryOptionsService}
                              value={categoryId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              onChange={({ value: nextValue }) => changeField({ fieldName: 'categoryId', value: nextValue }) }
                              emptySearchMessage='Selecione a categoria do atendimento'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='categoryId' />
                          </Box>
                        </Box>
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '40vw'}
                          gap='xxsmall'
                        >
                          <TitleField text='Responsáveis' />

                          <DescriptionField
                            text='Usuários responsáveis por acompanhar o atendimento.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '60vw'}
                        >
                          <Select
                            options={usersOptionsVoterAndService.filter(a => a.status)}
                            value={responsibles ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            multiple
                            closeOnChange={false}
                            onChange={({ value: nextValue }) => changeField({ fieldName: 'responsibles', value: nextValue }) }
                            emptySearchMessage='Selecione os usuários que estão trabalhando nesse atendimento'
                            valueLabel={
                              responsibles && responsibles.length > 1 ? (
                                <Box pad='small'>
                                  <Text>{`${responsibles.length} selecionado(s)`}</Text>
                                </Box>
                              ) : null
                            }
                          />
                        </Box>
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Descrição' required />

                        <TextArea
                          rows={5}
                          resize='vertical'
                          maxLength={1000}
                          value={description ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'description', value: event.target.value })
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                      </Box>
                    </Box>


                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={toggleFormCreateServiceLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              categoryId,
                              description,
                              documents,
                              electorId,
                              officeId,
                              originId,
                              requestDate: formattedDateTime(requestDate),
                              completionDate: formattedDateTime(completionDate),
                              responsibles,
                              statusId,
                            })
                          }
                        />
                      </Box>


                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ votersReducer: { formCreateService }, layerReducer, selectOptionsReducer }) => ({
  statusOptionsService: selectOptionsReducer.statusServiceOptions,
  originOptionsService: selectOptionsReducer.originServiceOptions,
  categoryOptionsService: selectOptionsReducer.categoryServiceOptions,
  officeOptionsService: selectOptionsReducer.officeOptions,
  usersOptionsVoterAndService: selectOptionsReducer.usersOptions,

  categoryId: formCreateService.categoryId,
  description: formCreateService.description,
  documents: formCreateService.documents,
  electorId: formCreateService.electorId,
  officeId: formCreateService.officeId,
  originId: formCreateService.originId,
  requestDate: formCreateService.requestDate,
  completionDate: formCreateService.completionDate,
  responsibles: formCreateService.responsibles,
  statusId: formCreateService.statusId,
  voterName: formCreateService.voterName,

  loadingPostForm: formCreateService.loadingPostForm,
  postDataError: formCreateService.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    create,
    changeField,
    toggleFormCreateServiceLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreateService);
