import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import { searchPolls } from '../../actions/form-search';
import { remove, removeAnswer, toggleFormDeleteLayer } from '../../actions/form-delete';

import visibleWidth from '../../../../constants/layer-width';
import visibleContentWidth from '../../../../constants/layer-content-width';

import { formattedDateTime } from '../../../../utils';

class FormDelete extends Component {
  handlePost = formParams => {
    const { remove, removeAnswer, actionToDelete } = this.props;

    if(actionToDelete === 'survey') {
      remove({
        formParams,
        callbackSuccess: () => {
          const { searchPolls, toggleFormDeleteLayer } = this.props;

          searchPolls();
          toggleFormDeleteLayer();
        },
      });
    }
    else {
      removeAnswer({
        formParams,
        callbackSuccess: () => {
          const { toggleFormDeleteLayer } = this.props;
          toggleFormDeleteLayer();
        },
      });
    }
  };

  render() {
    const {
      id,
      reference,
      endDate,
      actionToDelete,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormDeleteLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'delete') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormDeleteLayer}
              onEsc={toggleFormDeleteLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail
                  value={actionToDelete === 'survey' ?
                    'Deseja realmente remover essa enquete?' :
                    'Deseja realmente remover todas as respostas dessa enquete?' }
                />

                <Box
                  align='center'
                  margin={{ top: 'medium' }}
                  flex={{ shrink: 0 }}
                >
                  <Box
                    width={visibleContentWidth[size]}
                    round='small'
                    pad={{ vertical: 'large' }}
                    margin={{ bottom: 'medium', horizontal: 'xsmall' }}
                    elevation='small'
                  >
                    <SimpleDetail
                      title='Ref.'
                      value={reference}
                    />

                    <SimpleDetail
                      title='Data de encerramento'
                      value={formattedDateTime(endDate)}
                    />

                    <Hr />

                    <Box
                      margin={{
                        top: 'medium',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      alignSelf='center'
                    >
                      <Text size='small' textAlign='center' color='status-warning'>
                      {actionToDelete === 'survey' ?
                        'Após a confirmação a enquete será PERMANENTEMENTE excluida.' :
                        'Após a confirmação as respostas serão PERMANENTEMENTE excluidas.' }
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  align='center'
                  flex={{ shrink: 0 }}
                >
                  <ErrorForm errorsList={postDataError.errors} />
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                  flex={{ shrink: 0 }}
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormDeleteLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='status-error'
                      primary
                      label={ !loadingPostForm ? 'SIM, Remover': 'Removendo...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          reference,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ surveyReducer: { remove }, layerReducer }) => ({
  id: remove.id,
  reference: remove.reference,
  endDate: remove.endDate,
  actionToDelete: remove.actionToDelete,

  loadingPostForm: remove.loadingPostForm,
  postDataError: remove.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove,
    removeAnswer,
    searchPolls,
    toggleFormDeleteLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);
