import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormCancel from '../components/form-change-status/form-change-status';
import FormCreate from '../components/form-manager/form-manager';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchAppointments, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions } from '../../../actions/select-options';

class Appointments extends Component {
  componentDidMount() {
    const {
      getUsersOptions,
      searchAppointments,
      setDefaultPageRules,
    } = this.props;

    getUsersOptions();
    setDefaultPageRules();
    searchAppointments();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormCancel />
        <FormCreate />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,
    searchAppointments,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
