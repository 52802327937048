import { curry, map, when, propEq, assoc } from 'ramda';

export default curry((value, key, items) => map(
    when(
      propEq('id', key),
      assoc('isCanceled', value),
    ),
    items
  )
)
