const TYPES = ({
  generateExcel: 'GENERATE_CSV_LEGISLATIVE_PROCESS',

  // list
  loadingFormSearch: 'LOADING_SEARCH_LEGISLATIVE_PROCESS_FORM_TOGGLE',
  searchSuccess: 'SEARCH_LEGISLATIVE_PROCESS_SUCCESS',
  searchError: 'SEARCH_LEGISLATIVE_PROCESS_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_LEGISLATIVE_PROCESS_FORM_LOADING',
  loadDataEdit: 'MANAGER_LEGISLATIVE_PROCESS_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_LEGISLATIVE_PROCESS_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_LEGISLATIVE_PROCESS_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_LEGISLATIVE_PROCESS_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_LEGISLATIVE_PROCESS_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_LEGISLATIVE_PROCESS_SUCCESS_UPDATE_LISTING',
  changeFormManagerField: 'CHANGE_MANAGER_LEGISLATIVE_PROCESS_FORM_FIELD',
  addOrRemoveinList: 'CHANGE_MANAGER_LEGISLATIVE_PROCESS_FORM_FIELD_LIST',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_LEGISLATIVE_PROCESS_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_LEGISLATIVE_PROCESS_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_LEGISLATIVE_PROCESS_FORM',
  paginationFormSearch: 'SEARCH_LEGISLATIVE_PROCESS_PAGINATION',

  // change status
  changeFormStatusField: 'CHANGE_STATUS_LEGISLATIVE_PROCESS_FORM_FIELD',
  setDataFormStatus: 'SET_DATA_LEGISLATIVE_PROCESS_FORM_CHANGE_STATUS',
  loadingFormChangeStatus: 'LOADING_STATUS_LEGISLATIVE_PROCESS_FORM_TOGGLE',
  formChangeStatusError: 'POST_STATUS_LEGISLATIVE_PROCESS_FORM_ERROR',
  updateItemListingChangeStatus: 'STATUS_LEGISLATIVE_PROCESS_SUCCESS_UPDATE_LISTING',

  // archive
  setDataFormArchive: 'SET_DATA_LEGISLATIVE_PROCESS_FORM_ARCHIVE',
  formArchiveError: 'POST_ARCHIVE_LEGISLATIVE_PROCESS_FORM_ERROR',
  setPreviewFormArchive: 'SET_PREVIEW_LEGISLATIVE_PROCESS_FORM_ARCHIVE',
  loadingFormArchive: 'LOADING_ARCHIVE_LEGISLATIVE_PROCESS_FORM_TOGGLE',

  // comment
  changeFormCommentField: 'CHANGE_COMMENT_LEGISLATIVE_PROCESS_FORM_FIELD',
  setDataFormComment: 'SET_DATA_LEGISLATIVE_PROCESS_FORM_COMMENT',
  postLoadingFormComment: 'POST_LOADING_COMMENT_LEGISLATIVE_PROCESS_FORM_TOGGLE',
  postFormCommentError: 'POST_COMMENT_LEGISLATIVE_PROCESS_FORM_ERROR',

  // prepare doc
  changeFormPrepareDocField: 'CHANGE_PREPARE_DOC_LEGISLATIVE_PROCESS_FORM_FIELD',
  setDataFormPrepareDoc: 'SET_DATA_LEGISLATIVE_PROCESS_FORM_PREPARE_DOC',
  postLoadingFormPrepareDoc: 'POST_LOADING_PREPARE_DOC_LEGISLATIVE_PROCESS_FORM_TOGGLE',
  postFormPrepareDocError: 'POST_PREPARE_DOC_LEGISLATIVE_PROCESS_FORM_ERROR',
  listTemplarePrepareDoc: 'LIST_TEMPLATE_PREPARE_DOC_LEGISLATIVE_PROCESS',
  clearFormPrepareDoc: 'PREPARE_DOC_LEGISLATIVE_PROCESS_FORM_CLEAR',

  // notification
  showNotification: 'LEGISLATIVE_PROCESS_SHOW_NOTIFICATION',
  hideNotification: 'LEGISLATIVE_PROCESS_HIDE_NOTIFICATION',
});

export default TYPES;
