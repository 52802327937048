import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { create as c, update as u, details } from '../../../api/common';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const searchDetails = id =>
  details(id, 'expense.php')
    .then(response => ({
        type: TYPES.loadDataEdit,
        payload: response,
      })
    )
    .catch(() => ({ type: TYPES.loadingFormManagerEditError }))

const loadingPostManager = () => ({
  type: TYPES.loadingPostFormManager,
});

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'expense.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(notification(`A despesa ${response.data.reference} foi atualizada!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({ type: TYPES.formManagerPostError, payload: responseFail.response });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'expense.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(notification(`A despesa ${response.data.reference} foi criada!`));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value) => ({
  type: TYPES.changeFormManagerField,
  payload: value,
});

export const copyLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerCopy },
      searchDetails(params.id),
    ]
  }

  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
}

export const toggleFormManagerLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerEdit },
      searchDetails(params.id),
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: TYPES.clearFormManager }
];
