import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Anchor, TextInput, Text, Box, Select } from 'grommet';
import { AddCircle, Trash } from 'grommet-icons';

import { TitleField, CalendarDropButton, DescriptionField, SelectSearch } from '../../../../components/form';
import { Placeholder, ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import { searchServices } from '../../actions/form-search';
import { getVotersOptions } from '../../../../actions/select-options';
import { update, create, changeField, hideFormManagerLayer, addDocument, removeDocument } from '../../actions/form-manager';

import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

import selectDocumentTypeOptions from '../../../../constants/select-document-type-options';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchServices,
      hideFormManagerLayer,
    } = this.props;

    searchServices();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  onSearchVoters = text => {
    if (text.length < 2) return;

    const { getVotersOptions } = this.props;
    getVotersOptions({ name: text });
  };

  render() {
    const {
      votersOptions,
      usersOptions,
      statusOptions,
      originOptions,
      categoryOptions,
      officeOptions,

      categoryId,
      description,
      documents,
      electorId,
      id,
      officeId,
      originId,
      requestDate,
      completionDate,
      responsibles,
      statusId,
      voterName,

      getDataFormEditError,
      isEditing,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      hideFormManagerLayer,

      addDocument,
      removeDocument,
    } = this.props;


    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {isEditing && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                  <>
                    <TitleDetail value={id ? 'Editar atendimento' : 'Novo atendimento'} />

                    <Box flex overflow='auto'>
                      {!isEditing && (
                        <Box
                          margin={{
                            vertical: 'small',
                            horizontal: 'large'
                          }}
                          flex={{ shrink: 0 }}
                        >
                          <TitleField text='Buscar eleitor' required />

                          <Select
                            searchPlaceholder='Digite o nome do eleitor para buscar...'
                            emptySearchMessage='Nenhum resultado encontrado.'
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            dropHeight='small'
                            closeOnChange
                            value={electorId ?? ''}
                            options={votersOptions}
                            onChange={({ value: nextValue }) => changeField({ fieldName: 'electorId', value: nextValue }, 'changeFormManagerField')}
                            onSearch={text => this.onSearchVoters(text)}
                          >
                            {({ name, telephone, districtName, cityName }) => {
                              return (
                                <Box pad={{ horizontal: 'small', vertical: 'small' }}>
                                  <Text size='medium' color='dark-1'>{name}</Text>
                                  <Text size='xsmall' color='dark-5'>{telephone}</Text>
                                  <Text size='xsmall' color='dark-5'>{districtName}, {cityName}</Text>
                                </Box>
                              )
                            }}
                          </Select>

                          <ErrorForm errorsList={postDataError.errors} fieldName='electorId' />
                        </Box>
                      )}

                      {isEditing && (
                        <Box
                          margin={{
                            vertical: 'small',
                            horizontal: 'large'
                          }}
                          flex={{ shrink: 0 }}
                        >
                          <TitleField text='Eleitor' />

                          <TextInput
                            value={voterName}
                            disabled={isEditing}
                          />
                        </Box>
                      )}


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Status' required />

                        <SelectSearch
                          options={statusOptions}
                          value={statusId ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          disabled={isEditing}
                          title={isEditing ? 'Para trocar o status utilize a função que está na listagem de atendimento.' : null}
                          emptySearchMessage='Selecione o status desse atendimento'
                          onChange={({ value: nextValue }) => changeField({ fieldName: 'statusId', value: nextValue }, 'changeFormManagerField') }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='statusId' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Data solicitação' required
                          />

                          <DescriptionField
                            text='Data que o atendimento foi solicitado pelo eleitor.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <CalendarDropButton
                            date={requestDate ?? ''}
                            onSelect={(selectedDate) => changeField({ fieldName: 'requestDate', value: selectedDate }, 'changeFormManagerField')}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='requestDate' />
                        </Box>
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        flex={{ shrink: 0 }}
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Data conclusão'
                          />

                          <DescriptionField
                            text='Data que o atendimento foi finalizado.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <CalendarDropButton
                            date={completionDate ?? ''}
                            onSelect={(selectedDate) => changeField({ fieldName: 'completionDate', value: selectedDate }, 'changeFormManagerField')}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='completionDate' />
                        </Box>
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Origem' required />

                        <SelectSearch
                          options={originOptions}
                          value={originId ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          emptySearchMessage='Selecione qual o canal do atendimento'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'originId', value: nextValue }, 'changeFormManagerField')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='originId' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='50vw'>
                            <TitleField text='Escritório' required />

                            <SelectSearch
                              options={officeOptions}
                              value={officeId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              onChange={({ value: nextValue }) => changeField({ fieldName: 'officeId', value: nextValue }, 'changeFormManagerField') }
                              emptySearchMessage='Selecione qual o escritório desse atendimento'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='officeId' />
                          </Box>

                          <Box width='50vw'>
                            <TitleField text='Categoria' required />

                            <SelectSearch
                              options={categoryOptions}
                              value={categoryId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              onChange={({ value: nextValue }) => changeField({ fieldName: 'categoryId', value: nextValue }, 'changeFormManagerField') }
                              emptySearchMessage='Selecione a categoria do atendimento'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='categoryId' />
                          </Box>
                        </Box>
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '40vw'}
                          gap='xxsmall'
                        >
                          <TitleField text='Responsáveis' />

                          <DescriptionField
                            text='Usuários responsáveis por acompanhar o atendimento.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '60vw'}
                        >
                          <Select
                            options={
                              usersOptions.filter(
                                a => a.status || (responsibles && responsibles.some(userId => userId === a.id))
                              )
                            }
                            value={responsibles ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            multiple
                            closeOnChange={false}
                            onChange={({ value: nextValue }) => changeField({ fieldName: 'responsibles', value: nextValue }, 'changeFormManagerField') }
                            emptySearchMessage='Selecione os usuários que estão trabalhando nesse atendimento'
                            valueLabel={
                              responsibles && responsibles.length > 1 ? (
                                <Box pad='small'>
                                  <Text>{`${responsibles.length} selecionado(s)`}</Text>
                                </Box>
                              ) : null
                            }
                          />
                        </Box>
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Descrição' required />

                        <TextArea
                          rows={5}
                          resize='vertical'
                          maxLength={1000}
                          value={description ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'description', value: event.target.value }, 'changeFormManagerField')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                      </Box>

                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{ horizontal: 'large', bottom: 'small' }}
                        flex={{ shrink: 0 }}
                        direction='row'
                        justify='between'
                        align='center'
                      >
                        <Text size='medium' color='dark-2'>
                          Documentos que foram gerados por esse atendimento
                        </Text>

                        <Anchor
                          icon={<AddCircle size='medium' />}
                          title='Adicionar documento'
                          onClick={() => addDocument({
                              number: null,
                              typeId: null,
                              year: null,
                            })
                          }
                        />
                      </Box>

                      {documents && documents.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            margin={{
                              vertical: 'small',
                              horizontal: 'large'
                            }}
                            flex={{ shrink: 0 }}
                          >
                            <Box direction='row' align='center' gap='small'>
                              <Box>
                                <TitleField text='Tipo' required />

                                <Select
                                  options={selectDocumentTypeOptions}
                                  value={item.typeId ?? ''}
                                  labelKey='name'
                                  valueKey={{ key: 'id', reduce: true }}
                                  emptySearchMessage='Selecione o tipo do documento'
                                  onChange={({ value: nextValue }) =>
                                    changeField({ value: nextValue, item: 'typeId', index }, 'changeFormManagerDocument')
                                  }
                                />
                              </Box>

                              <Box>
                                <TitleField text='Número' required />

                                <TextInput
                                  type='number'
                                  value={item.number ?? ''}
                                  onChange={event => changeField({ value: event.target.value, item: 'number', index }, 'changeFormManagerDocument')}
                                />
                              </Box>

                              <Box margin={{ right: 'xsmall' }}>
                                <TitleField text='Ano' required />

                                <TextInput
                                  type='number'
                                  value={item.year ?? ''}
                                  onChange={event => changeField({ value: event.target.value, item: 'year', index }, 'changeFormManagerDocument')}
                                />
                              </Box>

                              <Anchor
                                icon={<Trash />}
                                color='status-error'
                                title='Remover'
                                onClick={() =>
                                  removeDocument(item)
                                }
                              />
                            </Box>
                          </Box>
                        )
                      })}

                      {postDataError.errors && postDataError.errors.length > 0 && (
                        <Box
                          margin={{
                            vertical: 'small',
                            horizontal: 'large'
                          }}
                          flex={{ shrink: 0 }}
                        >
                          <ErrorForm errorsList={postDataError.errors} fieldName='documents.all' />
                        </Box>
                      )}
                    </Box>


                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              categoryId,
                              description,
                              documents,
                              electorId,
                              id,
                              officeId,
                              originId,
                              requestDate: formattedDateTime(requestDate),
                              completionDate: formattedDateTime(completionDate),
                              responsibles,
                              statusId,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ servicesReducer: { formManager }, layerReducer, selectOptionsReducer }) => ({
  votersOptions: selectOptionsReducer.votersOptions,
  usersOptions: selectOptionsReducer.usersOptions,
  statusOptions: selectOptionsReducer.statusServiceOptions,
  originOptions: selectOptionsReducer.originServiceOptions,
  categoryOptions: selectOptionsReducer.categoryServiceOptions,
  officeOptions: selectOptionsReducer.officeOptions,

  categoryId: formManager.categoryId,
  description: formManager.description,
  documents: formManager.documents,
  electorId: formManager.electorId,
  id: formManager.id,
  officeId: formManager.officeId,
  originId: formManager.originId,
  requestDate: formManager.requestDate,
  completionDate: formManager.completionDate,
  responsibles: formManager.responsibles,
  statusId: formManager.statusId,
  voterName: formManager.voterName,

  getDataFormEditError: formManager.getDataFormEditError,
  isEditing: formManager.isEditing,
  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getVotersOptions,
    update,
    create,
    changeField,
    searchServices,
    hideFormManagerLayer,
    addDocument,
    removeDocument,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
