import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { create as c, update as u } from '../../../api/common';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const loadingPostManager = () => ({
  type: TYPES.loadingPostFormManager,
});

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'legislative.processe.status.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(notification(`O status ${response.data.name} foi atualizado!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({ type: TYPES.formManagerPostError, payload: responseFail.response });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'legislative.processe.status.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.insertItemListingManagerSuccess,
          payload: response,
        });

        dispatch(notification(`O status ${response.data.name} foi criado!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value) => ({
  type: TYPES.changeFormManagerField,
  payload: value,
});

export const showFormManagerLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerEdit },
      { type: TYPES.loadDataEdit, payload: params }
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: TYPES.clearFormManager }
];
