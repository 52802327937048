import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

export const Tag = (props) => {
  const {
    text = 'Undefined',
    background = 'dark-5',
    textColor = 'white'
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          background={background}
          round='xxsmall'
          pad={{ 
            horizontal: size === 'small' ? 'small' : 'xsmall',
            vertical: size === 'small' ? 'xxsmall' : 'none',
          }}
        >
          <Text
            title={text.toUpperCase()}
            truncate
            color={textColor}
            size='small'
          >
            {text.toUpperCase()}
          </Text>
        </Box>
      )}
      
    </ResponsiveContext.Consumer>
  )
}
