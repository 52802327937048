const TYPES = ({
  generateExcel: 'GENERATE_CSV_SERVICE',

  // archive
  setDataFormArchive: 'SET_DATA_SERVICE_FORM_ARCHIVE',
  formArchiveError: 'POST_ARCHIVE_SERVICE_FORM_ERROR',
  setPreviewFormArchive: 'SET_PREVIEW_SERVICE_FORM_ARCHIVE',
  loadingFormArchive: 'LOADING_ARCHIVE_SERVICE_FORM_TOGGLE',

  //options
  getDistrictOptions: 'GET_DISTRICTS_MODULE_SERVICE',
  getDistrictOptionsLoad: 'GET_DISTRICTS_LOAD_MODULE_SERVICE',

  // list
  loadingFormSearch: 'LOADING_SEARCH_SERVICE_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SERVICE_SUCCESS',
  searchError: 'SEARCH_SERVICE_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_SERVICE_FORM_LOADING',
  loadDataEdit: 'MANAGER_SERVICE_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_SERVICE_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_SERVICE_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_SERVICE_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_SERVICE_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_SERVICE_SUCCESS_UPDATE_LISTING',

  changeFormManagerField: 'CHANGE_MANAGER_SERVICE_FORM_FIELD',
  changeFormManagerDocument: 'CHANGE_MANAGER_SERVICE_FORM_DOCUMENTS',
  addDocumentFormManager: 'ADD_MANAGER_SERVICE_FORM_DOCUMENT',
  removeDocumentFormManager: 'REMOVE_MANAGER_SERVICE_FORM_DOCUMENT',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_SERVICE_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_SERVICE_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_SERVICE_FORM',
  paginationFormSearch: 'SEARCH_SERVICE_PAGINATION',

  // delete
  setDataFormDelete: 'SET_STATUS_SERVICE_FORM_ID',
  loadingFormDelete: 'LOADING_POST_FORM_DELETE_SERVICE',
  formPostDeleteError: 'POST_FORM_DELETE_SERVICE_ERROR',

  // change status
  updateItemListingChangeStatus: 'STATUS_SERVICE_SUCCESS_UPDATE_LISTING',

  // comment
  changeFormCommentField: 'CHANGE_COMMENT_SERVICE_FORM_FIELD',
  setDataFormComment: 'SET_DATA_SERVICE_FORM_COMMENT',
  postLoadingFormComment: 'POST_LOADING_COMMENT_SERVICE_FORM_TOGGLE',
  postFormCommentError: 'POST_COMMENT_SERVICE_FORM_ERROR',

  // notification
  showNotification: 'SERVICE_SHOW_NOTIFICATION',
  hideNotification: 'SERVICE_HIDE_NOTIFICATION',
});

export default TYPES;
