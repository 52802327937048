import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormChangeStatus from '../components/form-change-status/form-change-status';
import FormComment from '../components/form-comment/form-comment';
import FormCreate from '../components/form-manager/form-manager';
import FormReminder from '../components/form-reminder/form-reminder';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import FormCreateArchive from '../components/form-archive/form-archive';
import Header from '../../../components/header/header';

import { searchServices, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions, getGroupsOptions } from '../../../actions/select-options';

import {
  getOfficeOptions,
  getStatusServiceOptions,
  getOriginsServiceOptions,
  getCategoriesServiceOptions,
} from '../../../actions/select-options';

class Services extends Component {
  componentDidMount() {
    const {
      getGroupsOptions,
      getStatusServiceOptions,
      getOriginsServiceOptions,
      getCategoriesServiceOptions,
      getOfficeOptions,
      getUsersOptions,

      searchServices,
      setDefaultPageRules,
    } = this.props;

    getGroupsOptions();
    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
    getOfficeOptions();
    getUsersOptions();

    setDefaultPageRules();
    searchServices();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormChangeStatus />
        <FormComment />
        <FormCreate />
        <FormReminder />
        <Details />
        <FormCreateArchive />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getGroupsOptions,
    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    getOfficeOptions,
    getUsersOptions,

    searchServices,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
