import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormGenerateTag from '../components/form-generate-tag/form-generate-tag';
import FormCreateService from '../components/form-create-service/form-create-service';
import FormReminder from '../components/form-reminder/form-reminder';
import FormShortMessage from '../components/form-short-message/form-short-message';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchVoters, setDefaultPageRules } from '../actions/form-search';
import { getTagOptions } from '../actions/select-options';
import { getOfficeOptions, getStatusServiceOptions, getOriginsServiceOptions, getCategoriesServiceOptions, getUsersOptions, getGroupsOptions } from '../../../actions/select-options';

class Voters extends Component {
  componentDidMount() {
    const {
      getGroupsOptions,
      getTagOptions,

      getStatusServiceOptions,
      getOriginsServiceOptions,
      getCategoriesServiceOptions,
      getOfficeOptions,
      getUsersOptions,

      searchVoters,
      setDefaultPageRules,
    } = this.props;

    // voter
    getGroupsOptions();
    getTagOptions();

    // service - status, users, category, office, origin
    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
    getOfficeOptions();
    getUsersOptions();

    setDefaultPageRules();
    searchVoters();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormGenerateTag />
        <FormCreateService />
        <FormReminder />
        <Details />
        <FormShortMessage />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getGroupsOptions,
    getTagOptions,

    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    getOfficeOptions,
    getUsersOptions,

    searchVoters,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Voters);
