import TYPES from '../actions/types';
import paginationDefault from '../../../constants/pagination';

const INITIAL_STATE = {
  status: null,
  startDate: null,
  endDate: null,
  reference: null,
  subject: null,
  participantId: null,

  page: paginationDefault.page,
  limit: paginationDefault.limit,
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormSearchFields:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }

    case TYPES.setDefaultPage:
      return {
        ...state,
        page: paginationDefault.page,
        limit: paginationDefault.limit,
      };

    case TYPES.clearFormSearchFields:
      return {
        ...state,
        status: null,
        startDate: null,
        endDate: null,
        reference: null,
        subject: null,
        participantId: null,
      }

    case TYPES.paginationFormSearch:
      return { ...state, page: action.payload };

    default:
      return state;
  }
}
