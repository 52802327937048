const TYPES = ({
  getDetailsError: 'GET_DETAILS_MODULE_DASHBOARD_ERROR',

  getDetailService: 'GET_SERVICE_MODULE_DASHBOARD',
  getDetailVoter: 'GET_VOTER_MODULE_DASHBOARD',
  getDetailVoterDistrict: 'GET_VOTER_DISTRICT_MODULE_DASHBOARD',
  getTotalPendingTask: 'GET_TOTAL_PENDING_TASK_MODULE_DASHBOARD',
});

export default TYPES;
