import urlRoutes from '../../config/url-routes';

export default [{
    label: 'Eleitores',
    click: urlRoutes.voterListUrl,
    present: ['xlarge', 'large', 'medium', 'small'],
    location: 'primary',
  },
  {
    label: 'Atendimentos',
    click: urlRoutes.serviceListUrl,
    present: ['xlarge', 'large', 'medium', 'small'],
    location: 'primary',
  },
  {
    label: 'Compromissos',
    click: urlRoutes.appointmentListUrl,
    present: ['xlarge'],
    location: 'primary',
  },
  {
    label: 'Campanhas',
    click: urlRoutes.campaignListUrl,
    present: ['xlarge', 'large'],
    location: 'primary',
  },
  {
    label: 'Despesas',
    click: urlRoutes.expensesListUrl,
    present: ['xlarge', 'large', 'medium'],
    location: 'primary',
  },
  {
    label: 'Participativa',
    click: urlRoutes.manifestationsDashUrl,
    present: ['xlarge', 'large'],
    location: 'primary',
  },

  // secondMenu
  {
    label: 'Docs. Proposituras, Adm.',
    click: urlRoutes.legislativeProcessesListUrl,
    location: 'second',
  },
  {
    label: 'Enquetes',
    click: urlRoutes.pollsListUrl,
    location: 'second',
  },
  {
    label: 'Ligação',
    click: urlRoutes.phonecallListUrl,
    location: 'second',
  },
  {
    label: 'Tarefas',
    click: urlRoutes.tasksListUrl,
    location: 'second',
  },

  // adm
  {
    label: 'Área do cliente',
    click: urlRoutes.customerAreaUrl,
    location: 'adm',
  },
  {
    label: 'Configurações',
    click: urlRoutes.settingsUrl,
    location: 'adm',
  },
];
