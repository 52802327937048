import React from 'react';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Edit } from 'grommet-icons';

import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const configs = [
    {
      name: 'Status dos atendimentos',
      description: 'Os status são importantes para organizar os atendimentos',
      url: urlRoutes.settingsServiceStatusUrl,
      area: 1,
    },
    {
      name: 'Grupos dos eleitores',
      description: 'Os grupos são importantes para segmentar os eleitores cadastrados',
      url: urlRoutes.settingsGroupUrl,
      area: 1,
    },
    {
      name: 'Origem dos atendimentos',
      description: 'As origens ajudam a informar por qual meio os atendimentos são cadastrados',
      url: urlRoutes.settingsOriginUrl,
      area: 1,
    },
    {
      name: 'Categoria dos atendimentos',
      description: 'As categorias são importantes para segmentar seus atendimentos',
      url: urlRoutes.settingsCategoryUrl,
      area: 1,
    },
    {
      name: 'Usuários',
      description: 'Cadastre, altere, inative os usuários',
      url: urlRoutes.settingsUserUrl,
      area: 2,
    },
    {
      name: 'Escritórios',
      description: 'Cadastre, altere os escritórios',
      url: urlRoutes.settingsOfficeUrl,
      area: 1,
    },
    {
      name: 'Status docs. proposituras, adm.',
      description: 'Os status são importantes para organizar os documentos',
      url: urlRoutes.settingsLegislativeProcesseStatusUrl,
      area: 1,
    }
  ];

  const redirect = (url) => {
    const { history } = props;
    history.push(url);
  };

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width={ size === 'small' || size === 'medium' ? '100vw' : '70vw' }
              gap={ size === 'small' ? 'medium' : 'xsmall' }
            >
              <Box
                margin={{ bottom: 'medium' }}
              >
                <Text size='large'>Personalize o sistema</Text>
              </Box>

              <Box
                round='small'
                border={{ color: 'light-4' }}
                background='white'
                elevation='xsmall'
                margin={{ bottom: 'large' }}
              >
                {configs.filter(a => a.area === 1).map((row, i) => {
                  const isLastLine = configs.filter(a => a.area === 1).length === i + 1;

                  return (
                    <Box
                      key={i}
                      align='center'
                      direction='row'
                      pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'large' : 'small' }}
                      border={ !isLastLine ? { color: 'light-3', side: 'bottom' } : null }
                      justify='between'
                      gap={ size === 'small' ? 'medium' : 'small'}
                    >
                      <Box width='25vw'>
                        <Text color='dark-2' size='medium' truncate>
                          {row.name}
                        </Text>
                      </Box>

                      <Box width='50vw'>
                        <Text color='dark-4' size='small' truncate>
                          {row.description}
                        </Text>
                      </Box>

                      <Box width='10vw' align='end'>
                        <Button
                          title='Editar'
                          size='small'
                          icon={<Edit color='brand' />}
                          onClick={() => redirect(row.url) }
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>


              <Box
                round='small'
                border={{ color: 'light-4' }}
                background='white'
                elevation='xsmall'
                margin={{ bottom: 'large' }}
              >
                {configs.filter(a => a.area === 2).map((row, i) => {
                  const isLastLine = configs.filter(a => a.area === 2).length === i + 1;

                  return (
                    <Box
                      key={i}
                      align='center'
                      direction='row'
                      pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'large' : 'small' }}
                      border={ !isLastLine ? { color: 'light-3', side: 'bottom' } : null }
                      justify='between'
                    >
                      <Box width='25vw'>
                        <Text color='dark-2' size='medium' truncate>
                          {row.name}
                          </Text>
                      </Box>

                      <Box width='50vw'>
                        <Text color='dark-4' size='small' truncate>
                          {row.description}
                        </Text>
                      </Box>

                      <Box width='20vw' align='end'>
                        <Button
                          title='Editar'
                          size='small'
                          icon={<Edit color='brand' />}
                          onClick={() => redirect(row.url) }
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>

            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

export default withRouter(SearchResult);
