import TYPES from '../actions/types';

const INITIAL_STATE = {
  id: null,
  password: null,
  name: null,
  email: null,

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormPassField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }

    case TYPES.loadDataChangePass:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        password: null,
      }

    case TYPES.loadFormChangePass:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formChangePassError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
