import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Tabs, Tab } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, Description, Wrapper, TitleDetail, CopyUrl, Statistic } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import { toggleDetailsLayer } from '../../actions/details';
import { formatWeekDay, formatColor } from '../../utils';

import visibleWidth from '../../../../constants/layer-width';

class DetailsSurvey extends Component {
  getTotalVote = (answers) => {
    if(answers) {
      return answers.reduce((acc, current) => acc += current.total, 0);
    }

    return 0;
  }

  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      const totalVote = detail ? this.getTotalVote(detail.answers) : 0;

      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value='Detalhes, votação e estatísticas da enquete' />

                    <Tabs>
                      <Tab title='Detalhes'>
                        <Box
                          margin={{
                            top: size === 'small' ? 'large' : 'medium',
                            bottom: size === 'small' ? 'small' : 'none',
                          }}
                        >
                          <SimpleDetail
                            title='Referência'
                            value={detail.reference}
                          />

                          <SimpleDetail
                            title='Data de encerramento'
                            value={formattedDateTime(detail.endDate)}
                          />

                          <Hr />

                          <CopyUrl
                            title='Link de compartilhamento'
                            value={`https://survey.gerenciameumandato.com.br/${detail.identifier}`}
                          />

                          <Hr />

                          <Description
                            title='Pergunta'
                            value={detail.question}
                          />

                          <Hr />

                          <Description
                            title='Opções de resposta'
                          />

                          {detail.options.map((a, i) => {
                            return (
                              <SimpleDetail
                                key={i}
                                title={`Opção ${i + 1}`}
                                value={a.op}
                              />
                            )
                          })}

                          {detail.thanksText && (
                            <>
                              <Hr />

                              <Description
                                title='Mensagem de agradecimento após o voto'
                                value={detail.thanksText}
                              />
                            </>
                          )}
                        </Box>
                      </Tab>

                      <Tab title='Votação'>
                        <Box
                          margin={{
                            top: size === 'small' ? 'large' : 'medium',
                            bottom: size === 'small' ? 'small' : 'none',
                          }}
                        >
                          <Wrapper
                            value={
                              <Text
                                size='medium'
                                textAlign='start'
                                color='dark-3'
                              >
                                Atualmente já foram coletadas <strong>{totalVote}</strong> respostas e a
                                data de encerramento da enquete é <strong>{formattedDateTime(detail.endDate)}</strong>.
                              </Text>
                            }
                          />

                          {detail.answers.map((a, index) => {
                            return (
                              <Statistic
                                key={index}
                                title={a.item}
                                color={formatColor(index)}
                                amount={a.total}
                                total={totalVote}
                              />
                            )
                          })}
                        </Box>
                      </Tab>

                      <Tab title='Outras estatísticas'>
                        <Box
                          margin={{
                            top: size === 'small' ? 'large' : 'medium',
                            bottom: size === 'small' ? 'small' : 'none',
                          }}
                        >
                          <Wrapper
                            value={
                              <Text
                                size='medium'
                                textAlign='start'
                                color='dark-3'
                              >
                                Votos por dia da semana
                              </Text>
                            }
                          />

                          {detail.weekDay.map((a, index) => {
                            return (
                              <Statistic
                                key={index}
                                title={formatWeekDay(a.item)}
                                color={formatColor(index)}
                                amount={a.total}
                                total={totalVote}
                              />
                            )
                          })}

                          <Hr />

                          <Wrapper
                            value={
                              <Text
                                size='medium'
                                textAlign='start'
                                color='dark-3'
                              >
                                Votos por horário
                              </Text>
                            }
                          />

                          {detail.hour.map((a, index) => {
                            return (
                              <Statistic
                                key={index}
                                title={`${a.item}h`}
                                color={formatColor(index)}
                                amount={a.total}
                                total={totalVote}
                              />
                            )
                          })}
                        </Box>
                      </Tab>
                    </Tabs>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSurvey);
