import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box, Select, Anchor } from 'grommet';

import { TitleField, CalendarDropButton, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchExpenses, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

import selectExpenseCategory from '../../../../constants/select-expense-category';
import selectExpenseStatusOptions from '../../../../constants/select-expense-status';
import selectYesNoOptions from '../../../../constants/select-yesno-options';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchExpenses, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchExpenses(formParams);
    hideLayer();
  };

  render() {
    const {
      reference,
      categoryId,
      statusId,
      startDateExpense,
      endDateExpense,
      recipient,
      isRefund,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Buscar' />

                <Box flex overflow='auto'>
                  {/* Referência/Reembolso */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Referência' />

                        <TextInput
                          value={reference ?? ''}
                          onChange={event => changeField({ fieldName: 'reference', value: event.target.value })}
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Reembolso' />

                          {isRefund && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'isRefund', value: null })
                            }
                          />)}
                        </Box>

                        <SelectSearch
                          options={selectYesNoOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={isRefund ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'isRefund', value: nextValue })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Categoria/Status */}

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Categoria' />

                          {categoryId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'categoryId', value: null })
                            }
                          />)}
                        </Box>

                        <Select
                          options={selectExpenseCategory}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={categoryId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'categoryId', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Status' />

                          {statusId && (<Anchor
                            size='small'
                            label='limpar'
                            onClick={() =>
                              changeField({ fieldName: 'statusId', value: null })
                            }
                          />)}
                        </Box>

                        <Select
                          options={selectExpenseStatusOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={statusId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'statusId', value: nextValue })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Data despesa */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data da despesa' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startDateExpense ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'startDateExpense', value: selectedDate })}
                      />

                      <CalendarDropButton
                        date={endDateExpense ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'endDateExpense', value: selectedDate })}
                      />
                    </Box>
                  </Box>

                  {/* Nome */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Nome' />

                    <TextInput
                      value={recipient ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'recipient', value: event.target.value })
                      }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          reference,
                          categoryId,
                          statusId,
                          startDateExpense: formattedDateTime(startDateExpense),
                          endDateExpense: formattedDateTime(endDateExpense),
                          recipient,
                          isRefund,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ expenseReducer: { formSearch }, layerReducer }) => ({
  reference: formSearch.reference,
  categoryId: formSearch.categoryId,
  statusId: formSearch.statusId,
  startDateExpense: formSearch.startDateExpense,
  endDateExpense: formSearch.endDateExpense,
  recipient: formSearch.recipient,
  isRefund: formSearch.isRefund,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchExpenses,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
