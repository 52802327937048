import TYPES from '../actions/types';

const INITIAL_STATE = {
  id: 0,
  reference: '',
  status: '',
  statusColor: '',

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.setDataFormDelete:
      return {
        ...state,
        id: action.payload.id,
        reference: action.payload.reference,
        status: action.payload.status,
        statusColor: action.payload.statusColor,
        postDataError: { message: '', errors: [] },
      }

    case TYPES.loadingFormDelete:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostDeleteError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
