import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, TextInput, Box } from 'grommet';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { update, create, changeField, hideFormManagerLayer } from '../../actions/form-manager';

class FormCreate extends Component {
  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({ formParams });
    }
  };

  render() {
    const {
      id,
      name,

      getDataFormEditError,
      isEditing,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      hideFormManagerLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {isEditing && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                  <>
                    <TitleDetail value={id ? 'Editar categoria' : 'Nova categoria'} />

                    <Box flex overflow='auto'>
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Nome' required />

                        <TextInput
                          maxLength={50}
                          value={name ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'name', value: event.target.value })
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                      </Box>
                    </Box>

                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              name,
                            })
                          }
                        />
                      </Box>


                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsCategoryReducer: { formManager }, layerReducer }) => ({
  id: formManager.id,
  name: formManager.name,

  getDataFormEditError: formManager.getDataFormEditError,
  isEditing: formManager.isEditing,
  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeField,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
