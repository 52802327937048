import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box } from 'grommet';

import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchPolls, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchPolls, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchPolls(formParams);
    hideLayer();
  };

  render() {
    const {
      reference,
      question,
      startDate,
      endDate,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Buscar' />

                <Box flex overflow='auto'>
                  {/* Referência */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Referência' />

                    <TextInput
                      value={reference ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'reference', value: event.target.value })
                      }
                    />
                  </Box>

                  {/* Titulo */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Pergunta' />

                    <TextInput
                      value={question ?? ''}
                      onChange={event =>
                        changeField({ fieldName: 'question', value: event.target.value })
                      }
                    />
                  </Box>

                  {/* Data enquete */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data encerramento enquete' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startDate ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'startDate', value: selectedDate })}
                      />

                      <CalendarDropButton
                        date={endDate ?? ''}
                        onSelect={(selectedDate) => changeField({ fieldName: 'endDate', value: selectedDate })}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                    <Box width='small'>
                      <Button
                        color='dark-3'
                        label='Limpar'
                        onClick={() => clearFieldsFormSearch()}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        width='medium'
                        color='brand'
                        primary
                        label='Buscar'
                        onClick={() =>
                          this.searchAndSetPagination({
                            reference,
                            question,
                            startDate: formattedDateTime(startDate),
                            endDate: formattedDateTime(endDate),
                            page: paginationDefault.page,
                            limit: paginationDefault.limit,
                          })
                        }
                      />
                    </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ surveyReducer: { formSearch }, layerReducer }) => ({
  reference: formSearch.reference,
  question: formSearch.question,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchPolls,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
