import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Text, Button } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr } from '../../../../components/detail';
import { formattedDateTime, formattedCurrency, formattedNumber } from '../../../../utils';
import { Tag } from '../../../../components';

import { toggleBuyLayer } from '../../actions/communication-balance';

import urlRoutes from '../../../../config/url-routes';


const findCommunicationBalanceByType = (items, typeFilter) => {
  if (!items) return 0;

  const filteredItems = items.filter(a => a.typeCommunicationId === typeFilter);
  const total = filteredItems.reduce((acc, current) => acc + current.amount, 0);

  return total;
}

function DetailsCustomerArea(props) {
  const {
    detail,
    communicationBalance,
    getDetailError,

    toggleBuyLayer,

    history,
  } = props;

  if(getDetailError) {
    return (
      <Placeholder
        title='Ops! Algo deu errado :('
        message='Estamos constrangidos por isso.'
      />
    );
  }

  if((!detail || !communicationBalance) && !getDetailError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '80px' }}
        >
          <Box
            pad='small'
            width={ size === 'small' || size === 'medium' ? '100vw' : '70vw' }
            gap={ size === 'small' ? 'medium' : 'xsmall' }
          >
            <Box
              margin={{ bottom: 'medium' }}
            >
              <Text size='large'>Dados da conta</Text>
            </Box>

            <Box
              round='small'
              border={{ color: 'light-4' }}
              background='white'
              elevation='xsmall'
              margin={{ bottom: 'small' }}
              pad={{ vertical: 'medium' }}
            >
              <SimpleDetail
                title='Nome'
                value={detail.name}
              />

              <SimpleDetail
                title='Telefone'
                value={detail.telephone}
              />

              <SimpleDetail
                title='E-mail'
                value={detail.mail}
              />

              <SimpleDetail
                title='Criada em'
                value={formattedDateTime(detail.createdAt)}
              />

              <Hr />

              <Box
                flex={{ shrink: 0 }}
              >
                <Text size='small' textAlign='center' color='status-warning'>
                  Caso precise mudar algum das informações entre em contato conosco.
                </Text>
              </Box>
            </Box>


            {/* Cobrança */}
            {detail.billing && (
              <>
                <Box margin={{ vertical: 'medium' }}>
                  <Text size='large'>
                    Informações de cobrança
                  </Text>
                </Box>

                <Box
                  round='small'
                  border={{ color: 'light-4' }}
                  background='white'
                  elevation='xsmall'
                  pad={{ vertical: 'large', horizontal: 'large' }}
                >
                  <Box
                    direction={ size === 'small' ? 'column' : 'row' }
                    gap={ size === 'small' ? 'medium' : 'small' }
                  >
                    <Box
                      width='80vw'
                    >
                      <Text size='medium' color='dark-2'>{detail.billing.name}</Text>
                      <Text size='medium' color='dark-4'>{detail.billing.document}</Text>
                    </Box>

                    <Box
                      width='20vw'
                    >
                      <Button
                        primary
                        title='Faturas'
                        label='Faturas'
                        onClick={() => history.push(urlRoutes.customerAreaInvoicesUrl) }
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}


            {/* Saldo */}
            <Box margin={{ vertical: 'medium' }}>
              <Text size='large'>
                Saldo SMS, E-mail
              </Text>
            </Box>

            <Box
              round='small'
              border={{ color: 'light-4' }}
              background='white'
              elevation='xsmall'
              pad={{ vertical: 'large', horizontal: 'large' }}
            >
              <Box
                direction={ size === 'small' ? 'column-reverse' : 'row' }
                gap={ size === 'small' ? 'medium' : 'small' }
                justify='between'
              >
                <Box
                  width={ size === 'xsmall' || size === 'small' ? 'xlarge' : 'medium'}
                  gap='medium'
                >
                  <Box
                    gap='xsmall'
                  >
                    <Text size='small' color='dark-2'>O saldo de SMS e e-mail pode ser utilizado em todas as funcionalidades do sistema.</Text>
                    <Text size='xsmall' color='dark-3'>A validade de cada compra é de 6 (seis) meses.</Text>
                  </Box>

                  <Box width={ size === 'xsmall' || size === 'small'  ? 'xlarge' : 'medium' }>
                    <Button
                      primary
                      title={!detail.billing ? 'No período de avaliação não é possível realizar a ação.' : 'Comprar pacote de SMS ou e-mail.'}
                      label='Comprar'
                      disabled={!detail.billing}
                      onClick={() => toggleBuyLayer({ id: detail.clientId }) }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  gap={ size === 'small' ? 'xlarge' : 'large' }
                >
                  <Box align={ size === 'small' ? 'start' : 'end'}>
                    <Text size='xlarge' color='brand'>
                      {formattedNumber(findCommunicationBalanceByType(communicationBalance, 2))}
                    </Text>
                    <Text size='small' color='dark-3'>SMS</Text>
                  </Box>

                  <Box align={ size === 'small' ? 'start' : 'end'}>
                    <Text size='xlarge' color='brand'>
                      {formattedNumber(findCommunicationBalanceByType(communicationBalance, 1))}
                    </Text>
                    <Text size='small' color='dark-3'>E-mail</Text>
                  </Box>
                </Box>
              </Box>
            </Box>


            {/* Dados gerais */}
            <Box margin={{ vertical: 'medium' }}>
              <Text size='large'>
                Dados gerais
              </Text>
            </Box>

            <Box
              round='small'
              border={{ color: 'light-4' }}
              background='white'
              elevation='xsmall'
              margin={{ bottom: 'small' }}
              pad={{ vertical: 'medium', horizontal: 'large' }}
            >
              {detail.billing && (<Box
                border={{ color: 'light-3', side: 'bottom' }}
                pad={{ vertical: size === 'small' ? 'medium' : 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2'>
                  Valor
                </Text>

                <Text size='medium' color='dark-4'>
                  {formattedCurrency(detail.product.value)}
                </Text>
              </Box>)}


              {detail.billing && (<Box
                border={{ color: 'light-3', side: 'bottom' }}
                pad={{ vertical: size === 'small' ? 'medium' : 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2'>
                  Período da licença
                </Text>

                <Text size='medium' color='dark-4'>
                  {formattedDateTime(detail.product.dateStartLicense)} até {formattedDateTime(detail.product.dateEndLicense)}
                </Text>
              </Box>)}


              {detail.product.dateExpireTrial && (<Box
                border={{ color: 'light-3', side: 'bottom' }}
                pad={{ vertical: size === 'small' ? 'medium' : 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2'>
                  Data final de avaliação
                </Text>

                <Text size='medium' color='dark-4'>
                  {formattedDateTime(detail.product.dateExpireTrial)}
                </Text>
              </Box>)}


              <Box
                pad={{ vertical: size === 'small' ? 'medium' : 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2'>
                  Status da conta
                </Text>

                {detail.billing && (
                  <Tag
                    text={detail.product.status ? 'ativa' : 'inativa'}
                    background={detail.product.status ? 'status-ok' : 'status-error'}
                    textColor='white'
                  />
                )}

                {!detail.billing && detail.product.dateExpireTrial && (
                  <Tag
                    text='período de avaliação'
                    background='status-warning'
                    textColor='white'
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ customerAreaReducer: { details } }) => ({
  detail: details.detail,
  communicationBalance: details.communicationBalance,
  getDetailError: details.getDetailError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleBuyLayer,
  }, dispath)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsCustomerArea));
