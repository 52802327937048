import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { createService } from '../../../api/service';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const loadingPostForm = () => ({
  type: TYPES.loadingPostFormCreateService,
});

export const create = params => {
  return dispatch => {
    dispatch(loadingPostForm());

    createService(params.formParams)
      .then(response => {
        dispatch(loadingPostForm());
        dispatch(notification(`O atendimento ${response.data.reference} foi criado!`));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostForm());
        dispatch({
          type: TYPES.postFormCreateServiceError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value) => ({
  type: TYPES.changeFieldFormCreateService,
  payload: value,
});

export const toggleFormCreateServiceLayer = (params) => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.voter,
      action: analytics.actions.voterNewService,
    });

    return [
      { type: TYPES.clearFormCreateService },
      { type: COMMON_TYPES.toggleLayer, payload: 'createService' },
      { type: TYPES.loadDataFormCreateService, payload: params },
    ]
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
