import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Box, MaskedInput, Select, TextInput, Anchor } from 'grommet';
import { Clock, Phone } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchPhonecalls } from '../../actions/form-search';
import { update, create, changeField, hideFormManagerLayer } from '../../actions/form-manager';

import visibleWidth from '../../../../constants/layer-width';
import { formattedDateTime } from '../../../../utils';

import selectPhonecallStatusOptions from '../../../../constants/select-phonecall-status-options';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchPhonecalls,
      hideFormManagerLayer,
    } = this.props;

    searchPhonecalls();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      usersOptions,

      id,
      name,
      telephone,
      subject,
      date,
      hour,
      userWantedId,
      status,

      getDataFormEditError,
      isEditing,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      hideFormManagerLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {isEditing && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                  <>
                    <TitleDetail value={id ? 'Editar ligação' : 'Nova ligação'} />

                    <Box flex overflow='auto'>
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Dados da pessoa que ligou' required />

                        <Box direction='row' gap='small'>
                          <Box width='50vw'>
                            <TextInput
                              maxLength={255}
                              value={name}
                              placeholder='Nome...'
                              onChange={event => changeField({ fieldName: 'name', value: event.target.value })}
                            />
                          </Box>

                          <Box width='50vw'>
                            <MaskedInput
                              value={telephone}
                              placeholder='Telefone...'
                              icon={<Phone />}
                              reverse={true}
                              onChange={event => changeField({ fieldName: 'telephone', value: event.target.value })}
                              mask={[
                                { fixed: '(' },
                                { length: 2, regexp: /\d/ },
                                { fixed: ')' },
                                { fixed: ' ' },
                                { length: 4, regexp: /\d/ },
                                { fixed: '-' },
                                { length: [4, 5], regexp: /\d/ }
                              ]}
                            />
                          </Box>
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Data e horário' required />

                        <Box
                          direction='row'
                          gap='small'
                        >
                          <Box width='50vw'>
                            <CalendarDropButton
                              date={date}
                              onSelect={(selectedDate) => changeField({ fieldName: 'date', value: selectedDate })}
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                          </Box>

                          <Box width='50vw'>
                            <MaskedInput
                              value={hour}
                              placeholder='hh:mm'
                              icon={<Clock />}
                              reverse={true}
                              onChange={event => changeField({ fieldName: 'hour', value: event.target.value })}
                              mask={[
                                { length: 2, regexp: /^0[1-9]$|^1[0-9]$|^2[0-3]$|^[0-9]$/ },
                                { fixed: ':' },
                                { length: 2, regexp: /^[0-5][0-9]$|^[0-9]$/ },
                              ]}
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='hour' />
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Status' required />

                        <Select
                          options={selectPhonecallStatusOptions}
                          value={status}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          onChange={({ value: nextValue }) => changeField({ fieldName: 'status', value: nextValue })}
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='status' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Assunto' required />

                        <TextArea
                          resize='vertical'
                          maxLength={2000}
                          value={subject}
                          onChange={event => changeField({ fieldName: 'subject', value: event.target.value })}
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='subject' />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Pessoa procura' />

                        <Select
                          options={usersOptions.filter(a => a.status || a.id === userWantedId)}
                          value={userWantedId}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          onChange={({ value: nextValue }) => changeField({ fieldName: 'userWantedId', value: nextValue })}
                        />
                      </Box>
                    </Box>

                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              name,
                              telephone,
                              subject,
                              date: formattedDateTime(date),
                              hour,
                              userWantedId,
                              status,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ phonecallsReducer: { formManager, selectOptions }, layerReducer, selectOptionsReducer }) => ({
    usersOptions: selectOptionsReducer.usersOptions,

    id: formManager.id,
    name: formManager.name,
    telephone: formManager.telephone,
    subject: formManager.subject,
    date: formManager.date,
    hour: formManager.hour,
    userWantedId: formManager.userWantedId,
    status: formManager.status,

    getDataFormEditError: formManager.getDataFormEditError,
    isEditing: formManager.isEditing,
    loadingPostForm: formManager.loadingPostForm,
    postDataError: formManager.postDataError,

    toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeField,
    searchPhonecalls,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
