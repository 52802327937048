import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { changePass } from '../../../api/settings-user';

const loadingPostChangeStatusToggle = () => ({
  type: TYPES.loadFormChangePass,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const updatePass = params => {
  return dispatch => {
    dispatch(loadingPostChangeStatusToggle());

    changePass(params.formParams)
      .then(response => {
        dispatch(toggleFormChangePassLayer());
        dispatch(loadingPostChangeStatusToggle());

        dispatch(notification(`O usuário ${response.data.name} foi atualizado!`))
      })
      .catch(responseFail => {
        dispatch(loadingPostChangeStatusToggle());
        dispatch({
          type: TYPES.formChangePassError,
          payload: responseFail.response,
        });
      })
  }
}

export const changeField = (value) => ({
  type: TYPES.changeFormPassField,
  payload: value,
});

export const toggleFormChangePassLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changePass' },
      { type: TYPES.loadDataChangePass, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
