import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box, Select, Anchor } from 'grommet';

import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchPhonecalls, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

import selectPhonecallStatusOptions from '../../../../constants/select-phonecall-status-options';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchPhonecalls, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchPhonecalls(formParams);
    hideLayer();
  };

  render() {
    const {
      reference,
      name,
      telephone,
      subject,
      status,
      startDate,
      endDate,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Buscar' />

                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Referência' />

                    <TextInput
                      value={reference ?? ''}
                      onChange={event => changeField({ fieldName: 'reference', value: event.target.value })}
                    />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Nome' />

                        <TextInput
                          value={name ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'name', value: event.target.value })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Telefone' />

                        <TextInput
                          value={telephone ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'telephone', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data de cadastro' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startDate ?? ''}
                        placeholder='De'
                        onSelect={(selectedDate) => changeField({ fieldName: 'startDate', value: selectedDate })}
                      />

                      <CalendarDropButton
                        date={endDate ?? ''}
                        placeholder='Até'
                        onSelect={(selectedDate) => changeField({ fieldName: 'endDate', value: selectedDate })}
                      />
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField({ fieldName: 'status', value: null })
                        }
                      />)}
                    </Box>

                    <Select
                      options={selectPhonecallStatusOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={status ?? ''}
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'status', value: nextValue })
                      }
                    />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Assunto' />

                    <TextInput
                      value={subject ?? ''}
                      onChange={event => changeField({ fieldName: 'subject', value: event.target.value })}
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          status,
                          startDate: formattedDateTime(startDate),
                          endDate: formattedDateTime(endDate),
                          reference,
                          subject,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ phonecallsReducer: { formSearch }, layerReducer }) => ({
  reference: formSearch.reference,
  name: formSearch.name,
  telephone: formSearch.telephone,
  subject: formSearch.subject,
  status: formSearch.status,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchPhonecalls,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
