import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Select, Button, TextInput, Paragraph, Text, MaskedInput, ResponsiveContext, Anchor } from 'grommet';

import { ErrorForm, HeaderHtml } from '../../../components';
import { InputPassword } from '../../../components/form';
import Header from '../components/header/header';

import urlsApp from '../../../config/url-routes';

import { changeField, postCreateTrial } from '../actions/onboarding-actions';

import selectProductOptions from '../constants/select-product-options';
import selectStateOptions from '../../../constants/select-state-options';

class OnBoardingStart extends Component {
  callbackPostSuccess = () => {
    const { history } = this.props;
    history.push(urlsApp.onboardingWelcomeUrl);
  };

  handlePost = formParams => {
    const { postCreateTrial } = this.props;
    postCreateTrial({ formParams, callbackSuccess: this.callbackPostSuccess });
  };

  render() {
    const {
      name,
      telephone,
      city,
      state,
      product,
      mail,
      password,
      postDataError,
      loadingPostForm,
      changeField,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title='Experimente grátis a solução perfeita para gerenciar seu mandato e campanha política' />
            <Header />

            <Box
              direction='column'
              align='center'
            >
              <Box
                align='center'
                width='large'
                pad={ size === 'small' ? 'large' : 'none' }
              >
                <Box margin={{ bottom: 'small' }}>
                  <Text size='xlarge'>
                    Crie sua conta
                  </Text>
                </Box>

                <Box margin={{ top: 'none', bottom: 'large' }}>
                  <Text size='medium' color='dark-5'>
                    Experimente grátis por 15 dias!
                  </Text>
                </Box>

                <Box
                  direction={ size === 'small' ? 'column' : 'row' }
                  margin={{ bottom: 'small' }}
                  gap='small'
                  fill
                >
                  <Box
                    width='medium'
                    fill
                  >
                    <TextInput
                      placeholder='Nome *'
                      value={name}
                      onChange={ event => changeField({ fieldName: 'name', value: event.target.value }) }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                  </Box>

                  <Box
                    width='medium'
                    fill
                  >
                    <MaskedInput
                      mask={[
                        { fixed: '(' },
                        { length: 2, regexp: /\d/ },
                        { fixed: ')' },
                        { fixed: ' ' },
                        { length: 4, regexp: /\d/ },
                        { fixed: '-' },
                        { length: [4, 5], regexp: /\d/ }
                      ]}
                      placeholder='Telefone *'
                      value={telephone}
                      onChange={ event => changeField({ fieldName: 'telephone', value: event.target.value }) }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='telephone' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  margin={{ bottom: 'small' }}
                  gap='small'
                >
                  <Box
                    width='medium'
                  >
                    <TextInput
                      placeholder='Cidade *'
                      value={city}
                      onChange={ event => changeField({ fieldName: 'city', value: event.target.value }) }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='city' />
                  </Box>

                  <Box
                    width='medium'
                  >
                    <Select
                      options={selectStateOptions}
                      value={state}
                      placeholder='Estado *'
                      labelKey='name'
                      onChange={({ option }) => changeField({ fieldName: 'state', value: option }) }
                      emptySearchMessage='Selecione uma opção'
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='state' />
                  </Box>
                </Box>

                <Box direction='row'>
                  <Box
                    width='large'
                  >
                    <Select
                      options={selectProductOptions}
                      value={product}
                      placeholder='Quero testar para *'
                      labelKey='name'
                      onChange={({ option }) => changeField({ fieldName: 'product', value: option }) }
                      emptySearchMessage='Selecione uma opção'
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='product' />
                  </Box>
                </Box>


                <Box margin={{ vertical: 'large' }}>
                  <Text size='medium' color='brand'>
                    Configure e-mail e senha para acessar o sistema
                  </Text>
                </Box>

                <Box
                  direction={ size === 'small' ? 'column' : 'row' }
                  gap='small'
                  fill
                >
                  <Box width='medium' fill>
                    <TextInput
                      placeholder='E-mail *'
                      value={mail}
                      onChange={ event => changeField({ fieldName: 'mail', value: event.target.value }) }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='mail' />
                  </Box>

                  <Box width='medium' fill>
                    <InputPassword
                      value={password}
                      placeholder='Senha *'
                      onChange={event =>
                        changeField({ fieldName: 'password', value: event.target.value })
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='password' />
                  </Box>
                </Box>

                <Box margin={{ top: 'small', bottom: 'large' }}>
                  <Paragraph size='medium' fill color='dark-1'>
                    Ao clicar em "Finalizar e criar conta", você concorda com os <Anchor href={urlsApp.termsOfUse} target='_blank'>Termos de uso</Anchor> e com a <Anchor href={urlsApp.termsOfUse} target='_blank'>Política de Privacidade</Anchor>.
                  </Paragraph>
                </Box>

                <Box
                  direction='row'
                  margin={{ bottom: 'small' }}
                >
                  <Box
                    width='small'
                    justify='center'
                    align='center'
                  >
                    <Anchor
                      label='Cancelar'
                      href={urlsApp.site}
                    />
                  </Box>

                  <Box
                    width='medium'
                  >
                    <Button
                      primary
                      label={ !loadingPostForm ? 'Criar conta' : 'Carregando...' }
                      disabled={ loadingPostForm }
                      onClick={() => {
                        this.handlePost({
                          name,
                          telephone,
                          city,
                          state,
                          mail,
                          password,
                          product,
                        })
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ onboardingReducer: { form } }) => ({
  name: form.name,
  telephone: form.telephone,
  city: form.city,
  state: form.state,
  product: form.product,
  mail: form.mail,
  password: form.password,
  postDataError: form.postDataError,
  loadingPostForm: form.loadingPostForm,
});

const mapdispatchToProps = dispatch => bindActionCreators({
  changeField,
  postCreateTrial,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapdispatchToProps)(OnBoardingStart)
);
