import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box, Select, Anchor } from 'grommet';

import { TitleField, CalendarDropButton, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchAppointments, clearFieldsFormSearch, hideLayer, setDefaultPageRules } from '../../actions/form-search';
import { changeValues } from '../../../../actions/form';

import selectStatusOptions from '../../constants/select-status-options';
import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchAppointments, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchAppointments(formParams);
    hideLayer();
  };

  render() {
    const {
      usersOptions,

      status,
      startDate,
      endDate,
      reference,
      subject,
      participantId,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeValues,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Buscar' />

                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Referência' />

                    <TextInput
                      value={reference ?? ''}
                      onChange={event =>
                        changeValues({ fieldName: 'reference', value: event.target.value }, 'changeFormSearchFields')
                      }
                    />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Período' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startDate ?? ''}
                        onSelect={(selectedDate) =>
                          changeValues({ fieldName: 'startDate', value: selectedDate }, 'changeFormSearchFields')
                        }
                      />

                      <CalendarDropButton
                        date={endDate ?? ''}
                        onSelect={(selectedDate) =>
                          changeValues({ fieldName: 'endDate', value: selectedDate }, 'changeFormSearchFields')
                        }
                      />
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Na agenda de' />

                      {participantId && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'participantId', value: null }, 'changeFormSearchFields')
                        }
                      />)}
                    </Box>

                    <SelectSearch
                      emptySearchMessage='Selecione uma opção'
                      options={usersOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={participantId ?? ''}
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'participantId', value: nextValue }, 'changeFormSearchFields')
                      }
                    />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status !== null && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'status', value: null }, 'changeFormSearchFields')
                        }
                      />)}
                    </Box>

                    <Select
                      options={selectStatusOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={status ?? ''}
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'status', value: nextValue }, 'changeFormSearchFields')
                      }
                    />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Assunto' />

                    <TextInput
                      value={subject ?? ''}
                      onChange={event =>
                        changeValues({ fieldName: 'subject', value: event.target.value }, 'changeFormSearchFields')
                      }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          status,
                          startDate: formattedDateTime(startDate),
                          endDate: formattedDateTime(endDate),
                          reference,
                          subject,
                          participantId,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ appointmentsReducer: { formSearch }, layerReducer, selectOptionsReducer }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  status: formSearch.status,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
  reference: formSearch.reference,
  subject: formSearch.subject,
  participantId: formSearch.participantId,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchAppointments,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeValues,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
