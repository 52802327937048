import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { details } from '../../../api/common';
import { createAudit } from '../../../api/user';

const searchDetails = params =>
  details(params.id, 'eleitor.php')
    .then(response => {
      createAudit(
        'ACESSO_FICHA_ELEITOR',
        `Acessou os detalhes do eleitor ref.: ${params.id}.`
      );

      return {
        type: COMMON_TYPES.detailsGetResult,
        payload: response,
      }
    })
    .catch(() => ({ type: COMMON_TYPES.detailsGetResultError }))

export const toggleDetailsLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.voter,
      action: analytics.actions.voterDetails,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'details' },
      searchDetails(params),
    ]
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.detailsGetResult, payload: { data: null } }
  ];
};
