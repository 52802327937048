import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Menu } from 'grommet';
import { Notes, Edit, Transaction, Chat, Print, More, Download, Search, Trash, Alarm, Upload, DocumentText } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination, Tag } from '../../../../components';

import { toggleDetailsLayer } from '../../actions/details';
import { generatePrint } from '../../actions/print';
import { generateCsv } from '../../actions/export-csv';
import { toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { toggleFormCreateArchiveLayer } from '../../actions/form-archive';
import { toggleFormCommentLayer } from '../../actions/form-comment';
import { toggleFormReminderLayer } from '../../actions/form-reminder';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { toggleFormManagerLayer } from '../../actions/form-manager';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { toggleFormPrepareDocLayer } from '../../actions/form-prepare-doc';

import { formattedNumber } from '../../../../utils';
import rulesName from '../../../../constants/rules';

function SearchResult(props) {
  const {
    userLogged: { rules },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    type,
    status,
    areaInterest,
    year,
    number,
    menu,
    region,
    page,
    limit,

    toggleDetailsLayer,
    generatePrint,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormCreateArchiveLayer,
    toggleFormCommentLayer,
    toggleFormReminderLayer,
    openFormSearchLayer,
    toggleFormPrepareDocLayer,
    goToNextPage,
    goToPrevPage,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createProposition) ?
          { label: 'Novo documento', action: toggleFormManagerLayer } : null
      }
    />;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => toggleFormManagerLayer(row)
      });
    }

    if(rules.includes(rulesName.editProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <DocumentText />
            <Text size='small'>Elaborar doc.</Text>
          </Box>
        ),
        onClick: () => toggleFormPrepareDocLayer(row)
      });
    }

    if(rules.includes(rulesName.editProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Transaction />
            <Text size='small'>Trocar status</Text>
          </Box>
        ),
        onClick: () => toggleFormChangeStatusLayer(row)
      });
    }

    if(rules.includes(rulesName.editProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Upload />
            <Text size='small'>Upload arquivo</Text>
          </Box>
        ),
        onClick: () => toggleFormCreateArchiveLayer(row)
      });
    }

    if(rules.includes(rulesName.printProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Print />
            <Text size='small'>Imprimir</Text>
          </Box>
        ),
        onClick: () => generatePrint(row)
      });
    }

    if(rules.includes(rulesName.commentProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Chat />
            <Text size='small'>Comentar</Text>
          </Box>
        ),
        onClick: () => toggleFormCommentLayer(row)
      });
    }

    if(rules.includes(rulesName.editProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Alarm />
            <Text size='small'>Criar lembrete</Text>
          </Box>
        ),
        onClick: () => toggleFormReminderLayer(row)
      });
    }

    if(rules.includes(rulesName.deleteProposition)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer(row)
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'Documentos' : 'Documento'}
                  </Text>
                </Box>

                <Box direction='row' align='center'>
                  {rules.includes(rulesName.printProposition) &&
                    <Button
                      title={`Download Excel (.csv) dos ${formattedNumber(totalOfRows)} processo(s) encontrado(s)`}
                      icon={<Download />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => {
                        generateCsv({
                          type,
                          status,
                          areaInterest,
                          year,
                          number,
                          menu,
                          region,
                          page: 1,
                          limit: totalOfRows,
                        })
                      }}
                    />
                  }

                  <Button
                    title='Filtrar'
                    icon={<Search />}
                    size='small'
                    onClick={() => { openFormSearchLayer() }}
                  />

                  {rules.includes(rulesName.createProposition) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Novo'
                      onClick={() => { toggleFormManagerLayer() }}
                      margin={{ left: 'small' }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && size !== 'medium' && (
                  <Box width='20vw'>
                    <Text color='dark-2'>Tipo</Text>
                  </Box>
                )}

                {size !== 'small' && <Box width='20vw'>
                  <Text color='dark-2'>Númeração</Text>
                </Box>}

                {size !== 'small' && <Box width='80vw'>
                  <Text color='dark-2'>Assunto</Text>
                </Box>}

                {size !== 'small' && size !== 'medium' && (
                  <Box width='30vw'>
                    <Text color='dark-2'>Status</Text>
                  </Box>
                )}

                <Box width='20vw' />
              </Box>

              {dataRows.map(row => {
                const menu = optionsMenu(row);

                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && size !== 'medium' && (
                      <Box width='20vw'>
                        <Text color='dark-2' size='small'>{row.type}</Text>
                      </Box>
                    )}

                    <Box width='20vw'>
                      <Text color='dark-2' size='small'>{row.number}/{row.year}</Text>
                    </Box>

                    <Box width='80vw' direction='row'>
                      <Text
                        color='dark-2'
                        size='small'
                        title={row.menu}
                      >
                        {row.menu}
                      </Text>
                    </Box>

                    {size !== 'small' && size !== 'medium' && (
                      <Box width='30vw' direction='row'>
                        <Tag text={row.status} background={row.statusColor} />
                      </Box>
                    )}

                    <Box align='end' width='20vw'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        {menu.length > 0 && (<Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={menu}
                        />)}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  type,
                  status,
                  areaInterest,
                  year,
                  number,
                  menu,
                  region,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, legislativeProcessesReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  type: formSearch.type,
  status: formSearch.status,
  areaInterest: formSearch.areaInterest,
  year: formSearch.year,
  number: formSearch.number,
  menu: formSearch.menu,
  region: formSearch.region,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    generatePrint,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormCreateArchiveLayer,
    toggleFormCommentLayer,
    toggleFormReminderLayer,
    openFormSearchLayer,
    toggleFormPrepareDocLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
