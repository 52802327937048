import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import { searchLegislativeProcesses } from '../../actions/form-search';
import { remove, toggleFormDeleteLayer } from '../../actions/form-delete';

import visibleWidth from '../../../../constants/layer-width';
import visibleContentWidth from '../../../../constants/layer-content-width';

class FormDelete extends Component {
  callbackPostSuccess = () => {
    const {
      searchLegislativeProcesses,
      toggleFormDeleteLayer,
    } = this.props;

    searchLegislativeProcesses();
    toggleFormDeleteLayer();
  };

  handlePost = formParams => {
    const { remove } = this.props;
    remove({
      formParams,
      callbackSuccess: this.callbackPostSuccess,
    });
  };

  render() {
    const {
      id,
      type,
      number,
      year,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormDeleteLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'delete') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormDeleteLayer}
              onEsc={toggleFormDeleteLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Deseja realmente remover esse documento?' />

                <Box
                  align='center'
                  margin={{ top: 'medium' }}
                  flex={{ shrink: 0 }}
                >
                  <Box
                    width={visibleContentWidth[size]}
                    round='small'
                    pad={{ vertical: 'large' }}
                    margin={{ bottom: 'medium', horizontal: 'xsmall' }}
                    elevation='small'
                  >
                    <SimpleDetail
                      title='Tipo'
                      value={type}
                    />

                    <SimpleDetail
                      title='Número'
                      value={number}
                    />

                    <SimpleDetail
                      title='Ano'
                      value={year}
                    />

                    <Hr />

                    <Box
                      margin={{
                        top: 'medium',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      alignSelf='center'
                    >
                      <Text size='small' textAlign='center' color='status-warning'>
                        Após a confirmação o documento será PERMANENTEMENTE excluido.
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  align='center'
                  flex={{ shrink: 0 }}
                >
                  <ErrorForm errorsList={postDataError.errors} />
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                  flex={{ shrink: 0 }}
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormDeleteLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='status-error'
                      primary
                      label={ !loadingPostForm ? 'SIM, Remover': 'Removendo...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerReducer, formCreateUpdateCopyGeneric }) => ({
  id: formCreateUpdateCopyGeneric.id,
  type: formCreateUpdateCopyGeneric.type,
  number: formCreateUpdateCopyGeneric.number,
  year: formCreateUpdateCopyGeneric.year,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove,
    searchLegislativeProcesses,
    toggleFormDeleteLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);
