const TYPES = ({
  // list
  loadingFormSearch: 'LOADING_SEARCH_PHONECALL_FORM_TOGGLE',
  searchPhonecallSuccess: 'SEARCH_PHONECALL_SUCCESS',
  searchError: 'SEARCH_PHONECALL_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_PHONECALL_FORM_LOADING',
  loadDataEdit: 'MANAGER_PHONECALL_EDIT_DATA_LOAD',
  loadDataCreate: 'MANAGER_PHONECALL_CREATE_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_PHONECALL_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_PHONECALL_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_PHONECALL_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_PHONECALL_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_PHONECALL_SUCCESS_UPDATE_LISTING',
  changeFormManagerFields: 'CHANGE_MANAGER_PHONECALL_FORM_FIELDS',

  // form search
  changeFormSearchFields: 'CHANGE_SEARCH_PHONECALL_FORM_FIELDS',
  setDefaultPage: 'SET_SEARCH_PHONECALL_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_PHONECALL_FORM',
  paginationFormSearch: 'SEARCH_PHONECALL_PAGINATION',

  // notification
  showNotification: 'PHONECALL_SHOW_NOTIFICATION',
  hideNotification: 'PHONECALL_HIDE_NOTIFICATION',
});

export default TYPES;
