import { dateInterfere, datetimeIsAfter } from '../../../utils';

export default object => {
  const dateTimeNow = new Date();
  const licenseExpired = object.product.dateEndLicense ? datetimeIsAfter(object.product.dateEndLicense) : true;
  const isNewClient = !object.billing;

  let startLicense = null;
  let endLicense = null;

  if(isNewClient || licenseExpired) {
    startLicense = dateInterfere(dateTimeNow, 0, 'days');
    endLicense = dateInterfere(dateTimeNow, 31, 'days');
  }
  else {
    startLicense = object.product.dateEndLicense;
    endLicense = dateInterfere(object.product.dateEndLicense, 31, 'days');
  }

  return {
    isNewClient,
    startLicense,
    endLicense,

    identifier: object.identifier,
    clientId: object.clientId,
    clientName: object.name,
    openInvoice: object.openInvoice,
    value: object.product.value,

    inputBillingName: object.billing && object.billing.name ? object.billing.name : null,
    inputBillingDocument: object.billing && object.billing.document ? object.billing.document : null,
  }
}
