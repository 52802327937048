import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, TextInput, Box, Select, RadioButton, MaskedInput, CheckBoxGroup } from 'grommet';
import { Phone } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, InputPassword } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';
import visibleWidth from '../../../../constants/layer-width';

import { update, create, changeRules, changeField, hideFormManagerLayer } from '../../actions/form-manager';

import selectJobRoleOptions from '../../../../constants/select-job-role-options';
import selectRulesOptions from '../../../../constants/select-rules-options';

class FormCreate extends Component {
  handlePost = formParams => {
    const { create, update } = this.props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({ formParams });
    }
  };

  render() {
    const {
      officeOptions,

      id,
      name,
      email,
      password,
      jobRoleId,
      officeId,
      profileId,
      telephone,
      rules,

      isEditing,
      getDataFormEditError,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeRules,
      changeField,
      hideFormManagerLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {isEditing && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                  <>
                    <TitleDetail value={id ? 'Editar usuário' : 'Novo usuário'} />

                    <Box flex overflow='auto'>
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='medium'>
                            <TitleField text='Nome' required />

                            <TextInput
                              maxLength={60}
                              value={name ?? ''}
                              onChange={event =>
                                changeField({ fieldName: 'name', value: event.target.value })
                              }
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='name' />
                          </Box>

                          <Box width='medium'>
                            <TitleField text='Telefone' />

                            <MaskedInput
                              value={telephone ?? ''}
                              placeholder='00 0000 0000'
                              icon={<Phone />}
                              reverse={true}
                              onChange={event =>
                                changeField({ fieldName: 'telephone', value: event.target.value })
                              }
                              mask={[
                                { fixed: '(' },
                                { length: 2, regexp: /\d/ },
                                { fixed: ')' },
                                { fixed: ' ' },
                                { length: 4, regexp: /\d/ },
                                { fixed: '-' },
                                { length: [4, 5], regexp: /\d/ }
                              ]}
                            />
                          </Box>
                        </Box>
                      </Box>


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='medium'>
                            <TitleField text='Cargo' required />

                            <Select
                              options={selectJobRoleOptions}
                              value={jobRoleId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              emptySearchMessage='Selecione uma opção'
                              onChange={({ value: nextValue }) =>
                                changeField({ fieldName: 'jobRoleId', value: nextValue })
                              }
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='jobRoleId' />
                          </Box>

                          <Box width='medium'>
                            <TitleField text='Escritório' required />

                            <Select
                              options={officeOptions}
                              value={officeId ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              emptySearchMessage='Selecione uma opção'
                              onChange={({ value: nextValue }) =>
                                changeField({ fieldName: 'officeId', value: nextValue })
                              }
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='officeId' />
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='E-mail' required />

                        <TextInput
                          maxLength={60}
                          value={email ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'email', value: event.target.value })
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='email' />
                      </Box>


                      {!isEditing && (
                        <Box
                          margin={{
                            vertical: 'small',
                            horizontal: 'large'
                          }}
                          flex={{ shrink: 0 }}
                        >
                          <TitleField text='Senha' required />

                          <InputPassword
                            maxLength={30}
                            value={password ?? ''}
                            onChange={event =>
                              changeField({ fieldName: 'password', value: event.target.value })
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='password' />
                        </Box>
                      )}


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box
                          border={{ color: 'light-5' }}
                          round='xxsmall'
                        >
                          <Box
                            border={{ color: 'light-5', side: 'bottom' }}
                            pad={{ vertical: 'small', horizontal: 'medium' }}
                          >
                            <RadioButton
                              name='type'
                              label='Perfil Administrador'
                              value='1'
                              checked={profileId === '1'}
                              onChange={event => {
                                changeRules(selectRulesOptions);
                                changeField({ fieldName: 'profileId', value: event.target.value })
                              }}
                            />
                          </Box>

                          <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
                            <RadioButton
                              name='type'
                              label='Perfil Básico'
                              value='2'
                              checked={profileId === '2'}
                              onChange={event =>
                                changeField({ fieldName: 'profileId', value: event.target.value })
                              }
                            />
                          </Box>

                          {profileId === '2' && (
                            <Box
                              margin={{
                                top: 'small',
                                bottom: 'large',
                                horizontal: 'medium'
                              }}
                              flex={{ shrink: 0 }}
                            >
                              <CheckBoxGroup
                                labelKey='label'
                                valueKey='id'
                                value={rules}
                                onChange={({ value: nextValue, option }) => {
                                  changeField({ fieldName: 'rules', value: nextValue })
                                }}
                                options={
                                  selectRulesOptions.map(row => ({
                                    label: `${row.category} - ${row.name}`,
                                    id: row.id,
                                  }))
                                }
                              />
                            </Box>
                          )}
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='profileId' />
                      </Box>
                    </Box>

                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              name,
                              email,
                              password,
                              jobRoleId,
                              officeId,
                              profileId,
                              telephone,
                              rules,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsUserReducer: { formManager }, selectOptionsReducer, layerReducer }) => ({
  officeOptions: selectOptionsReducer.officeOptions,

  id: formManager.id,
  name: formManager.name,
  email: formManager.email,
  password: formManager.password,
  jobRoleId: formManager.jobRoleId,
  officeId: formManager.officeId,
  profileId: formManager.profileId,
  telephone: formManager.telephone,
  rules: formManager.rules,

  isEditing: formManager.isEditing,
  getDataFormEditError: formManager.getDataFormEditError,

  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeRules,
    changeField,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
