import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, Description, TitleDetail } from '../../../../components/detail';

import { formattedDateTime, mask } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsManifestation extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value='Detalhes da manifestação' />

                    <SimpleDetail
                      title='Protocolo'
                      value={detail.protocol}
                    />

                    <SimpleDetail
                      title='Data de cadastro'
                      value={formattedDateTime(detail.createdAt)}
                    />

                    <SimpleDetail
                      title='Status'
                      value={detail.statusName}
                    />

                    <SimpleDetail
                      title='Tipo'
                      value={detail.typeName}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Nome'
                      value={detail.name}
                    />

                    {detail.individualRegistration && <SimpleDetail
                      title='CPF'
                      value={mask(detail.individualRegistration, '###.###.###-##')}
                    />}

                    <SimpleDetail
                      title='E-mail'
                      value={detail.contacts.email}
                    />

                    <SimpleDetail
                      title='Telefone'
                      value={mask(detail.contacts.telephone, '(##) ####-#####')}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Cidade'
                      value={detail.location.city}
                    />

                    <SimpleDetail
                      title='Bairro'
                      value={detail.location.district}
                    />

                    <Hr />

                    <Description
                      title='Descrição'
                      value={detail.description}
                    />

                    {detail.history && detail.history.length > 0 &&
                      <Box
                        margin={{
                          top: 'xsmall',
                          bottom: 'none',
                          horizontal: 'large',
                        }}
                        pad={{ bottom: 'small' }}
                        flex={{ shrink: 0 }}
                      >
                        <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                          Histórico
                        </Text>

                        <Box
                          border={{ color: 'light-4' }}
                          round='xsmall'
                          background='light-1'
                          pad={{ vertical: 'medium', horizontal: 'medium' }}
                          margin={{ bottom: 'small' }}
                          height={{ min: 'small', max: 'medium' }}
                          overflow='auto'
                        >
                        {
                          detail.history.map((h, index) => (
                            <Box
                              key={index}
                              direction='row'
                              justify='between'
                              pad={{ vertical: 'xsmall' }}
                              flex={{ shrink: 0 }}
                            >
                              <Box direction='row'>
                                <Text
                                  size='small'
                                  color='dark-5'
                                  margin={{ right: 'small' }}
                                >
                                  {formattedDateTime(h.createdAt, 'DD MMM YYYY HH:mm')}
                                </Text>

                                <Text
                                  size='small'
                                  color='dark-2'
                                  margin={{ right: 'small' }}
                                >
                                  {h.name}
                                </Text>
                              </Box>

                              <Box>
                                <Text size='small' color='dark-3'>
                                  {h.category}
                                </Text>
                              </Box>
                            </Box>
                          ))
                        }
                        </Box>
                      </Box>
                    }
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsManifestation);
