import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        status: always(object.status),
        name: always(object.name),
        email: always(object.email),
        profile: always(object.profile),
      }),
    ),
    items
  )
)
