import TYPES from './types';
import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';
import * as analytics from '../../../analytics';

const _notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const _command = params =>
  dispatch => {
    dispatch(_notification(`Gerando Excel. Aguarde...`));

    list(params, 'compromisso.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            id: { name: 'Ref.', transform: (value) => `AG-${value}` },
            subject: { name: 'Assunto', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            startDate: { name: 'Data Início', transform: (value) => value ?? '' },
            endDate: { name: 'Data Fim', transform: (value) => value ?? '' },
            description: { name: 'Descrição', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            local: { name: 'Local', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            isCanceled: { name: 'Status', transform: (value) => value ? 'CANCELADO' : 'ATIVO' },
          },
          fileName: `listagem-compromissos-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(_notification(`Ops... não conseguimos gerar o Excel.`))
      );
  }

export const generateCsv = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.appointment,
    action: analytics.actions.appointmentExport,
  });

  return _command(params);
};
