import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { remove as d } from '../../../api/common';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const loadingPostDeleteToggle = () => ({
  type: TYPES.loadingFormDelete,
});

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDeleteToggle());

    d(params.formParams.id, 'escritorio.php')
      .then(response => {
        dispatch(loadingPostDeleteToggle());
        dispatch(toggleFormDeleteLayer());

        dispatch({
          type: TYPES.deleteItemListingManagerSuccess,
          payload: params.formParams,
        });

        dispatch(notification(`O escritório foi removido!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostDeleteToggle());
        dispatch({
          type: TYPES.formPostDeleteError,
          payload: responseFail.response
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: TYPES.setDataFormDelete, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
