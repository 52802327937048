import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: '/manifestation.php',
    headers: {
      token: decipher.token.value,
      action: 'getSummary',
    }
  });
}
