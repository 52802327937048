const TYPES = ({
  // list
  loadFormSearchAppointment: 'LOADING_SEARCH_APPOINTMENT_FORM_TOGGLE',
  searchAppointmentSuccess: 'SEARCH_APPOINTMENT_SUCCESS',
  searchError: 'SEARCH_APPOINTMENT_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_APPOINTMENT_FORM_LOADING',
  loadDataEdit: 'MANAGER_APPOINTMENT_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_APPOINTMENT_EDIT_DATA_LOADING_FORM',
  loadingFormManagerCopy: 'MANAGER_APPOINTMENT_COPY_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_APPOINTMENT_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_APPOINTMENT_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_APPOINTMENT_FORM_ERROR',
  changeFormManagerFields: 'CHANGE_MANAGER_APPOINTMENTE_FORM_FIELDS',
  updateItemListingManagerSuccess: 'MANAGER_APPOINTMENT_SUCCESS_UPDATE_LISTING',

  // form search
  changeFormSearchFields: 'CHANGE_SEARCH_APPOINTMENT_FORM_FIELDS',
  setDefaultPage: 'SET_SEARCH_APPOINTMENT_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_APPOINTMENT_FORM',
  paginationFormSearch: 'SEARCH_APPOINTMENT_PAGINATION',

  // change status
  setIdFormCancel: 'SET_STATUS_APPOINTMENT_FORM_ID',
  changeFormChangeStatusReason: 'CHANGE_STATUS_APPOINTMENT_FORM_REASON',
  loadFormChangeStatus: 'LOADING_STATUS_APPOINTMENT_FORM_TOGGLE',
  formChangeStatusError: 'POST_STATUS_APPOINTMENT_FORM_ERROR',
  updateItemListingChangeStatusSuccess: 'STATUS_APPOINTMENT_SUCCESS_UPDATE_LISTING',

  // notification
  showNotification: 'APPOINTMENT_SHOW_NOTIFICATION',
  hideNotification: 'APPOINTMENT_HIDE_NOTIFICATION',

  // public details
  openPublicDetails: 'LOAD_OPEN_APPOINTMENT_PUBLIC_DETAILS',
});

export default TYPES;
