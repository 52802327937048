import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Text, Box, Select, MaskedInput, Anchor } from 'grommet';
import { Spinning } from 'grommet-controls';

import { TitleField, SelectSearch, CalendarDropButton } from '../../../../components/form';
import { Hr, TitleDetail } from '../../../../components/detail';

import { searchVoters, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';
import { searchCityOptions, searchDistrictOptions, searchSchoolOptions } from '../../actions/select-options';

import selectYesNoOptions from '../../../../constants/select-yesno-options';
import selectActiveInactiveOptions from '../../../../constants/select-active-inactive-options';
import selectGenreOptions from '../../../../constants/select-genre-options';
import selectMaritalStatusOptions from '../../../../constants/select-marital-status-options';
import selectOccupationOptions from '../../../../constants/select-occupation-options';
import selectResponsibleTypes from '../../../../constants/select-responsibles-types';

import { formattedDateTime } from '../../../../utils';

import paginationDefault from '../../../../constants/pagination';
import selectVoterStatusOptions from '../../../../constants/select-voter-status-options';
import visibleWidth from '../../../../constants/layer-width';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchVoters, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchVoters(formParams);
    hideLayer();
  };

  onSearchCities = text => {
    if (text.length < 2) return;

    const { searchCityOptions } = this.props;
    searchCityOptions({ name: text });
  };

  onSearchSchools = text => {
    if (text.length < 2) return;

    const { searchSchoolOptions } = this.props;
    searchSchoolOptions({ name: text });
  }

  onSearchDistricts = text => {
    const {
      cityId,
      searchDistrictOptions
    } = this.props;

    if(!cityId || text.length < 2) return;

    searchDistrictOptions({ cityId, name: text });
  };

  render() {
    const {
      groupOptions,
      cityOptions,
      districtOptions,
      getCityOptionsLoad,
      getDistrictOptionsLoad,
      schoolOptions,

      reference,
      individualRegistration,
      name,
      genre,
      cityId,
      districtsId,
      address,
      zipcode,
      status,
      groupsId,
      maritalStatusId,
      professionId,
      withTelephone,
      withEmail,
      telephone,
      email,
      startBirthDate,
      endBirthDate,
      startCreatedDate,
      endCreatedDate,
      voteStatusId,
      responsibleId,
      responsibleName,
      schoolId,

      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;


    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Buscar' />

                <Box flex overflow='auto'>
                  {/* Referência/Nome */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Referência' />

                        <TextInput
                          type='number'
                          value={reference ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'reference', value: event.target.value })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='CPF' />

                        <MaskedInput
                          mask={[
                            { length: 3, regexp: /\d/ },
                            { fixed: '.' },
                            { length: 3, regexp: /\d/ },
                            { fixed: '.' },
                            { length: 3, regexp: /\d/ },
                            { fixed: '-' },
                            { length: 2, regexp: /\d/ }
                          ]}
                          value={individualRegistration ?? ''}
                          placeholder='000 000 000 00'
                          onChange={event =>
                            changeField({ fieldName: 'individualRegistration', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Nome/Apelido/Gênero */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Nome/Apelido' />

                        <TextInput
                          value={name ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'name', value: event.target.value })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Gênero' />

                          {genre && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'genre', value: null })}
                            />
                          )}
                        </Box>

                        <Select
                          options={selectGenreOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={genre ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'genre', value: nextValue })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  {/* Grupo */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Grupo' />

                      {groupsId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => changeField({ fieldName: 'groupsId', value: null })}
                        />
                      )}
                    </Box>

                    <SelectSearch
                      options={groupOptions}
                      value={groupsId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      multiple
                      closeOnChange={false}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'groupsId', value: nextValue })
                      }
                      valueLabel={
                        groupsId && groupsId.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${groupsId.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      LOCAL
                    </Text>
                  </Box>

                  {/* Cidade */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Cidade' />

                      {cityId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => {
                            changeField({ fieldName: 'cityId', value: null });
                            changeField({ fieldName: 'districtsId', value: null });
                          }}
                        />
                      )}
                    </Box>

                    <Select
                      options={cityOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={cityId ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'cityId', value: nextValue }) }
                      onSearch={ text => this.onSearchCities(text) }
                      icon={
                        getCityOptionsLoad ? (
                          <Spinning size='medium' color='brand' kind='pulse' />
                        ) : (
                          true
                        )
                      }
                    />
                  </Box>


                  {/* Bairro */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Bairro' />

                      {districtsId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => changeField({ fieldName: 'districtsId', value: null })}
                        />
                      )}
                    </Box>

                    <Select
                      placeholder={!cityId ? 'Selecione uma cidade antes...' : ''}
                      searchPlaceholder={cityId ? 'Digite para buscar...' : 'Selecione uma cidade antes...'}
                      emptySearchMessage={cityId ? 'Nenhum resultado encontrado.' : 'Você ainda não selecionou uma cidade...'}
                      options={districtOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      dropHeight='small'
                      value={districtsId ?? ''}
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'districtsId', value: nextValue }) }
                      onSearch={ text => this.onSearchDistricts(text) }
                      multiple
                      disabled={!cityId}
                      icon={
                        getDistrictOptionsLoad ? (
                          <Spinning size='medium' color='brand' kind='pulse' />
                        ) : (
                          true
                        )
                      }
                      valueLabel={
                        districtsId && districtsId.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${districtsId.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />
                  </Box>


                  {/* Logradouro/CEP */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Logradouro' />

                        <TextInput
                          value={address ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'address', value: event.target.value })
                          }
                        />
                      </Box>

                      <Box width='medium'>
                        <TitleField text='CEP' />
                        <TextInput
                          type='number'
                          value={zipcode ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'zipcode', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      CONTATOS
                    </Text>
                  </Box>


                  {/* Telefone */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='medium'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Com telefone?' />

                          {withTelephone !== null && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'withTelephone', value: null })}
                            />
                          )}
                        </Box>

                        <Select
                          options={selectYesNoOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={withTelephone ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'withTelephone', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Telefone' />

                        <TextInput
                          value={telephone ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'telephone', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  {/* E-mail */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='medium'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Com e-mail?' />

                          {withEmail !== null && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'withEmail', value: null })}
                            />
                          )}
                        </Box>

                        <Select
                          options={selectYesNoOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={withEmail ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'withEmail', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='E-mail' />

                        <TextInput
                          value={email ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'email', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      ANIVERSARIANTES
                    </Text>
                  </Box>

                  {/* Aniversariantes */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Nascidos entre' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <MaskedInput
                        mask={[
                          { length: 2, regexp: /\d/ },
                          { fixed: '/' },
                          { length: 2, regexp: /\d/ }
                        ]}
                        value={startBirthDate ?? ''}
                        placeholder='00/00'
                        onChange={event =>
                          changeField({ fieldName: 'startBirthDate', value: event.target.value })
                        }
                      />

                      <MaskedInput
                        mask={[
                          { length: 2, regexp: /\d/ },
                          { fixed: '/' },
                          { length: 2, regexp: /\d/ }
                        ]}
                        value={endBirthDate ?? ''}
                        placeholder='00/00'
                        onChange={event =>
                          changeField({ fieldName: 'endBirthDate', value: event.target.value })
                        }
                      />
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      PERÍODOS
                    </Text>
                  </Box>

                  {/* cadastro */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Cadastrado em' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startCreatedDate ?? ''}
                        placeholder='A partir de ...'
                        onSelect={(selectedDate) => changeField({ fieldName: 'startCreatedDate', value: selectedDate })}
                      />

                      <CalendarDropButton
                        date={endCreatedDate ?? ''}
                        placeholder='até...'
                        onSelect={(selectedDate) => changeField({ fieldName: 'endCreatedDate', value: selectedDate })}
                      />
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      VOTAÇÃO
                    </Text>
                  </Box>

                  {/* Status voto */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status do voto' />

                      {voteStatusId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => changeField({ fieldName: 'voteStatusId', value: null })}
                        />
                      )}
                    </Box>

                    <SelectSearch
                      options={selectVoterStatusOptions}
                      value={voteStatusId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'voteStatusId', value: nextValue })
                      }
                    />
                  </Box>

                  {/* Local de votação */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Local de votação' />

                      {schoolId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => {
                            changeField({ fieldName: 'schoolId', value: null });
                          }}
                        />
                      )}
                    </Box>

                    <Select
                      searchPlaceholder='Digite parte do nome para buscar...'
                      emptySearchMessage='Selecione uma opção'
                      options={schoolOptions}
                      value={schoolId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'schoolId', value: nextValue }) }
                      onSearch={ text => this.onSearchSchools(text) }
                    >
                      {({ name, city, state }) => (
                        <Box pad={{ horizontal: 'small', vertical: 'small' }}>
                          <Text size='small' color='dark-1'>{name}</Text>
                          {city && state && (<Text size='xsmall' color='dark-5'>{city} - {state}</Text>)}
                        </Box>
                      )}
                    </Select>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      RESPONSÁVEIS
                    </Text>
                  </Box>

                  {/* Responsável tipo e nome */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='medium'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Tipo' />

                          {responsibleId !== null && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'responsibleId', value: null })}
                            />
                          )}
                        </Box>

                        <Select
                          options={selectResponsibleTypes}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={responsibleId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'responsibleId', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Nome' />

                        <TextInput
                          value={responsibleName ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'responsibleName', value: event.target.value })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Text weight='bold' size='xsmall' color='dark-2'>
                      ADICIONAIS
                    </Text>
                  </Box>

                  {/* Status/Estado civil */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='medium'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Status' />

                          {status !== null && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'status', value: null })}
                            />
                          )}
                        </Box>

                        <Select
                          options={selectActiveInactiveOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={status ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'status', value: nextValue })
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <Box direction='row' align='center' gap='xsmall'>
                          <TitleField text='Estado civil' />

                          {maritalStatusId && (
                            <Anchor
                              size='small'
                              label='limpar'
                              onClick={() => changeField({ fieldName: 'maritalStatusId', value: null })}
                            />
                          )}
                        </Box>

                        <SelectSearch
                          options={selectMaritalStatusOptions}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          value={maritalStatusId ?? ''}
                          emptySearchMessage='Selecione uma opção'
                          onChange={({ value: nextValue }) =>
                            changeField({ fieldName: 'maritalStatusId', value: nextValue })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>


                  {/* Profissão */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Profissão' />

                      {professionId && (
                        <Anchor
                          size='small'
                          label='limpar'
                          onClick={() => changeField({ fieldName: 'professionId', value: null })}
                        />
                      )}
                    </Box>

                    <SelectSearch
                      options={selectOccupationOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={professionId ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'professionId', value: nextValue })
                      }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          reference,
                          individualRegistration,
                          name,
                          genre,
                          cityId,
                          districtsId,
                          address,
                          zipcode,
                          status,
                          groupsId,
                          maritalStatusId,
                          professionId,
                          withTelephone,
                          withEmail,
                          telephone,
                          email,
                          startBirthDate,
                          endBirthDate,
                          voteStatusId,
                          responsibleId,
                          responsibleName,
                          schoolId,
                          startCreatedDate: formattedDateTime(startCreatedDate),
                          endCreatedDate: formattedDateTime(endCreatedDate),
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ votersReducer: { formSearch, selectOptions }, layerReducer, selectOptionsReducer }) => ({
  groupOptions: selectOptionsReducer.groupsOptions,
  cityOptions: selectOptions.cityOptions,
  districtOptions: selectOptions.districtOptions,
  getCityOptionsLoad: selectOptions.getCityOptionsLoad,
  getDistrictOptionsLoad: selectOptions.getDistrictOptionsLoad,
  schoolOptions: selectOptions.schoolOptions,

  reference: formSearch.reference,
  individualRegistration: formSearch.individualRegistration,
  name: formSearch.name,
  genre: formSearch.genre,
  cityId: formSearch.cityId,
  districtsId: formSearch.districtsId,
  address: formSearch.address,
  zipcode: formSearch.zipcode,
  status: formSearch.status,
  groupsId: formSearch.groupsId,
  maritalStatusId: formSearch.maritalStatusId,
  professionId: formSearch.professionId,
  withTelephone: formSearch.withTelephone,
  withEmail: formSearch.withEmail,
  telephone: formSearch.telephone,
  email: formSearch.email,
  startBirthDate: formSearch.startBirthDate,
  endBirthDate: formSearch.endBirthDate,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  voteStatusId: formSearch.voteStatusId,
  responsibleId: formSearch.responsibleId,
  responsibleName: formSearch.responsibleName,
  schoolId: formSearch.schoolId,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchVoters,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,

    searchCityOptions,
    searchDistrictOptions,
    searchSchoolOptions,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
