import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import SearchResult from '../components/search-result/search-result';
import FormCancel from '../components/form-cancel/form-cancel';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchCampaigns, setDefaultPageRules } from '../actions/form-search';

class Campaigns extends Component {
  componentDidMount() {
    const {
      searchCampaigns,
      setDefaultPageRules,
    } = this.props;

    setDefaultPageRules();
    searchCampaigns();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <Details />
        <FormCancel />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchCampaigns,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
