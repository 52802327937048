import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Menu, Anchor } from 'grommet';
import { Notes, Edit, More, Download, Search, Trash, Copy, Alarm } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination } from '../../../../components';

import { toggleDetailsLayer } from '../../actions/details';
import { generateCsv } from '../../actions/export-csv';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { toggleFormManagerLayer, copyLayer } from '../../actions/form-manager';
import { toggleFormReminderLayer } from '../../actions/form-reminder';
import { toggleFormDeleteLayer } from '../../actions/form-delete';

import { formattedDateTime, formattedNumber, formattedCurrency } from '../../../../utils';
import rulesName from '../../../../constants/rules';

function SearchResult(props) {
  const {
    userLogged: { rules },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    reference,
    categoryId,
    statusId,
    startDateExpense,
    endDateExpense,
    recipient,
    isRefund,
    page,
    limit,

    toggleDetailsLayer,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormManagerLayer,
    toggleFormReminderLayer,
    copyLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createExpense) ?
          { label: 'Nova despesa', action: toggleFormManagerLayer } : null
      }
    />;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editExpense)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => toggleFormManagerLayer(row)
      });
    }

    if(rules.includes(rulesName.createExpense)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Copy />
            <Text size='small'>Copiar</Text>
          </Box>
        ),
        onClick: () => copyLayer(row)
      });
    }

    options.push({
      label: (
        <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
          <Alarm />
          <Text size='small'>Criar lembrete</Text>
        </Box>
      ),
      onClick: () => toggleFormReminderLayer(row)
    });

    if(rules.includes(rulesName.deleteExpense)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer(row)
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'Despesas' : 'Despesa'}
                  </Text>
                </Box>

                <Box direction='row' align='center'>
                  {rules.includes(rulesName.printExpense) &&
                    <Button
                      title={`Download Excel (.csv) das ${formattedNumber(totalOfRows)} despesa(s) encontrada(s)`}
                      icon={<Download />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => {
                        generateCsv({
                          reference,
                          categoryId,
                          statusId,
                          startDateExpense: formattedDateTime(startDateExpense),
                          endDateExpense: formattedDateTime(endDateExpense),
                          recipient,
                          isRefund,
                          page: 1,
                          limit: totalOfRows,
                        })
                      }}
                    />
                  }

                  <Button
                    title='Filtrar'
                    icon={<Search />}
                    size='small'
                    onClick={() => { openFormSearchLayer() }}
                  />

                  {rules.includes(rulesName.createExpense) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Nova'
                      onClick={() => { toggleFormManagerLayer() }}
                      margin={{ left: 'small' }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && (
                  <Box width='20vw'>
                    <Text color='dark-2'>Ref.</Text>
                  </Box>
                )}

                {size !== 'small' && <Box width='30vw'>
                  <Text color='dark-2'>Data despesa</Text>
                </Box>}

                {size !== 'small' && <Box width='40vw'>
                  <Text color='dark-2'>Categoria</Text>
                </Box>}

                {size !== 'small' && <Box width='30vw'>
                  <Text color='dark-2'>Valor</Text>
                </Box>}

                {size !== 'small' && <Box width='30vw'>
                  <Text color='dark-2'>Reembolso</Text>
                </Box>}

                {size !== 'small' && size !== 'medium' && (
                  <Box width='30vw'>
                    <Text color='dark-2'>Status</Text>
                  </Box>
                )}

                <Box width='20vw' />
              </Box>

              {dataRows.map(row => {
                const menu = optionsMenu(row);

                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && <Box width='20vw'>
                      <Anchor
                        title='Abrir detalhes'
                        label={row.reference}
                        onClick={() => { toggleDetailsLayer(row) }}
                      />
                    </Box>}

                    {size !== 'small' && <Box width='30vw'>
                      <Text color='dark-2' size='small'>{formattedDateTime(row.dateExpense)}</Text>
                    </Box>}

                    <Box width='40vw'>
                      <Text color='dark-2' size='small'>{row.categoryName}</Text>
                    </Box>

                    <Box width='30vw' direction='row'>
                      <Text color='dark-2' size='small'>{formattedCurrency(row.value)}</Text>
                    </Box>

                    {size !== 'small' && <Box width='30vw' direction='row'>
                      <Text color='dark-2' size='small'>
                        {row.isRefund ? 'Sim' : 'Não' }
                      </Text>
                    </Box>}

                    {size !== 'small' && size !== 'medium' && (
                      <Box width='30vw' direction='row'>
                        <Text color='dark-2' size='small'>{row.statusName}</Text>
                      </Box>
                    )}

                    <Box align='end' width='20vw'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        {menu.length > 0 && (<Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={menu}
                        />)}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  reference,
                  categoryId,
                  statusId,
                  startDateExpense: formattedDateTime(startDateExpense),
                  endDateExpense: formattedDateTime(endDateExpense),
                  recipient,
                  isRefund,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, expenseReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  categoryId: formSearch.categoryId,
  statusId: formSearch.statusId,
  startDateExpense: formSearch.startDateExpense,
  endDateExpense: formSearch.endDateExpense,
  recipient: formSearch.recipient,
  isRefund: formSearch.isRefund,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormManagerLayer,
    toggleFormReminderLayer,
    copyLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
