const selectRulesOptions = [
  { id: 'create_voter', name: 'Cadastrar eleitor', category: 'Eleitor' },
  { id: 'edit_voter', name: 'Editar eleitor', category: 'Eleitor' },
  { id: 'print_voter', name: 'Imprimir dados dos eleitores', category: 'Eleitor' },
  { id: 'export_voter', name: 'Exportar eleitor para Excel', category: 'Eleitor' },
  { id: 'create_label', name: 'Gerar etiqueta', category: 'Eleitor' },
  { id: 'delete_voter', name: 'Excluir eleitor', category: 'Eleitor' },

  { id: 'create_treatment', name: 'Cadastrar atendimento', category: 'Atendimento' },
  { id: 'edit_treatment', name: 'Editar atendimento', category: 'Atendimento' },
  { id: 'delete_treatment', name: 'Excluir atendimento', category: 'Atendimento' },
  { id: 'print_search_treatment', name: 'Imprimir dados dos atendimentos', category: 'Atendimento' },
  { id: 'create_commet_treatment', name: 'Cadastrar andamento para os atendimentos', category: 'Atendimento' },
  { id: 'export_treatment', name: 'Exportar atendimento para Excel', category: 'Atendimento' },

  { id: 'create_appointment', name: 'Cadastrar compromisso', category: 'Agenda' },
  { id: 'edit_appointment', name: 'Editar compromisso', category: 'Agenda' },
  { id: 'export_appointment', name: 'Exportar compromissos para Excel', category: 'Agenda' },
  { id: 'delete_appointment', name: 'Excluir compromisso', category: 'Agenda' },
  { id: 'print_appointment', name: 'Imprimir dados dos compromissos', category: 'Agenda' },

  { id: 'cancel_campaign', name: 'Cancelar envio de SMS/E-mail para o eleitor', category: 'Campanhas' },
  { id: 'create_campaign', name: 'Criar campanha', category: 'Campanhas' },

  { id: 'create_call', name: 'Cadastrar ligação', category: 'Telefonemas' },
  { id: 'edit_call', name: 'Editar ligação', category: 'Telefonemas' },
  { id: 'delete_call', name: 'Excluir ligação', category: 'Telefonemas' },
  { id: 'print_call', name: 'Imprimir e exportar ligações', category: 'Telefonemas' },

  { id: 'create_legislative_process', name: 'Cadastrar documento', category: 'Documentos' },
  { id: 'edit_legislative_process', name: 'Alterar documento', category: 'Documentos' },
  { id: 'delete_legislative_process', name: 'Excluir documento', category: 'Documentos' },
  { id: 'print_legislative_process', name: 'imprimir e exportar documento', category: 'Documentos' },
  { id: 'comment_legislative_process', name: 'Comentar o andamento da documento', category: 'Documentos' },

  { id: 'create_expense', name: 'Cadastrar despesas', category: 'Despesa' },
  { id: 'edit_expense', name: 'Alterar despesas', category: 'Despesa' },
  { id: 'delete_expense', name: 'Excluir despesas', category: 'Despesa' },
  { id: 'print_expense', name: 'Exportar despesas', category: 'Despesa' },

  { id: 'create_survey', name: 'Cadastrar enquetes', category: 'Enquete' },
  { id: 'edit_survey', name: 'Alterar enquetes', category: 'Enquete' },
  { id: 'delete_survey', name: 'Excluir enquetes e respostas', category: 'Enquete' },
]

export default selectRulesOptions;
