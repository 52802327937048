import TYPES from '../actions/types';

const INITIAL_STATE = {
  officeOptions: [],
  statusServiceOptions: [],
  originServiceOptions: [],
  categoryServiceOptions: [],
  usersOptions: [],
  groupsOptions: [],
  votersOptions: [],
  documentTypeOptions: [],
  authorsOptions: [],
  statusLegislativeProcessOptions: [],
  interestLegislativeProcessOptions: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.getOfficeOptions:
      return { ...state, officeOptions: action.payload };

    case TYPES.getStatusServiceOptions:
      return { ...state, statusServiceOptions: action.payload };

    case TYPES.getOriginServiceOptions:
      return { ...state, originServiceOptions: action.payload };

    case TYPES.getCategoryServiceOptions:
      return { ...state, categoryServiceOptions: action.payload };

    case TYPES.getUsersOptions:
      return { ...state, usersOptions: action.payload };

    case TYPES.getGroupsOptions:
      return { ...state, groupsOptions: action.payload };

    case TYPES.getVotersOptions:
      return { ...state, votersOptions: action.payload }

    case TYPES.getDocumentTypeOptions:
      return { ...state, documentTypeOptions: action.payload };

    case TYPES.getAuthorsOptions:
      return { ...state, authorsOptions: action.payload };

    case TYPES.getStatusLegislativeProcessOptions:
      return { ...state, statusLegislativeProcessOptions: action.payload };

    case TYPES.getAreaInterestLegislativeProcessOptions:
      return { ...state, interestLegislativeProcessOptions: action.payload };

    default:
      return state;
  }
}
