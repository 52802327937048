import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { list } from '../../../api/common';

import { formatDataFormSearch } from '../utils';

const loadingSearchToggle = () => ({
  type: TYPES.loadingFormSearch,
});

export const search = params => {
  return dispatch => {
    dispatch(loadingSearchToggle());
    list(params, 'task.php')
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(loadingSearchToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const setDefaultPageRules = () => ({
  type: TYPES.setDefaultPage,
});

export const initializeValueFormSearch = () => ({
  type: TYPES.initializeValueFormSearch, payload: formatDataFormSearch(),
});

export const openFormSearchLayer = () => ({
  type: COMMON_TYPES.toggleLayer, payload: 'search'
});

export const hideLayer = () => ({
  type: COMMON_TYPES.toggleLayer, payload: null
});

export const goToNextPage = params => {
  const pageNumber = params.page + 1;

  return dispatch => {
    dispatch(search({ ...params, page: pageNumber }));
    dispatch({
      type: TYPES.paginationFormSearch,
      payload: pageNumber
    })
  }
}

export const goToPrevPage = params => {
  const pageNumber = params.page - 1;

  return dispatch => {
    dispatch(search({ ...params, page: pageNumber }));
    dispatch({
      type: TYPES.paginationFormSearch,
      payload: pageNumber
    })
  }
}
