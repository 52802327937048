import TYPES from './types';
import { listVoterPrint } from '../../../api/voter';
import { formattedDateTime } from '../../../utils';
import { saveAs } from 'file-saver';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const generatePrintList = params =>
  dispatch => {
    dispatch(notification(`Gerando arquivo. Aguarde...`));

    listVoterPrint(params)
      .then(response => {
        saveAs(
          response.data,
          `listagem-eleitores-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.pdf`
        );
      })
      .catch(() =>
        dispatch(notification(`Ops... não conseguimos gerar o arquivo.`))
      );
  }
