import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Anchor } from 'grommet';
import { UserNew, Transaction, Edit } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches } from '../../../../components';
import { showFormManagerLayer } from '../../actions/form-manager';
import { toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { toggleFormChangePassLayer } from '../../actions/form-change-pass';

import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const {
    history,

    loadingFormSearch,
    dataRows,
    getListError,

    showFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormChangePassLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  if(!dataRows.length) {
    return <EmptySearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width={ size === 'small' || size === 'medium' ? '100vw' : '70vw' }
              gap={ size === 'small' ? 'medium' : 'xsmall'}
            >
              <Box
                direction='row'
                align='center'
                justify='between'
                margin={{ bottom: 'medium' }}
              >
                <Text size='large'>Usuários</Text>

                <Anchor
                  size='medium'
                  onClick={ () => history.push(urlRoutes.settingsUrl) }
                  label='voltar para configurações'
                />
              </Box>

              <Box
                round='small'
                border={{ color: 'light-4' }}
                background='white'
                elevation='xsmall'
              >
                <Box
                  align='center'
                  pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'large' : 'medium' }}
                  border={{ color: 'light-4', style: 'dashed', side: 'bottom' }}
                  round={{ size: 'small', corner: 'top' }}
                  background='light-1'
                  direction='row'
                  justify='between'
                >
                  <Text size='medium' color='dark-6' weight={300}>
                    {dataRows.length} encontrados
                  </Text>

                  <Button
                    primary
                    size='small'
                    label='+ Adicionar'
                    color='dark-2'
                    onClick={() => { showFormManagerLayer() }}
                  />
                </Box>

                {dataRows.map((row, i) => {
                  const isLastLine = dataRows.length === i + 1;

                  return (
                    <Box
                      key={row.id}
                      align='center'
                      direction='row'
                      pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'medium' : 'small' }}
                      border={ !isLastLine ? { color: 'light-3', side: 'bottom' } : null }
                      justify='between'
                    >
                      <Box
                        direction='row'
                        gap={size === 'small' ? 'medium' : 'xsmall'}
                      >
                        {size !== 'small' && <Box width='xxsmall' justify='center'>
                          <Box
                            title={ row.status ? 'Ativo' : 'Inativo' }
                            width='1em'
                            height='1em'
                            round='medium'
                            elevation='small'
                            background={{
                              color: row.status ? 'status-ok' : 'status-error'
                            }}
                          />
                        </Box>}

                        <Box>
                          <Text color='dark-2' size='medium' truncate>{row.name}</Text>
                          <Text color='dark-4' size='small' truncate>{row.email}</Text>
                          <Text color='dark-2' size='small' truncate>{row.profile}</Text>
                        </Box>
                      </Box>

                      <Box
                        direction='row'
                        align='center'
                        justify='end'
                        gap='xsmall'
                        //width='20vw'
                      >
                        <Button
                          icon={<Edit color='dark-3' size='medium' />}
                          onClick={() => { showFormManagerLayer(row) }}
                        />

                        <Button
                          title='Trocar senha'
                          icon={<UserNew color='dark-3' size='medium' />}
                          onClick={() => { toggleFormChangePassLayer(row) }}
                        />

                        <Button
                          title={row.status ? 'Inativar' : 'Reativar'}
                          icon={<Transaction color='dark-3' size='medium' />}
                          onClick={() => { toggleFormChangeStatusLayer(row) }}
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsUserReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    showFormManagerLayer,
    toggleFormChangeStatusLayer,
    toggleFormChangePassLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
