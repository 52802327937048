import TYPES from '../actions/types';
import { formatDataFormEdit } from '../utils';

const INITIAL_STATE = {
  id: null,
  name: null,
  email: null,
  password: null,
  jobRoleId: null,
  officeId: null,
  profileId: null,
  telephone: null,
  rules: [],

  isEditing: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormManagerField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

      case TYPES.setAllRulesAdmin:
        return {
          ...state,
          rules: action.payload.map(a => a.id.toString())
        }

    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormManager:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.loadingPostFormManager:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formManagerPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
