import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const TitleDetail = ({ value }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            bottom: 'medium',
            horizontal: 'large'
          }}
          flex={{ shrink: 0 }}
        >
          <Text
            size={ size === 'small' ? 'large' : 'xlarge' }
            textAlign={ size === 'small' ? 'start' : 'center' }
          >
            {value}
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default TitleDetail;
