import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Text, Button, Heading, ResponsiveContext } from 'grommet';

import Header from '../components/header/header';
import { Placeholder, HeaderHtml } from '../../../components';

import { formattedDateTime, formattedCurrency } from '../../../utils';

import urlRoutes from '../../../config/url-routes';

import { getUseLicense } from '../actions/open-invoice';

class InvoiceOpen extends Component {
  constructor(props) {
    super(props);

    const { identity } = this.props.match.params;
    const { getUseLicense } = this.props;

    getUseLicense(identity);
  }

  componentDidUpdate(prevProps) {
    const { clientId } = this.props;

    if(clientId && prevProps.clientId !== clientId) {
      this.existOpenInvoice();
    }
  };

  existOpenInvoice = () => {
    const { identifier, invoice, history } = this.props;

    if(!invoice) {
      history.push(urlRoutes.InvoiceUseLicense.replace(/:identity/gi, identifier));
    }
  };

  printBankSlip = () => {
    const { invoice: { bankSlipUrl } } = this.props;
    window.open(bankSlipUrl, '_blank');
  };

  render() {
    const {
      clientId,
      clientStatus,

      billingName,
      billingDocument,

      invoice,
    } = this.props;

    if(!clientId) {
      return (
        <Placeholder
          title='Carregando...'
          message='Aguarde que estamos preparando as coisas :)'
        />
      );
    }

    const licenseStart = invoice ? formattedDateTime(invoice.periodStart) : '';
    const licenseFinish = invoice ? formattedDateTime(invoice.periodEnd) : '';

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <HeaderHtml title={ `Fatura em aberto do período ${licenseStart} até ${licenseFinish}` } />
            <Header />

            <Box direction='row' justify='center'>
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                width='xlarge'
              >
                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'small' }
                  margin={{ right: size === 'small' ? 'none' : 'medium' }}
                >
                  <Heading level='3' size='small'>
                    Dados do pagador
                  </Heading>

                  <Box
                    border={{ color: 'light-3' }}
                    round='xsmall'
                    margin={{ top: 'small', bottom: 'small' }}
                    pad='medium'
                    background='white'
                    flex={{ shrink: 0 }}
                  >
                    <Text size='medium' color='dark-2'>{billingName} - {billingDocument}</Text>
                  </Box>

                  <Heading level='3' size='small'>
                    Dados da cobrança
                  </Heading>

                  <Box
                    border={{ color: 'light-3' }}
                    round='xsmall'
                    margin={{ top: 'xsmall' }}
                    pad='medium'
                    background='white'
                    flex={{ shrink: 0 }}
                  >
                    <Text size='medium' color='dark-2'>Período de uso da cobrança</Text>
                    <Text size='small' color='dark-4'>{licenseStart} até {licenseFinish}</Text>
                  </Box>
                </Box>

                <Box
                  width='medium'
                  fill='horizontal'
                  pad={ size === 'small' ? 'large' : 'small' }
                  margin={{ left: size === 'small' ? 'none' : 'medium' }}
                >
                  <Heading level='3' size='small'>
                    Dados da fatura
                  </Heading>

                  <Box
                    border={{ color: 'status-warning', side: 'left', size: 'medium' }}
                    round='xsmall'
                    margin={{ top: 'small', bottom: 'small' }}
                    pad='medium'
                    background='accent-4'
                  >
                    <Text size='small' color='dark-1'>
                      O prazo para compensação do boleto é de até 1 dia util. {!clientStatus ? 'O sistema é liberado automaticamente após a compensação.' : null}
                    </Text>
                  </Box>

                  <Box
                    border={{ color: 'light-3', side: 'bottom' }}
                    margin={{ top: 'xsmall' }}
                    pad={{ bottom: 'small' }}
                    direction='row'
                    justify='between'
                    align='center'
                  >
                    <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>Produto</Text>
                    <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>Licença de uso</Text>
                  </Box>

                  {invoice && (
                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'xsmall' }}
                      pad={{ bottom: 'small', top: 'xsmall' }}
                      direction='row'
                      justify='between'
                      align='center'
                    >
                      <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>Vencimento fatura</Text>
                      <Text size='small' color='dark-4' margin={{ top: 'xsmall', bottom: 'none' }}>{formattedDateTime(invoice.dueDate)}</Text>
                    </Box>
                  )}

                  {invoice && (
                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'xsmall' }}
                      pad={{ bottom: 'small', top: 'xsmall' }}
                      direction='row'
                      justify='between'
                      align='center'
                    >
                      <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>Total a pagar</Text>
                      <Text size='medium' color='dark-2' margin={{ top: 'xsmall', bottom: 'none' }}>{formattedCurrency(invoice.value)}</Text>
                    </Box>
                  )}

                  <Box
                    margin={{ top: 'medium' }}
                    pad={{ bottom: 'small', top: 'xsmall' }}
                  >
                    <Button
                      primary
                      label='Abrir Boleto'
                      onClick={this.printBankSlip}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ invoiceReducer: { openInvoice } }) => ({
  identifier: openInvoice.identifier,
  clientId: openInvoice.clientId,
  clientStatus: openInvoice.clientStatus,

  billingName: openInvoice.inputBillingName,
  billingDocument: openInvoice.inputBillingDocument,

  invoice: openInvoice.openInvoice,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUseLicense,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceOpen)
);

