import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';
import notificationReducer from './notification';

const surveyReducer = combineReducers({
  remove: removeReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
  notification: notificationReducer,
});

export default surveyReducer;
