const TYPES = ({
  // list
  loadingFormSearch: 'LOADING_SEARCH_SURVEY_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SURVEY_SUCCESS',
  searchError: 'SEARCH_SURVEY_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_SURVEY_FORM_LOADING',
  loadDataEdit: 'MANAGER_SURVEY_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_SURVEY_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_SURVEY_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_SURVEY_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_SURVEY_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_SURVEY_SUCCESS_UPDATE_LISTING',
  changeFormManagerField: 'CHANGE_MANAGER_SURVEY_FORM_FIELD',
  addOptFormManager: 'ADD_OPT_SURVEY_FORM_MANAGER',
  removeOptFormManager: 'REMOVE_OPT_SURVEY_FORM_MANAGER',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_SURVEY_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_SURVEY_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_SURVEY_FORM',
  paginationFormSearch: 'SEARCH_SURVEY_PAGINATION',

  // delete
  setDataFormDelete: 'SET_STATUS_SURVEY_FORM_ID',
  loadingFormDelete: 'LOADING_POST_FORM_DELETE_SURVEY',
  formPostDeleteError: 'POST_FORM_DELETE_SURVEY_ERROR',

  // notification
  showNotification: 'SURVEY_SHOW_NOTIFICATION',
  hideNotification: 'SURVEY_HIDE_NOTIFICATION',
});

export default TYPES;
