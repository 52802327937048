import TYPES from '../actions/types';

const INITIAL_STATE = {
  formManagerLayerIsVisible: false,
  formDeleteLayerIsVisible: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.formManagerLayerToggle:
      return { ...state, formManagerLayerIsVisible: !state.formManagerLayerIsVisible }

    case TYPES.formDeleteLayerToggle:
      return { ...state, formDeleteLayerIsVisible: !state.formDeleteLayerIsVisible }

    default:
      return state;
  }
}
