import { prepend } from 'ramda';

export default (object, itens) => {
  const newObject = {
    ...object,
    serviceUsing: 0,
  };

  return prepend(newObject, itens);
};
