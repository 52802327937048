import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Text, Box, MaskedInput, Select, TextInput, Anchor } from 'grommet';
import { Clock, Phone } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchAppointments } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import selectHourOptions from '../../constants/select-hour-options';
import selectYesNoOptions from '../../../../constants/select-yesno-options';

import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchAppointments,
      hideFormManagerLayer,
    } = this.props;

    searchAppointments();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { isEditing, create, update } = this.props;

    if(isEditing && formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      usersOptions,

      id,
      subject,
      date,
      description,
      hourStart,
      hourEnd,
      participant,
      requesterName,
      requesterPhone,
      location,
      notify,

      getDataFormEditError,
      isEditing,
      isCopy,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {(isEditing || isCopy) && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || (isCopy && id) || (!isEditing && !isCopy)) ? (
                  <>
                    <TitleDetail value={isEditing && id ? 'Editar compromisso' : 'Novo compromisso'} />

                    <Box flex overflow='auto'>
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Dia e horário' required />

                        <Box direction='row' gap='small'>
                          <Box width='40vw'>
                            <CalendarDropButton
                              date={date ?? ''}
                              onSelect={(selectedDate) => changeValues({ fieldName: 'date', value: selectedDate }, 'changeFormManagerFields')}
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                          </Box>

                          <Box width='40vw'>
                            <Select
                              options={selectHourOptions}
                              value={hourStart ?? ''}
                              placeholder='Inicia as...'
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              icon={<Clock />}
                              onChange={({ value: nextValue }) => changeValues({ fieldName: 'hourStart', value: nextValue }, 'changeFormManagerFields')}
                              emptySearchMessage='Selecione uma opção'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='hourStart' />
                          </Box>

                          <Box width='40vw'>
                            <Select
                              options={selectHourOptions}
                              value={hourEnd ?? ''}
                              placeholder='Termina as...'
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              icon={<Clock />}
                              onChange={({ value: nextValue }) => changeValues({ fieldName: 'hourEnd', value: nextValue }, 'changeFormManagerFields')}
                              emptySearchMessage='Selecione uma opção'
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='hourEnd' />
                          </Box>
                        </Box>
                      </Box>


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Assunto' required />

                        <TextInput
                          maxLength={60}
                          value={subject ?? ''}
                          onChange={event => changeValues({ fieldName: 'subject', value: event.target.value }, 'changeFormManagerFields')}
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='subject' />
                      </Box>


                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Local' />

                        <TextInput
                          maxLength={100}
                          value={location ?? ''}
                          onChange={event => changeValues({ fieldName: 'location', value: event.target.value }, 'changeFormManagerFields')}
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='location' />
                      </Box>

                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Participantes' />

                        <Select
                          options={
                            usersOptions.filter(
                              a => a.status || participant.some(userId => userId === a.id)
                            )
                          }
                          value={participant}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          multiple
                          closeOnChange={false}
                          onChange={({ value: nextValue }) => changeValues({ fieldName: 'participant', value: nextValue }, 'changeFormManagerFields')}
                          emptySearchMessage='Selecione em quais agendas o compromisso irá aparecer'
                          valueLabel={
                            participant && participant.length > 1 ? (
                              <Box pad='small'>
                                <Text>{`${participant.length} selecionado(s)`}</Text>
                              </Box>
                            ) : null
                          }
                        />
                      </Box>

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                        gap='small'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '70vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Notificar os participantes?'
                          />

                          <DescriptionField
                            text='O SMS será disparado até 10 minutos antes do horário de início.'
                          />

                          <DescriptionField
                            text='Garanta que tenha saldo de SMS disponível no momento da notificação. Para consultar acesse o menu "Mais" > "Área do cliente".'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '40vw'}
                        >
                          <Select
                            options={selectYesNoOptions}
                            value={notify}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            onChange={({ value: nextValue }) => changeValues({ fieldName: 'notify', value: nextValue }, 'changeFormManagerFields')}
                          />
                        </Box>
                      </Box>

                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Compromisso será com' />

                        <Box direction='row' gap='small'>
                          <Box width='60vw'>
                            <TextInput
                              maxLength={50}
                              value={requesterName ?? ''}
                              placeholder='Nome...'
                              onChange={event => changeValues({ fieldName: 'requesterName', value: event.target.value }, 'changeFormManagerFields')}
                            />
                          </Box>

                          <Box width='40vw'>
                            <MaskedInput
                              value={requesterPhone ?? ''}
                              placeholder='Telefone...'
                              icon={<Phone />}
                              reverse={true}
                              onChange={event => changeValues({ fieldName: 'requesterPhone', value: event.target.value }, 'changeFormManagerFields')}
                              mask={[
                                { fixed: '(' },
                                { length: 2, regexp: /\d/ },
                                { fixed: ')' },
                                { fixed: ' ' },
                                { length: 4, regexp: /\d/ },
                                { fixed: '-' },
                                { length: [4, 5], regexp: /\d/ }
                              ]}
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />

                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Descrição' />

                        <TextArea
                          resize='vertical'
                          maxLength={800}
                          value={description ?? ''}
                          onChange={event => changeValues({ fieldName: 'description', value: event.target.value }, 'changeFormManagerFields')}
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                      </Box>
                    </Box>


                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              subject,
                              date: formattedDateTime(date),
                              description,
                              hourStart,
                              hourEnd,
                              participant,
                              requesterName,
                              requesterPhone,
                              location,
                              notify,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ appointmentsReducer: { formManager }, layerReducer, selectOptionsReducer }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  id: formManager.id,
  subject: formManager.subject,
  date: formManager.date,
  description: formManager.description,
  hourStart: formManager.hourStart,
  hourEnd: formManager.hourEnd,
  participant: formManager.participant,
  requesterName: formManager.requesterName,
  requesterPhone: formManager.requesterPhone,
  location: formManager.location,
  notify: formManager.notify,

  getDataFormEditError: formManager.getDataFormEditError,
  isEditing: formManager.isEditing,
  isCopy: formManager.isCopy,
  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    searchAppointments,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
