import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';
import formChangeStatusReducer from './form-change-status';
import notificationReducer from './notification';
import detailsPublicReducer from './details-public';

const appointmentsReducer = combineReducers({
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
  formChangeStatus: formChangeStatusReducer,
  notification: notificationReducer,
  detailsPublic: detailsPublicReducer,
});

export default appointmentsReducer;
