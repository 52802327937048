import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        statusId: always(object.statusId),
        statusName: always(object.statusName),
      }),
    ),
    items
  )
)
