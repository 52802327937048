import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const getCommunicationBalance = () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: '/transaction.communication.php',
    headers: {
      token: decipher.token.value,
      action: 'getSummary',
    }
  });
}

export default getCommunicationBalance;
