import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default (id, path) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    url: path,
    data: { id },
    headers: {
      token: decipher.token.value,
      action: 'delete',
    }
  });
}
