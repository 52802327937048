const TYPES = ({

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_CATEGORY_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_CATEGORY_SUCCESS',
  searchError: 'SEARCH_SETTINGS_CATEGORY_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_SETTINGS_CATEGORY_FORM_LOADING',
  loadDataEdit: 'MANAGER_SETTINGS_CATEGORY_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_SETTINGS_CATEGORY_EDIT_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_SETTINGS_CATEGORY_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_SETTINGS_CATEGORY_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_SETTINGS_CATEGORY_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_UPDATE_LISTING',
  insertItemListingManagerSuccess: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_INSERT_LISTING',
  deleteItemListingManagerSuccess: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_DELETE_LISTING',
  changeFormManagerField: 'CHANGE_MANAGER_SETTINGS_CATEGORY_FORM_FIELD',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_CATEGORY_DEFAULT_PAGE',

  // delete
  setDataFormDelete: 'SET_STATUS_SETTINGS_CATEGORY_FORM_ID',
  loadingFormDelete: 'LOADING_POST_FORM_DELETE_SETTINGS_CATEGORY',
  formPostDeleteError: 'POST_FORM_DELETE_SETTINGS_CATEGORY_ERROR',

  // notification
  showNotification: 'SETTINGS_CATEGORY_SHOW_NOTIFICATION',
  hideNotification: 'SETTINGS_CATEGORY_HIDE_NOTIFICATION',
});

export default TYPES;
