import { combineReducers } from 'redux';
import formArchiveReducer from './form-archive';
import formChangeStatusReducer from './form-change-status';
import formCommentReducer from './form-comment';
import formReminderReducer from './form-reminder';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';
import notificationReducer from './notification';
import formPrepareDocReducer from './form-prepare-doc';
import templatesReducer from './form-prepare-doc-template';

const legislativeProcessesReducer = combineReducers({
  formArchive: formArchiveReducer,
  formChangeStatus: formChangeStatusReducer,
  formComment: formCommentReducer,
  formReminder: formReminderReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
  notification: notificationReducer,
  formPrepareDoc: formPrepareDocReducer,
  templates: templatesReducer,
});

export default legislativeProcessesReducer;
