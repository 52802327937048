import TYPES from './types';
import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const generateCsv = params =>
  dispatch => {
    dispatch(notification(`Gerando Excel. Aguarde...`));

    list(params, 'manifestation.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            protocolNumber: { name: 'Protocolo', transform: (value) => value ?? '' },
            name: { name: 'Nome', transform: (value) => value ?? '' },
            email: { name: 'E-mail', transform: (value) => value ?? '' },
            telephone: { name: 'Telefone', transform: (value) => value ?? '' },
            typeName: { name: 'Tipo', transform: (value) => value ?? '' },
            statusName: { name: 'Status', transform: (value) => value ?? '' },
            //description: { name: 'Descrição', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
          },
          fileName: `listagem-manifestacoes-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(notification(`Ops... não conseguimos gerar o Excel.`))
      );
  }
