import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default id => {
  const formattedParams = {
    id,
  };

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: '/survey.php',
    params: formattedParams,
    headers: {
      token: decipher.token.value,
      action: 'getAnalytics',
    }
  });
}
