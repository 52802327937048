export default object => ({
  id: object.id,
  name: object.name,
  email: object.email,
  officeId: object.officeId,
  jobRoleId: object.jobRoleId.toString(),
  profileId: object.profileId.toString(),
  telephone: object.telephone,
  rules: object.rules,
});
