import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Text, Layer, TextArea, Anchor, Box, Select } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { changeValues } from '../../../../actions/form';
import { create, toggleFormReminderLayer } from '../../actions/form-reminder';

import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormReminder extends Component {
  callbackPostSuccess = () => {
    const {

      toggleFormReminderLayer,
    } = this.props;

    toggleFormReminderLayer();
  };

  handlePost = formParams => {
    const { create } = this.props;

    create({
      formParams,
      callbackSuccess: this.callbackPostSuccess
    });
  };

  render() {
    const {
      usersOptions,

      id,
      sourceId,
      source,
      status,
      description,
      taskDate,
      participants,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormReminderLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'reminder') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormReminderLayer}
              onEsc={toggleFormReminderLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Criar lembrete' />

                <Box flex overflow='auto'>

                  {/* Participantes */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Participantes' required />

                    <Select
                      options={
                        usersOptions.filter(
                          a => a.status || participants?.some(userId => userId === a.id)
                        )
                      }
                      value={participants}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      multiple
                      closeOnChange={false}
                      onChange={({ value: nextValue }) => changeValues({ fieldName: 'participants', value: nextValue }, 'formChangeValues')}
                      emptySearchMessage='Selecione os responsáveis por esse lembrete'
                      valueLabel={
                        participants && participants.length > 1 ? (
                          <Box pad='small'>
                            <Text>{`${participants.length} selecionado(s)`}</Text>
                          </Box>
                        ) : null
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='participants' />
                  </Box>

                  {/* Data lembrete */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data do lembrete' required />

                    <CalendarDropButton
                      date={taskDate ?? ''}
                      onSelect={(selectedDate) => changeValues({ fieldName: 'taskDate', value: selectedDate }, 'formChangeValues')}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='taskDate' />
                  </Box>

                  {/* descricao */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Descrição' required />

                    <TextArea
                      rows={4}
                      resize='vertical'
                      maxLength={200}
                      value={description ?? ''}
                      onChange={event =>
                        changeValues({ fieldName: 'description', value: event.target.value }, 'formChangeValues')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                  </Box>
                </Box>


                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormReminderLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          sourceId,
                          source,
                          status,
                          description,
                          taskDate: formattedDateTime(taskDate),
                          participants,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerReducer, selectOptionsReducer, formCreateUpdateCopyGeneric }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  id: formCreateUpdateCopyGeneric.id,
  sourceId: formCreateUpdateCopyGeneric.sourceId,
  source: formCreateUpdateCopyGeneric.source,
  status: formCreateUpdateCopyGeneric.status,
  description: formCreateUpdateCopyGeneric.description,
  taskDate: formCreateUpdateCopyGeneric.taskDate,
  participants: formCreateUpdateCopyGeneric.participants,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    create,
    changeValues,
    toggleFormReminderLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormReminder);
