import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default (params) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'campaign.php',
    headers: {
      token: decipher.token.value,
      action: 'searchVoters'
    },
    params,
  })
}
