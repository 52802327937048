import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        dateExpense: always(object.dateExpense),
        categoryName: always(object.categoryName),
        value: always(object.value),
        isRefund: always(object.isRefund),
        statusName: always(object.statusName),
      }),
    ),
    items
  )
)
