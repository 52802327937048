import { prepend } from 'ramda';

export default (object, itens) => {
  const newObject = {
    status: object.status,
    name: object.name,
    email: object.email,
    profile: object.profile,
  };

  return prepend(newObject, itens);
};
