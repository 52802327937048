import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormCreate from '../components/form-manager/form-manager';
import FormReminder from '../components/form-reminder/form-reminder';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import Header from '../../../components/header/header';

import { searchExpenses, setDefaultPageRules } from '../actions/form-search';
import { getUsersOptions } from '../../../actions/select-options';

class Expenses extends Component {
  componentDidMount() {
    const {
      getUsersOptions,

      searchExpenses,
      setDefaultPageRules,
    } = this.props;

    getUsersOptions();

    setDefaultPageRules();
    searchExpenses();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormCreate />
        <FormReminder />
        <Details />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,

    searchExpenses,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
