import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField, CalendarDropButton } from '../../../../components/form';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeValues } from '../../../../actions/form';
import { createComment, toggleFormCommentLayer } from '../../actions/form-comment';

import { formattedDateTime } from '../../../../utils';

class FormComment extends Component {
  handlePost = formParams => {
    const {
      createComment,
    } = this.props;

    createComment({ formParams });
  };

  render() {
    const {
      id,
      date,
      note,
      type,
      number,
      year,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormCommentLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'comment') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCommentLayer}
              onEsc={toggleFormCommentLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Andamento/Resposta orgão responsável' />

                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Tipo'
                    value={type}
                  />

                  <SimpleDetail
                    title='Número'
                    value={number}
                  />

                  <SimpleDetail
                    title='Ano'
                    value={year}
                  />

                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data' required />

                    <CalendarDropButton
                      date={date ?? ''}
                      onSelect={(selectedDate) =>
                        changeValues({ fieldName: 'date', value: selectedDate }, 'changeFormCommentField')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Descrição' required />

                    <TextArea
                      rows={3}
                      resize='vertical'
                      value={note ?? ''}
                      placeholder='Exemplo: Entramos em contato com a prefeitura e a mesma esta analisando.'
                      onChange={event =>
                        changeValues({ fieldName: 'note', value: event.target.value }, 'changeFormCommentField')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='note' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormCommentLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          date: formattedDateTime(date),
                          note,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ legislativeProcessesReducer: { formComment }, layerReducer }) => ({
  id: formComment.id,
  date: formComment.date,
  note: formComment.note,
  type: formComment.type,
  number: formComment.number,
  year: formComment.year,

  loadingPostForm: formComment.loadingPostForm,
  postDataError: formComment.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    createComment,
    toggleFormCommentLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormComment);
