import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ResponsiveContext, Box, Text, Button, Anchor, Menu } from 'grommet';
import { Notes, Edit, Print, More, Download, Projects, Search, Trash, Menu as MenuIcon, Clipboard, Alarm, Send } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination } from '../../../../components';

import { toggleDetailsLayer } from '../../actions/details';
import { generatePrint } from '../../actions/print';
import { generatePrintList } from '../../actions/print-list';
import { generateCsv } from '../../actions/export-csv';
import { openGenerateTagLayer } from '../../actions/form-generate-tag';
import { toggleFormCreateServiceLayer } from '../../actions/form-create-service';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { toggleFormReminderLayer } from '../../actions/form-reminder';
import { toggleFormShortMessageLayer } from '../../actions/form-short-message';

import { formattedNumber, formattedDateTime } from '../../../../utils';
import rulesName from '../../../../constants/rules';
import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const {
    userLogged: { rules },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    // search
    reference,
    individualRegistration,
    name,
    genre,
    cityId,
    districtsId,
    address,
    zipcode,
    status,
    groupsId,
    maritalStatusId,
    professionId,
    withTelephone,
    withEmail,
    telephone,
    email,
    startBirthDate,
    endBirthDate,
    startCreatedDate,
    endCreatedDate,
    voteStatusId,
    responsibleId,
    responsibleName,
    schoolId,
    page,
    limit,

    toggleDetailsLayer,
    generatePrint,
    generatePrintList,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormReminderLayer,
    toggleFormShortMessageLayer,
    openGenerateTagLayer,
    openFormSearchLayer,
    toggleFormCreateServiceLayer,
    goToNextPage,
    goToPrevPage,

    history,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createVoter) ?
          { label: 'Novo eleitor', action: () => history.push(urlRoutes.voterCreateUrl) } : null
      }
    />;
  }

  const getFilters = () => {
    return {
      reference,
      individualRegistration,
      name,
      genre,
      cityId,
      districtsId,
      address,
      zipcode,
      status,
      groupsId,
      maritalStatusId,
      professionId,
      withTelephone,
      withEmail,
      telephone,
      email,
      startBirthDate,
      endBirthDate,
      startCreatedDate: formattedDateTime(startCreatedDate),
      endCreatedDate: formattedDateTime(endCreatedDate),
      voteStatusId,
      responsibleId,
      responsibleName,
      schoolId,
      page: 1,
      limit: totalOfRows,
    }
  }

  const optionsMenuList = () => {
    const options = [];

    if(rules.includes(rulesName.printVoter)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'medium', top: 'small' }}>
            <Text size='small'>Imprimir</Text>
          </Box>
        ),
        onClick: () => generatePrintList(getFilters()),
      });
    }

    if(rules.includes(rulesName.exportVoter)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'medium' }}>
            <Text size='small'>Download Excel (.csv)</Text>
          </Box>
        ),
        onClick: () => generateCsv(getFilters()),
      });
    }

    if(rules.includes(rulesName.createLabel)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'medium', bottom: 'small' }}>
            <Text size='small'>Gerar Etiqueta</Text>
          </Box>
        ),
        onClick: () => openGenerateTagLayer(getFilters()),
      });
    }

    return options;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editVoter)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => history.push(urlRoutes.voterEditUrl.replace(/:id/gi, row.id))
      });
    }

    if(rules.includes(rulesName.printVoter)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Print />
            <Text size='small'>Imprimir</Text>
          </Box>
        ),
        onClick: () => generatePrint(row)
      });
    }

    if(rules.includes(rulesName.createTreatment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Clipboard />
            <Text size='small'>Novo atendimento</Text>
          </Box>
        ),
        onClick: () => toggleFormCreateServiceLayer(row)
      });
    }

    options.push({
      label: (
        <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
          <Alarm />
          <Text size='small'>Criar lembrete</Text>
        </Box>
      ),
      onClick: () => toggleFormReminderLayer(row)
    });

    options.push({
      label: (
        <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
          <Send />
          <Text size='small'>Enviar SMS</Text>
        </Box>
      ),
      onClick: () => toggleFormShortMessageLayer(row)
    });

    if(rules.includes(rulesName.deleteVoter)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Trash color='status-error' />
            <Text size='small'>Excluir</Text>
          </Box>
        ),
        onClick: () => toggleFormDeleteLayer(row)
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'eleitores' : 'eleitor'}
                  </Text>
                </Box>

                <Box
                  direction='row'
                  align='center'
                  gap={size === 'small' ? 'large' : 'medium'}
                >
                  {size === 'small' && (
                    <Menu
                      dropProps={{ align: { right: 'right' } }}
                      icon={<MenuIcon />}
                      items={optionsMenuList()}
                    />
                  )}

                  {size !== 'small' && rules.includes(rulesName.exportVoter) &&
                    <Button
                      plain
                      title={`Download Excel (.csv) dos ${formattedNumber(totalOfRows)} eleitor(es) encontrado(s)`}
                      icon={<Download />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => generateCsv(getFilters()) }
                    />
                  }

                  {size !== 'small' && rules.includes(rulesName.createLabel) &&
                    <Button
                      plain
                      title={`Gerar Etiqueta dos ${formattedNumber(totalOfRows)} eleitor(es) encontrado(s)`}
                      icon={<Projects />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => openGenerateTagLayer(getFilters()) }
                    />
                  }

                  {size !== 'small' && rules.includes(rulesName.printVoter) &&
                    <Button
                      plain
                      title={`Imprimir os ${formattedNumber(totalOfRows)} eleitor(es) encontrado(s)`}
                      icon={<Print />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => generatePrintList(getFilters()) }
                    />
                  }

                  <Button
                    plain
                    title='Filtrar eleitores'
                    icon={<Search />}
                    size='small'
                    onClick={() => openFormSearchLayer() }
                  />

                  {rules.includes(rulesName.createVoter) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Novo'
                      onClick={() => { history.push(urlRoutes.voterCreateUrl) }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && size !== 'medium' && (
                  <Box width='15vw'>
                    <Text color='dark-2'>Ref.</Text>
                  </Box>
                )}

                {size !== 'small' && (
                  <Box width='30vw'>
                    <Text color='dark-2'>Nome</Text>
                  </Box>
                )}

                {size !== 'small' && (
                  <Box width='50vw'>
                    <Text color='dark-2'>Endereço</Text>
                  </Box>
                )}

                {size !== 'small' && (
                  <Box width='30vw'>
                    <Text color='dark-2'>Telefone</Text>
                  </Box>
                )}

                {size !== 'small' && size !== 'medium' && (
                  <Box width='30vw'>
                    <Text color='dark-2'>E-mail</Text>
                  </Box>
                )}

                <Box width='30vw' />
              </Box>

              {dataRows.map(row => {
                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && size !== 'medium' && (
                      <Box width='15vw'>
                        <Anchor
                          title='Abrir detalhes'
                          label={row.id}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />
                      </Box>
                    )}

                    <Box width='30vw'>
                      <Text color='dark-2' size='small'>{row.name}</Text>
                    </Box>

                    <Box width='50vw'>
                      {row.withLocation ? (
                        <>
                          <Text color='dark-2' size='small'>{row.formattedAddress}</Text>
                          <Text color='dark-3' size='xsmall'>{row.districtAndCity}</Text>
                        </>
                      ) : null}
                    </Box>

                    {size !== 'small' && (
                      <Box width='30vw'>
                        <Text color='dark-2' size='small'>{row.telephone}</Text>
                      </Box>
                    )}

                    {size !== 'small' && size !== 'medium' && (
                      <Box width='30vw'>
                        <Text color='dark-2' size='small'>{row.email}</Text>
                      </Box>
                    )}

                    <Box align='end' width='30vw'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        <Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={optionsMenu(row)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  reference,
                  individualRegistration,
                  name,
                  genre,
                  cityId,
                  districtsId,
                  address,
                  zipcode,
                  status,
                  groupsId,
                  maritalStatusId,
                  professionId,
                  withTelephone,
                  withEmail,
                  telephone,
                  email,
                  startBirthDate,
                  endBirthDate,
                  startCreatedDate: formattedDateTime(startCreatedDate),
                  endCreatedDate: formattedDateTime(endCreatedDate),
                  voteStatusId,
                  responsibleId,
                  responsibleName,
                  schoolId,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, votersReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  // search
  reference: formSearch.reference,
  individualRegistration: formSearch.individualRegistration,
  name: formSearch.name,
  genre: formSearch.genre,
  cityId: formSearch.cityId,
  districtsId: formSearch.districtsId,
  address: formSearch.address,
  zipcode: formSearch.zipcode,
  status: formSearch.status,
  groupsId: formSearch.groupsId,
  maritalStatusId: formSearch.maritalStatusId,
  professionId: formSearch.professionId,
  withTelephone: formSearch.withTelephone,
  withEmail: formSearch.withEmail,
  telephone: formSearch.telephone,
  email: formSearch.email,
  startBirthDate: formSearch.startBirthDate,
  endBirthDate: formSearch.endBirthDate,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  voteStatusId: formSearch.voteStatusId,
  responsibleId: formSearch.responsibleId,
  responsibleName: formSearch.responsibleName,
  schoolId: formSearch.schoolId,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleDetailsLayer,
    generatePrint,
    generatePrintList,
    generateCsv,
    toggleFormDeleteLayer,
    toggleFormReminderLayer,
    toggleFormShortMessageLayer,
    openGenerateTagLayer,
    openFormSearchLayer,
    toggleFormCreateServiceLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResult));
