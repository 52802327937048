import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Anchor } from 'grommet';
import { Send } from 'grommet-icons';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { toggleFormShortMessageLayer } from '../../actions/form-short-message';

class FormShortMessage extends Component {

  render() {
    const {
      toggleLayer,
      toggleFormShortMessageLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'sendShortMessage') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormShortMessageLayer}
              onEsc={toggleFormShortMessageLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Comunicação com eleitor' />

                <Box
                  flex
                  overflow='auto'
                  justify='center'
                >
                  <Box
                    align='center'
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                    gap='medium'
                  >
                    <Box
                      margin={{
                        bottom: 'medium'
                      }}
                    >
                      <Send size='xlarge' color='brand' />
                    </Box>

                    <Box
                      pad={{
                        horizontal: 'xlarge'
                      }}
                    >
                      <Text size='small' textAlign='center'>
                        Sabemos o quanto é importante se comunicar com o eleitor, por isso estamos construindo essa funcionalidade.
                        Em breve será possível enviar SMS para o eleitor.
                      </Text>
                    </Box>
                  </Box>
                </Box>


                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                  >
                    <Anchor
                      color='dark-3'
                      label='Fechar'
                      onClick={toggleFormShortMessageLayer}
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerReducer }) => ({
  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleFormShortMessageLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormShortMessage);
