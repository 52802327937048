import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default name => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'cidade.php',
    headers: {
      token: decipher.token.value,
      action: 'getByName'
    },
    params: {
      name,
      // timestamp: new Date().getTime()
    },
  })
}
