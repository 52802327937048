import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { cancelAppointment, enableAppointment } from '../../../api/appointment';

const loadingPostCancelToggle = () => ({
  type: TYPES.loadFormChangeStatus,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const enable = params => {
  return dispatch => {
    dispatch(loadingPostCancelToggle());

    enableAppointment(params.formParams.id)
      .then(() => {
        dispatch(toggleFormChangeStatusLayer());
        dispatch(loadingPostCancelToggle());

        dispatch({
          type: TYPES.updateItemListingChangeStatusSuccess,
          payload: { data: { id: params.formParams.id, isCanceled: false } }
        });
        dispatch(notification(`O compromisso AG-${params.formParams.id} foi ativado!`))
      })
      .catch(responseFail => {
        dispatch(loadingPostCancelToggle());
        dispatch({ type: TYPES.formChangeStatusError, payload: responseFail.response });
      })
  }
}

export const cancel = params => {
  return dispatch => {
    dispatch(loadingPostCancelToggle());

    cancelAppointment(params.formParams)
      .then(() => {
        dispatch(toggleFormChangeStatusLayer());
        dispatch(loadingPostCancelToggle());

        dispatch({
          type: TYPES.updateItemListingChangeStatusSuccess,
          payload: { data: { id: params.formParams.id, isCanceled: true } }
        });
        dispatch(notification(`O compromisso AG-${params.formParams.id} foi cancelado!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostCancelToggle());
        dispatch({
          type: TYPES.formChangeStatusError,
          payload: responseFail.response
        });
      })
  }
};

export const toggleFormChangeStatusLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.appointment,
      action: analytics.actions.appointmentChangeStatus,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      { type: TYPES.setIdFormCancel, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
