import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TagStatus, TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsMessage extends Component {

  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value={detail.subject} />

                    <SimpleDetail
                      title='Referência'
                      value={`LI-${detail.id}`}
                    />

                    <SimpleDetail
                      title='Cadastrado por'
                      value={detail.userCreated.name}
                    />

                    <SimpleDetail
                      title='Data cadastro'
                      value={formattedDateTime(detail.createdAt, 'DD MMM YYYY HH:mm')}
                    />

                    {detail.userWanted && detail.userWanted.name && (
                      <SimpleDetail
                        title='Procurou por'
                        value={detail.userWanted.name}
                      />
                    )}

                    <TagStatus
                      title='Status'
                      value={detail.status}
                      color='dark-2'
                    />

                    <Hr />

                    <SimpleDetail
                      title='Quem ligou'
                      value={detail.name}
                    />

                    <SimpleDetail
                      title='Telefone de contato'
                      value={detail.telephone}
                    />
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsMessage);
