import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        type: always(object.typeName),
        typeId: always(object.type),
        number:always(object.number),
        year: always(object.year),
        status: always(object.statusName),
        statusId: always(object.status),
        menu: always(object.menu),
      }),
    ),
    items
  )
)
