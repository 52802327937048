import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        status: always(object.statusName),
        statusId: always(object.statusId),
        statusColor: always(object.statusColor),
      }),
    ),
    items
  )
)
