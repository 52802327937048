import { curry, map, when, propEq, evolve, always } from 'ramda';

export default curry((object, key, items) => map(
    when(
      propEq('id', key),
      evolve({
        name: always(object.name),
        description: always(object.description),
      }),
    ),
    items
  )
)
