import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formManagerReducer from './form-manager';
import notificationReducer from './notification';
import formChangePassRecuder from './form-change-pass';
import formChangeStatusRecuder from './form-change-status';

const settingsUserReducer = combineReducers({
  searchResult: searchResultReducer,
  formManager: formManagerReducer,
  notification: notificationReducer,
  formChangePass: formChangePassRecuder,
  formChangeStatus: formChangeStatusRecuder,
});

export default settingsUserReducer;
