import TYPES from '../actions/types';

const INITIAL_STATE = {
  id: 0,
  isCanceled: false,
  startDate: '',
  reason: '',

  loadingPostForm: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.changeFormChangeStatusReason:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }

    case TYPES.setIdFormCancel:
      return {
        ...state,
        id: action.payload.id,
        isCanceled: action.payload.isCanceled,
        startDate: action.payload.startDate,
      }

    case TYPES.loadFormChangeStatus:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formChangeStatusError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
