import TYPES from '../actions/types';
import { formatDataFormEdit } from '../utils';

const INITIAL_STATE = {
  id: null,
  categoryId: null,
  statusId: null,
  value: null,
  isRefund: false,
  name: null,
  nationalRegister: null,
  telephone: null,
  dateRefund: null,
  dateExpense: null,
  description: null,

  isEditing: false,
  isCopy: false,
  getDataFormEditError: false,

  loadingPostForm: false,
  postDataSuccess: false,
  postDataError: { message: '', errors: [] },
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormManagerField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.loadingFormManagerEdit:
      return {
        ...state,
        isEditing: true,
      }

    case TYPES.loadingFormManagerCopy:
      return {
        ...state,
        isCopy: true,
      }

    case TYPES.loadingFormManagerEditError:
      return {
        ...state,
        getDataFormEditError: true,
      }

    case TYPES.loadDataEdit:
      return {
        ...state,
        ...formatDataFormEdit(action.payload.data),
      }

    case TYPES.clearFormManager:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case TYPES.loadingPostFormManager:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formManagerPostError:
      return { ...state, postDataError: action.payload.data };

    default:
      return state;
  }
}
