import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const organizeListObject = list =>
  Object.keys(list).reduce((acc, key) => {
    if(list[key]) {
      acc.push({
        name: key,
        value: list[key],
      })
    }

    return acc;
  }, [])

export default params => {
  const formattedParams = {
    type: params.type,
    name: params.name,
    dateStart: params.dateStart,
    hourStart: params.hourStart,
    voters: params.voters,
    message: organizeListObject(params.composerMessage),
    filters: organizeListObject(params.searchVoters),
  };

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    data: formattedParams,
    url: '/campaign.php',
    headers: {
      token: decipher.token.value,
      action: 'createFull',
    }
  });
}
