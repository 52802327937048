import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr } from '../../../../components/detail';
import { formattedDateTime } from '../../../../utils';

import { cancel, toggleFormCancelLayer } from '../../actions/form-cancel';

import visibleWidth from '../../../../constants/layer-width';
import visibleContentWidth from '../../../../constants/layer-content-width';

class FormCancel extends Component {
  handlePost = formParams => {
    const {
      cancel,
    } = this.props;

    cancel({ formParams });
  };

  render() {
    const {
      id,
      name,
      dateSend,
      toggleLayer,

      loadingPostForm,
      postDataError,

      toggleFormCancelLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCancelLayer}
              onEsc={toggleFormCancelLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                overflow='auto'
                flex
              >
                <Box
                  margin={{
                    top: 'large',
                    bottom: 'medium',
                    horizontal: 'large',
                  }}
                  flex={{ shrink: 0 }}
                >
                  <Text size='xlarge' textAlign='center'>
                    Deseja realmente cancelar essa campanha?
                  </Text>
                </Box>

                <Box
                  align='center'
                  margin={{ top: 'medium' }}
                  flex={{ shrink: 0 }}
                >
                  <Box
                    width={visibleContentWidth[size]}
                    round='small'
                    pad={{ vertical: 'large' }}
                    margin={{ bottom: 'medium', horizontal: 'xsmall' }}
                    elevation='small'
                  >
                    <SimpleDetail
                      title='Nome'
                      value={name}
                    />

                    <SimpleDetail
                      title='Data de envio'
                      value={formattedDateTime(dateSend, 'DD MMM YYYY')}
                    />

                    <SimpleDetail
                      title='Horário de envio'
                      value={formattedDateTime(dateSend, 'HH:mm')}
                    />

                    <Hr />

                    <Box
                      margin={{
                        top: 'medium',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      alignSelf='center'
                    >
                      <Text size='small' textAlign='center' color='status-warning'>
                        Ao cancelar esta campanha o disparo não será realizado e o valor pago não será estornado.
                      </Text>
                    </Box>
                  </Box>
                </Box>


                <Box
                  margin={{ horizontal: 'large' }}
                  flex={{ shrink: 0 }}
                >
                  <ErrorForm errorsList={postDataError.errors} />
                </Box>


                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                  flex={{ shrink: 0 }}
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Não'
                      onClick={toggleFormCancelLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Sim' : 'Cancelando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          status: 6,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ campaignsReducer: { cancel }, layerReducer }) => ({
  id: cancel.id,
  name: cancel.name,
  dateSend: cancel.dateSend,

  loadingPostForm: cancel.loadingPostForm,
  postDataError: cancel.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    cancel,
    toggleFormCancelLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCancel);
