import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Anchor, Menu } from 'grommet';
import { Notes, Edit, Transaction, Download, Print, Search, More, Copy } from 'grommet-icons';

import { EmptySearches, Placeholder, ErrorsSearches, Pagination, Tag } from '../../../../components';

import { toggleDetailsLayer } from '../../actions/details';
import { generateCsv } from '../../actions/export-csv';
import { generatePrintList } from '../../actions/print-list';
import { toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { openFormSearchLayer, goToNextPage, goToPrevPage } from '../../actions/form-search';
import { toggleFormManagerLayer, copyLayer } from '../../actions/form-manager';

import { formattedDateTime, formattedNumber } from '../../../../utils';
import rulesName from '../../../../constants/rules';

function SearchResult(props) {
  const {
    userLogged: { rules },

    loadingFormSearch,
    dataRows,
    totalOfRows,
    getListError,

    status,
    startDate,
    endDate,
    reference,
    subject,
    participantId,
    page,
    limit,

    generateCsv,
    generatePrintList,
    toggleDetailsLayer,
    toggleFormManagerLayer,
    copyLayer,
    toggleFormChangeStatusLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches
      button={{
        label: 'Abrir filtros',
        action: openFormSearchLayer
      }}
    />;
  }

  if(!dataRows.length) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openFormSearchLayer,
      }}

      buttonCreate={
        rules.includes(rulesName.createAppointment) ?
          { label: 'Novo compromisso', action: toggleFormManagerLayer } : null
      }
    />;
  }

  const optionsMenu = (row) => {
    const options = [];

    if(rules.includes(rulesName.editAppointment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Edit />
            <Text size='small'>Editar</Text>
          </Box>
        ),
        onClick: () => toggleFormManagerLayer(row)
      });
    }

    if(rules.includes(rulesName.createAppointment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Copy />
            <Text size='small'>Copiar</Text>
          </Box>
        ),
        onClick: () => copyLayer(row)
      });
    }

    if(rules.includes(rulesName.editAppointment)) {
      options.push({
        label: (
          <Box pad={{ horizontal: 'small' }} gap='small' direction='row'>
            <Transaction />
            <Text size='small'>{row.isCanceled ? 'Reativar' : 'Cancelar'}</Text>
          </Box>
        ),
        onClick: () => {
          toggleFormChangeStatusLayer({
            id: row.id,
            isCanceled: row.isCanceled,
            startDate: row.startDate,
          })
        }
      });
    }

    return options;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width='xlarge'
            >
              <Box
                align='center'
                direction='row'
                justify='between'
                margin={{ bottom: 'small' }}
              >
                <Box
                  direction={size === 'small' ? 'column' : 'row'}
                  align={size === 'small' ? 'baseline' : 'center'}
                  gap={size === 'small' ? null : 'small'}
                >
                  <Text size='xlarge'>{formattedNumber(totalOfRows)}</Text>

                  <Text
                    size={size === 'small' ? 'xsmall' : 'medium'}
                    color='dark-6'
                  >
                    {totalOfRows > 1 ? 'compromissos' : 'compromisso'}
                  </Text>
                </Box>

                <Box
                  direction='row'
                  align='center'
                  gap={size === 'small' ? 'large' : 'medium'}
                >
                  {rules.includes(rulesName.exportAppointment) &&
                    <Button
                      plain
                      title='Download Excel (.csv)'
                      icon={<Download />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => {
                        generateCsv({
                          status,
                          startDate: formattedDateTime(startDate),
                          endDate: formattedDateTime(endDate),
                          reference,
                          subject,
                          participantId,
                          page: 1,
                          limit: totalOfRows,
                        })
                      }}
                    />
                  }

                  {rules.includes(rulesName.printAppointment) &&
                    <Button
                      plain
                      title='Imprimir resultado da pesquisa'
                      icon={<Print />}
                      size='small'
                      margin={{ right: 'xsmall' }}
                      onClick={() => {
                        generatePrintList({
                          status,
                          startDate: formattedDateTime(startDate),
                          endDate: formattedDateTime(endDate),
                          reference,
                          subject,
                          participantId,
                          page: 1,
                          limit: totalOfRows,
                        })
                      }}
                    />
                  }

                  <Button
                    plain
                    title='Filtrar compromissos'
                    icon={<Search />}
                    onClick={() => { openFormSearchLayer() }}
                  />

                  {rules.includes(rulesName.createAppointment) &&
                    <Button
                      primary
                      color='neutral-1'
                      size='small'
                      label='Novo'
                      onClick={() => { toggleFormManagerLayer() }}
                    />
                  }
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', color: 'light-5' }}
                gap='small'
              >
                {size !== 'small' && size !== 'medium' && <Box width='xsmall'>
                  <Text color='dark-2'>Ref.</Text>
                </Box>}

                {size !== 'small' && <Box width='medium'>
                  <Text color='dark-2'>Assunto</Text>
                </Box>}

                {size !== 'small' && <Box width='small'>
                  <Text color='dark-2'>Data</Text>
                </Box>}

                {size !== 'small' && <Box width='small'>
                  <Text color='dark-2'>Horário</Text>
                </Box>}

                {size !== 'small' && <Box width='xsmall'>
                  <Text color='dark-2'>Status</Text>
                </Box>}

                <Box width='medium' />
              </Box>

              {dataRows.map(row => {
                const menu = optionsMenu(row);

                return (
                  <Box
                    key={row.id}
                    align='center'
                    direction='row'
                    pad={{ vertical: size === 'small' ? 'small' : 'xsmall' }}
                    border={{ side: 'bottom', color: 'light-5' }}
                    gap='small'
                  >
                    {size !== 'small' && size !== 'medium' && <Box width='xsmall'>
                      <Anchor
                        title='Abrir detalhes'
                        label={`AG-${row.id}`}
                        onClick={() => { toggleDetailsLayer(row) }}
                      />
                    </Box>}

                    <Box width='medium'>
                      <Text color='dark-2' size='small'>{row.subject}</Text>

                      {size === 'small' &&
                        <Text color='dark-2' size='small'>
                          {formattedDateTime(row.startDate)} - {formattedDateTime(row.startDate, 'HH:mm')} até {formattedDateTime(row.endDate, 'HH:mm')}
                        </Text>
                      }
                    </Box>

                    {size !== 'small' && <Box width='small'>
                      <Text color='dark-2' size='small'>
                        {formattedDateTime(row.startDate, 'DD MMM YYYY')}
                      </Text>
                    </Box>}

                    {size !== 'small' && <Box width='small'>
                      <Text color='dark-2' size='small'>
                        {formattedDateTime(row.startDate, 'HH:mm')} até {formattedDateTime(row.endDate, 'HH:mm')}
                      </Text>
                    </Box>}

                    <Box width='xsmall' direction='row'>
                      <Text color='dark-2' size='small'>
                        {row.isCanceled ?
                          <Tag text='CANCELADO' background='status-critical' /> :
                          <Tag text='ATIVO' background='status-ok' />}
                      </Text>
                    </Box>

                    <Box align='end' width='medium'>
                      <Box direction='row'>
                        <Button
                          title='Abrir detalhes'
                          icon={<Notes color='dark-3' size='medium' />}
                          onClick={() => { toggleDetailsLayer(row) }}
                        />

                        {menu.length > 0 && (<Menu
                          dropProps={{ align: { right: 'right', top: 'top' } }}
                          icon={<More />}
                          items={menu}
                        />)}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              <Pagination
                totalOfRows={totalOfRows}
                nextPage={goToNextPage}
                prevPage={goToPrevPage}
                filters={{
                  status,
                  startDate: formattedDateTime(startDate),
                  endDate: formattedDateTime(endDate),
                  reference,
                  subject,
                  participantId,
                  page,
                  limit,
                }}
              />
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ authReducer: { authenticated }, appointmentsReducer: { searchResult, formSearch } }) => ({
  userLogged: authenticated,

  status: formSearch.status,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,
  reference: formSearch.reference,
  subject: formSearch.subject,
  participantId: formSearch.participantId,
  page: formSearch.page,
  limit: formSearch.limit,

  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  totalOfRows: searchResult.totalOfRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    generateCsv,
    generatePrintList,
    toggleDetailsLayer,
    toggleFormManagerLayer,
    copyLayer,
    toggleFormChangeStatusLayer,
    openFormSearchLayer,
    goToNextPage,
    goToPrevPage,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
