import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, Text, Box } from 'grommet';

import { ErrorForm } from '../../../../components';
import { TitleField, InputPassword } from '../../../../components/form';
import { SimpleDetail, Hr } from '../../../../components/detail';
import visibleWidth from '../../../../constants/layer-width';

import { changeField, updatePass, toggleFormChangePassLayer } from '../../actions/form-change-pass';

class FormChangePass extends Component {
  handlePost = formParams => {
    const {
      updatePass,
    } = this.props;

    updatePass({ formParams });
  };

  render() {
    const {
      id,
      password,
      name,
      email,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      toggleFormChangePassLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'changePass') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangePassLayer}
              onEsc={toggleFormChangePassLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                overflow='auto'
                flex
              >
                <Box
                  margin={{
                    top: 'large',
                    bottom: 'small',
                    horizontal: 'large'
                  }}
                  flex={{ shrink: 0 }}
                >
                  <Text size='xlarge' textAlign='center'>
                    Trocar senha
                  </Text>
                </Box>

                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Nome'
                    value={name}
                  />

                  <SimpleDetail
                    title='E-mail'
                    value={email}
                  />

                  <Hr />

                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Nova senha' required />

                    <InputPassword
                      value={password}
                      onChange={event =>
                        changeField({ fieldName: 'password', value: event.target.value })
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='password' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormChangePassLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          password,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsUserReducer: { formChangePass }, layerReducer }) => ({
  id: formChangePass.id,
  password: formChangePass.password,
  name: formChangePass.name,
  email: formChangePass.email,

  loadingPostForm: formChangePass.loadingPostForm,
  postDataError: formChangePass.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeField,
    updatePass,
    toggleFormChangePassLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangePass);
