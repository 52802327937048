import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, TextArea, Box, Anchor, RadioButtonGroup } from 'grommet';

import { ErrorForm } from '../../../../components';
import { CalendarDropButton, TitleField, DescriptionField } from '../../../../components/form';
import { SimpleDetail, TagStatus, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeStatus, toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { changeValues } from '../../../../actions/form';

import { formattedDateTime } from '../../../../utils';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      changeStatus,
    } = this.props;

    changeStatus({ formParams });
  };

  render() {
    const {
      statusOptions,

      treatmentId,
      statusCurrentId,
      status,
      statusColor,
      reference,
      statusNewId,
      comment,
      completionDate,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormChangeStatusLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <TitleDetail value='Trocar status' />

                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Referência'
                    value={reference}
                    description='Código do atendimento que esta mudando o status.'
                    width={{
                      title: '50vw',  value: '50vw'
                    }}
                  />

                  <TagStatus
                    title='Status'
                    value={status}
                    color={statusColor}
                    description='Status atual do atendimento.'
                  />

                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Qual será o novo status do atendimento?' required />

                    <Box
                      round='xxsmall'
                      pad={{
                        vertical: 'small',
                      }}
                      gap='xsmall'
                    >
                      <RadioButtonGroup
                        name='statusNewId'
                        value={statusNewId ?? ''}
                        options={
                          statusOptions.map(item => ({
                            label: item.name,
                            id: item.id.toString(),
                            value: item.id.toString(),
                            disabled: item.id === statusCurrentId
                          }))
                        }
                        onChange={event => {
                          changeValues({ fieldName: 'statusNewId', value: event.target.value }, 'formChangeValues')
                        }}
                      >
                        {(option, { checked }) => {
                          let background = null;
                          let borderColor = 'dark-5';

                          if(checked) {
                            background = 'brand';
                            borderColor = 'brand';
                          }

                          return (
                            <Box
                              direction='row'
                              gap='small'
                              justify='center'
                              align='center'
                            >
                              <Box
                                width={'20px'}
                                height={'20px'}
                                background={background}
                                round='xxsmall'
                                border={{
                                  color: borderColor
                                }}
                              />
                              <Text color='dark-2' size='small'>{option.label}</Text>
                            </Box>
                          )
                        }}
                      </RadioButtonGroup>
                    </Box>

                    <ErrorForm errorsList={postDataError.errors} fieldName='statusNewId' />
                  </Box>

                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                      gap='xxsmall'
                    >
                      <TitleField
                        text='Data conclusão'
                      />

                      <DescriptionField
                        text='Data que o atendimento foi finalizado. bla bla bla'
                      />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <CalendarDropButton
                        date={completionDate ?? ''}
                        onSelect={(selectedDate) => changeValues({ fieldName: 'completionDate', value: selectedDate }, 'formChangeValues')}
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='completionDate' />
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Motivo da troca do status' required />

                    <TextArea
                      rows={3}
                      resize='vertical'
                      maxLength={1000}
                      value={comment ?? ''}
                      onChange={event =>
                        changeValues({ fieldName: 'comment', value: event.target.value }, 'formChangeValues')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='comment' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          treatmentId,
                          statusNewId,
                          comment,
                          completionDate: formattedDateTime(completionDate),
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerReducer, selectOptionsReducer, formCreateUpdateCopyGeneric }) => ({
  statusOptions: selectOptionsReducer.statusServiceOptions,

  treatmentId: formCreateUpdateCopyGeneric.treatmentId,
  statusCurrentId: formCreateUpdateCopyGeneric.statusCurrentId,
  status: formCreateUpdateCopyGeneric.status,
  statusColor: formCreateUpdateCopyGeneric.statusColor,
  reference: formCreateUpdateCopyGeneric.reference,

  statusNewId: formCreateUpdateCopyGeneric.statusNewId,
  comment: formCreateUpdateCopyGeneric.comment,
  completionDate: formCreateUpdateCopyGeneric.completionDate,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    changeStatus,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
