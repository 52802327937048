import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { remove as d } from '../../../api/common';

import { formatDataFormDelete } from '../utils';

const notification = message => ({ type: TYPES.showNotification, payload: message });
const loadingPostDelete = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDelete());

    d(params.formParams.id, 'legislative.processe.php')
      .then(() => {
        dispatch(loadingPostDelete());
        dispatch(notification('O documento foi removido!'));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostDelete());
        dispatch({ type: COMMON_TYPES.formPostResultError, payload: responseFail.response.data });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeDelete,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormDelete(params) },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
