import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { createProgress } from '../../../api/service';

const loadingPostCommentToggle = () => ({
  type: TYPES.postLoadingFormComment,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const createComment = params => {
  return dispatch => {
    dispatch(loadingPostCommentToggle());

    createProgress(params.formParams)
      .then(response => {
        dispatch(toggleFormCommentLayer());
        dispatch(loadingPostCommentToggle());
        dispatch(notification(`Comentário incluido no atendimento ${response.data.reference}!`))
      })
      .catch(responseFail => {
        dispatch(loadingPostCommentToggle());
        dispatch({
          type: TYPES.postFormCommentError,
          payload: responseFail.response
        });
      })
  }
}

export const changeField = (value, type) => ({
  type: TYPES[type],
  payload: value,
});

export const toggleFormCommentLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceComment,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'createComment' },
      { type: TYPES.setDataFormComment, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
