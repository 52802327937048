import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, Description, TitleDetail } from '../../../../components/detail';

import { formattedDateTime, formattedCurrency } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsExpense extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value='Detalhes da despesa' />

                    <SimpleDetail
                      title='Categoria'
                      value={detail.categoryName}
                    />

                    <SimpleDetail
                      title='Status'
                      value={detail.statusName}
                    />

                    <SimpleDetail
                      title='Valor'
                      value={formattedCurrency(detail.value)}
                    />

                    <SimpleDetail
                      title='Reembolso'
                      value={detail.isRefund ? 'Sim' : 'Não'}
                    />

                    <SimpleDetail
                      title='Data da despesa'
                      value={formattedDateTime(detail.dateExpense)}
                    />

                    {detail.description &&
                      <Description
                        title='Descrição'
                        value={detail.description}
                      />
                    }

                    {(detail.name || detail.nationalRegister || detail.telephone) &&
                      <>
                        <Hr />

                        <SimpleDetail
                          title='Nome'
                          value={detail.name}
                        />

                        <SimpleDetail
                          title='CPF/CNPJ'
                          value={detail.nationalRegister}
                        />

                        <SimpleDetail
                          title='Telefone'
                          value={detail.telephone}
                        />
                      </>
                    }
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsExpense);
