import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Notification } from '../../../../components';
import { hideNotificationLayer } from '../../actions/notification';

function Communication(props) {
  const {
    description,
    isOpen,
    hideNotificationLayer,
  } = props;

  return <Notification
    description={description}
    isOpen={isOpen}
    onClose={hideNotificationLayer}
  />
}

const mapStateToProps = ({ phonecallsReducer: { notification } }) => ({
  description: notification.description,
  isOpen: notification.isOpen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    hideNotificationLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Communication);
