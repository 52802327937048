import { combineReducers } from 'redux';
import removeReducer from './form-delete';
import searchResultReducer from './search-result';
import formManagerReducer from './form-manager';
import notificationReducer from './notification';

const settingsGroupReducer = combineReducers({
  remove: removeReducer,
  searchResult: searchResultReducer,
  formManager: formManagerReducer,
  notification: notificationReducer,
});

export default settingsGroupReducer;
