import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Text, Layer, Box, Anchor } from 'grommet';
import { FormNext } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TextEditor } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeValues } from '../../../../actions/form';
import { create, update, toggleFormPrepareDocLayer, loadTemplate } from '../../actions/form-prepare-doc';

const FormPrepareDoc = (props) => {
  const handlePost = useCallback(formParams => {
    const { create, update } = props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({ formParams });
    }
  }, [props]);

  const chosenTemplate = useCallback((formParams) => {
    const { loadTemplate } = props;
    loadTemplate(formParams);
  }, [props]);

  const {
    templates,

    id,
    processeId,
    text,
    number,
    year,
    typeName,

    toggleLayer,
    loadingPostForm,
    postDataError,

    changeValues,
    toggleFormPrepareDocLayer,
  } = props;


  if(toggleLayer && toggleLayer === 'prepareDoc') {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleFormPrepareDocLayer}
            onEsc={toggleFormPrepareDocLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {!processeId || !templates ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {!text && templates && processeId > 0 ? (
                <>
                  <TitleDetail value='Selecione um de nossos modelos' />

                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        top: 'xsmall',
                        horizontal: 'large',
                      }}
                      pad={{ bottom: 'small' }}
                      flex={{ shrink: 0 }}
                    >
                      {templates.map((item) => {
                        return (
                          <Box
                            key={item.groupName}
                            gap='small'
                            margin={{ bottom: 'medium' }}
                          >
                            <Box><Text>{item.groupName}</Text></Box>

                            {item.listItens.map((item, index) => (
                              <Box
                                key={index}
                                width='large'
                                round='xsmall'
                                pad={{
                                  vertical: 'xsmall',
                                  horizontal: 'small'
                                }}
                                direction='row'
                                border={{ color: 'light-3' }}
                                background='light-1'
                                align='center'
                                justify='between'
                              >
                                <Text size='small' color='dark-3'>{item.description}</Text>

                                <Anchor
                                  title='Usar esse modelo'
                                  icon={<FormNext color='brand' />}
                                  onClick={() => chosenTemplate({
                                    id,
                                    processeId,
                                    typeName,
                                    number,
                                    year,
                                    text: item.template
                                  })}
                                />
                              </Box>
                            ))}
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </>
              ) : null}

              {text && processeId > 0 && templates ? (
                <>
                  <TitleDetail value={id ? `Editar texto do(a) ${typeName} - ${number}/${year}` : `Elaborar documento do(a) ${typeName} - ${number}/${year}`} />

                  <Box flex overflow='auto'>
                    {/* texto documento */}
                    <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TextEditor
                        value={text ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'text', value: event }, 'changeFormPrepareDocField')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='text' />
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        onClick={toggleFormPrepareDocLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          handlePost({
                            id,
                            processeId,
                            text,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    )
  }

  return null;
}

const mapStateToProps = ({ legislativeProcessesReducer: { formPrepareDoc, templates }, layerReducer }) => ({
  templates: templates.list,

  id: formPrepareDoc.id,
  processeId: formPrepareDoc.processeId,
  text: formPrepareDoc.text,
  number: formPrepareDoc.number,
  year: formPrepareDoc.year,
  typeName: formPrepareDoc.typeName,

  loadingPostForm: formPrepareDoc.loadingPostForm,
  postDataError: formPrepareDoc.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    create,
    update,
    toggleFormPrepareDocLayer,
    loadTemplate,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormPrepareDoc);
