import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default (
  params
) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'user.php',
    headers: {
      token: decipher.token.value,
      action: 'search'
    },
    params: {
      ...params,
      order: 'fl_status',
      orderDirection: 'desc'
    },
  })
}
