const TYPES = ({
  generateExcel: 'GENERATE_CSV_EXPENSE',

  // list
  loadingFormSearch: 'LOADING_SEARCH_EXPENSE_FORM_TOGGLE',
  searchSuccess: 'SEARCH_EXPENSE_SUCCESS',
  searchError: 'SEARCH_EXPENSE_LIST_ERROR',

  // form create
  loadingPostFormManager: 'MANAGER_EXPENSE_FORM_LOADING',
  loadDataEdit: 'MANAGER_EXPENSE_EDIT_DATA_LOAD',
  loadingFormManagerEdit: 'MANAGER_EXPENSE_EDIT_DATA_LOADING_FORM',
  loadingFormManagerCopy: 'MANAGER_EXPENSE_COPY_DATA_LOADING_FORM',
  loadingFormManagerEditError: 'MANAGER_EXPENSE_EDIT_DATA_LOADING_FORM_ERROR',
  clearFormManager: 'MANAGER_EXPENSE_FORM_CLEAR',
  formManagerPostError: 'POST_MANAGER_EXPENSE_FORM_ERROR',
  updateItemListingManagerSuccess: 'MANAGER_EXPENSE_SUCCESS_UPDATE_LISTING',
  changeFormManagerField: 'CHANGE_MANAGER_EXPENSE_FORM_FIELD',

  // form search
  changeFormSearchField: 'CHANGE_SEARCH_EXPENSE_FORM_FIELD',
  setDefaultPage: 'SET_SEARCH_EXPENSE_DEFAULT_PAGE',
  clearFormSearchFields: 'CLEAR_FIELDS_EXPENSE_FORM',
  paginationFormSearch: 'SEARCH_EXPENSE_PAGINATION',

  // notification
  showNotification: 'EXPENSE_SHOW_NOTIFICATION',
  hideNotification: 'EXPENSE_HIDE_NOTIFICATION',
});

export default TYPES;
