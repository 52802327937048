import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Anchor, Button } from 'grommet';
import { Document, Share } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TagStatus, Description, TitleDetail } from '../../../../components/detail';

import urlsApp from '../../../../config/url-routes';
import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';
import { generatePrint } from '../../actions/print-doc';

class DetailsLegislativeProcess extends Component {
  printDoc = () => {
    const {
      detail: {
        id
      },
      generatePrint,
    } = this.props;

    generatePrint({ id });
  };

  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value='Detalhes do documento' />

                    <SimpleDetail
                      title='Tipo'
                      value={detail.typeName}
                    />

                    <SimpleDetail
                      title='Número'
                      value={detail.number}
                    />

                    <SimpleDetail
                      title='Ano'
                      value={detail.year}
                    />

                    <TagStatus
                      title='Status'
                      value={detail.statusName}
                      color={detail.statusColor}
                    />

                    {detail.areaInterestName && <SimpleDetail
                      title='Área de interesse'
                      value={detail.areaInterestName}
                    />}

                    {detail.region && <SimpleDetail
                      title='Região (bairro, cidade)'
                      value={detail.region}
                    />}

                    <SimpleDetail
                      title='Em tramitação'
                      value={detail.proceeding ? 'Sim' : 'Não'}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Cadastro'
                      value={formattedDateTime(detail.createdAt)}
                    />

                    <SimpleDetail
                      title='Última atualização'
                      value={formattedDateTime(detail.updatedAt)}
                    />

                    <Hr />

                    <Description
                      title='Assunto'
                      value={detail.menu}
                    />

                    <Hr />

                    <Description
                      title='Imprimir documento elaborado'
                      value={
                        <Text size='small' color='dark-3'>
                          Para mais informaçõs sobre a impressão, <Anchor href={urlsApp.printDoc} target='_blank'>acesse <Share color='brand' size='small' /></Anchor>. Se ainda não elabolou o documento <Anchor href={urlsApp.createDoc} target='_blank'>clique aqui <Share color='brand' size='small' /></Anchor>.
                        </Text>
                      }
                    />

                    <Box
                      margin={{ top: 'xsmall', bottom: 'none', horizontal: 'large' }}
                      pad={{ bottom: 'small' }}
                      flex={{ shrink: 0 }}
                    >
                      <Button
                        primary
                        label='Imprimir'
                        onClick={this.printDoc}
                        className='primary-button'
                      />
                    </Box>

                    <Hr />

                    {detail.authors && detail.authors.length > 0 &&
                      <>
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large',
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Autores
                          </Text>

                          <Box
                            direction='row'
                            gap='small'
                            wrap={true}
                          >
                            {
                              detail.authors.map((item, index) => (
                                <Box
                                  key={index}
                                  gap='small'
                                  round='large'
                                  pad={{ vertical: 'small', horizontal: 'medium' }}
                                  margin={{ bottom: 'small' }}
                                  border={{ color: 'light-3' }}
                                >
                                  <Box direction='row' align='center' gap='small'>
                                    <Text size='small' color='dark-2' >{item}</Text>
                                  </Box>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>

                        <Hr />
                      </>
                    }

                    {detail.archives && detail.archives.length > 0 &&
                      <>
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large',
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Arquivos
                          </Text>

                          <Box
                            direction='row'
                            wrap={true}
                          >
                            {
                              detail.archives.map((item, index) => (
                                <Box
                                  key={index}
                                  width='large'
                                  round='xsmall'
                                  pad={{
                                    vertical: size === 'small' ? 'medium' : 'small',
                                    horizontal: 'medium'
                                  }}
                                  margin={{ bottom: 'medium' }}
                                  border={{ color: 'light-3' }}
                                  gap='medium'
                                  direction='row'
                                  align='center'
                                >
                                  <Document />

                                  <Box gap='xsmall'>
                                    <Anchor
                                      label={item.name}
                                      href={item.url}
                                      target='_blank'
                                    />

                                    <Text size='small' color='dark-3'>
                                      {item.description}
                                    </Text>
                                  </Box>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>

                        <Hr />
                      </>
                    }


                    {detail.comments && detail.comments.length > 0 &&
                      <>
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large',
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Andamentos/Respostas orgão responsável
                          </Text>

                          <Box
                            direction='row'
                            wrap={true}
                          >
                            {
                              detail.comments.map((item, index) => (
                                <Box
                                  key={index}
                                  width='large'
                                  round='xsmall'
                                  pad={{
                                    vertical: size === 'small' ? 'medium' : 'small',
                                    horizontal: 'medium'
                                  }}
                                  margin={{ bottom: 'medium' }}
                                  border={{ color: 'light-3' }}
                                  background='light-1'
                                >
                                  <Text size='small' color='dark-2' margin={{ bottom: 'small' }}>
                                    {formattedDateTime(item.date)}
                                  </Text>

                                  <Text size='small' color='dark-3'>
                                    {item.note}
                                  </Text>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>

                        <Hr />
                      </>
                    }

                    {detail.history && detail.history.length > 0 &&
                      <Box
                        margin={{
                          top: 'xsmall',
                          bottom: 'none',
                          horizontal: 'large',
                        }}
                        pad={{ bottom: 'small' }}
                        flex={{ shrink: 0 }}
                      >
                        <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                          Histórico
                        </Text>

                        <Box
                          border={{ color: 'light-4' }}
                          round='xsmall'
                          background='light-1'
                          pad={{ vertical: 'medium', horizontal: 'medium' }}
                          margin={{ bottom: 'small' }}
                          height={{ min: 'small', max: 'medium' }}
                          overflow='auto'
                        >
                        {
                          detail.history.map((h, index) => (
                            <Box
                              key={index}
                              direction='row'
                              justify='between'
                              pad={{ vertical: 'xsmall' }}
                              flex={{ shrink: 0 }}
                            >
                              <Box direction='row'>
                                <Text
                                  size='small'
                                  color='dark-5'
                                  margin={{ right: 'small' }}
                                >
                                  {formattedDateTime(h.createdAt, 'DD MMM YYYY HH:mm')}
                                </Text>

                                <Text
                                  size='small'
                                  color='dark-2'
                                  margin={{ right: 'small' }}
                                >
                                  {h.name}
                                </Text>
                              </Box>

                              <Box>
                                <Text size='small' color='dark-3'>
                                  {h.category}
                                </Text>
                              </Box>
                            </Box>
                          ))
                        }
                        </Box>

                      </Box>
                    }

                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
    generatePrint,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsLegislativeProcess);
