import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';
import formManagerReducer from './form-manager';
import notificationReducer from './notification';

const phonecallsReducer = combineReducers({
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
  formManager: formManagerReducer,
  notification: notificationReducer,
});

export default phonecallsReducer;
