import COMMON_TYPES from '../../../actions/types';

import { details } from '../../../api/common';
import { createAudit } from '../../../api/user';

const searchDetails = id =>
  details(id, 'telefonema.php')
    .then(response => {
      createAudit(
        'DETALHES_TELEFONEMA',
        `Acessou os detalhes da ligação LI-${id}.`
      );

      return {
        type: COMMON_TYPES.detailsGetResult,
        payload: response,
      }
    })
    .catch(() => ({ type: COMMON_TYPES.detailsGetResultError }))

export const toggleDetailsLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'details' },
      searchDetails(params.id),
    ]
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.detailsGetResult, payload: { data: null } }
  ];
};
