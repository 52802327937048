import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';

import { create as c, update as u, details } from '../../../api/common';

import { formatDataFormCreate, formatDataFormEdit, formatDataFormCopy } from '../utils';

const notification = message => ({ type: TYPES.showNotification, payload: message });
const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'task.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(notification(`A tarefa ${response.data.id} foi atualizada!`));
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({ type: COMMON_TYPES.formPostResultError, payload: responseFail.response.data });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'task.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(notification(`A tarefa ${response.data.id} foi criada!`));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({ type: COMMON_TYPES.formPostResultError, payload: responseFail.response.data });
      })
  }
}

export const copyLayer = params => {
  if(params && params.id) {
    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
        { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      ]);

      details(params.id, 'task.php')
        .then(({ data }) => {
          dispatch([
              {
                type: COMMON_TYPES.formInitializeValues,
                payload: formatDataFormCopy(data),
              },
              { type: COMMON_TYPES.formGetWatingResponse, payload: false },
          ])
        })
        .catch(() =>
          dispatch({ type: COMMON_TYPES.formGetResultError })
        )
    };
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
}

export const toggleFormManagerLayer = params => {
  if(params && params.id) {
    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
        { type: COMMON_TYPES.formGetWatingResponse, payload: true },
      ]);

      details(params.id, 'task.php')
        .then(({ data }) => {
          dispatch([{
              type: COMMON_TYPES.formInitializeValues,
              payload: formatDataFormEdit(data),
            },
            { type: COMMON_TYPES.formGetWatingResponse, payload: false },
          ])
        })
        .catch(() =>
          dispatch({ type: COMMON_TYPES.formGetResultError })
        )
    };
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
    { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormCreate() },
    { type: COMMON_TYPES.formGetWatingResponse, payload: false },
  ];
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: COMMON_TYPES.formClearValues }
];
