import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { create as c } from '../../../api/common';

import { formatDataFormReminder } from '../utils';

const notification = message => ({ type: TYPES.showNotification, payload: message });
const loadingPostManager = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'task.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(notification('Lembrete criado!'));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());
        dispatch({ type: COMMON_TYPES.formPostResultError, payload: responseFail.response.data });
      })
  }
}

export const toggleFormReminderLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceReminder,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'reminder' },
      { type: COMMON_TYPES.formInitializeValues, payload: formatDataFormReminder(params) },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
