import { formattedDateTime } from '../../../utils';

export default () => ({
  id: 0,
	name: '',
	telephone: '',
	subject: '',
	date: new Date(),
	hour: formattedDateTime(new Date(), 'HH:mm'),
	userWantedId: 0,
	status: 1,
});
