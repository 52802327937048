import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Text } from 'grommet';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, Description, TitleDetail, TagStatus } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import statusOptions from '../../constants/status-options';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsExpense extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <TitleDetail value='Detalhes da tarefa' />

                    <SimpleDetail
                      title='Dia'
                      value={formattedDateTime(detail.taskDate, 'DD MMM YYYY')}
                    />

                    <TagStatus
                      title='Status'
                      value={statusOptions.find(item => item.id === detail.status)?.name}
                      color={statusOptions.find(item => item.id === detail.status)?.color}
                    />

                    <Hr />

                    <Description title='Descrição' value={detail.description} />

                    <Hr />

                    {detail.participants && detail.participants.length > 0 &&
                      <Box
                        margin={{
                          top: 'xsmall',
                          bottom: 'none',
                          horizontal: 'large'
                        }}
                        pad={{ bottom: 'small' }}
                        flex={{ shrink: 0 }}
                      >
                        <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                          Responsáveis
                        </Text>

                        <Box
                          direction='row'
                          flex={{ shrink: 0 }}
                          wrap={true}
                          gap='small'
                        >
                          {
                            detail.participants.map((participant, index) => (
                              <Box
                                key={index}
                                border={{ color: 'light-4' }}
                                round='xsmall'
                                background='light-1'
                                pad={{ vertical: 'small', horizontal: 'medium' }}
                                flex={{ shrink: 0 }}
                                margin={{ bottom: 'small' }}
                              >
                                <Text size='medium' color='dark-2'>
                                  {participant.name}
                                </Text>
                              </Box>
                            ))
                          }
                        </Box>
                      </Box>
                    }

                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsExpense);
